@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');
@font-face {
  font-family: Montserrat;
  src: url('../font/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'fontello-tree';
  src: url('../font/tree_fontello.eot');
  src: url('../font/tree_fontello.eot?#iefix') format('embedded-opentype'),
    url('../font/tree_fontello.woff') format('woff'),
    url('../font/tree_fontello.ttf') format('truetype'),
    url('../font/tree_fontello.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

body,
html {
  width: 100% !important;
  font-family: Montserrat, sans-serif !important;
  font-weight: 300 !important;
}

h1,
h2,
h3,
h4 {
  font-family: 'Ubuntu', sans-serif !important;
}
.home h2 {
  font-size: 22px;
  line-height: 30px;
}

.powered {
  max-width: 70%;
}

.playpause {
  background-repeat: no-repeat;
  left: 0%;
  right: 0%;
  top: -45%;
  bottom: 0%;
  background-size: contain;
  background-position: center;
}
.text-title {
  font-size: 350%;
  color: #fff;
  line-height: 120%;
  padding: 10% 0 5% 0;
  /* padding: 10% 0 2% 13%; */
  font-weight: 400;
}
.divide100 {
  width: 100%;
  height: 100px;
}
.divide40 {
  width: 100%;
  height: 40px;
}
.divide20 {
  width: 100%;
  height: 20px;
}
.intro-subtitle {
  font-weight: 300;
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 75px;
}
.h2light {
  font-weight: 400 !important;
  text-align: center !important;
}
.social li a i {
  font-size: 36px;
  border: none;
  color: white !important;
}

.text-social {
  display: inline-block;
  padding-top: 0px !important;
  margin-right: 0 !important;
  line-height: 36px;
  font-weight: 900;
  margin-right: 35px !important;
  font-family: 'Ubuntu', sans-serif !important;
}

.social {
  margin-right: 65px;
}

.list-footer {
  list-style: none;
  float: right;
}

.list-footer li {
  line-height: 28px;
}

.marcas {
  max-width: 80%;
  margin-top: 60px;
}

.marcas-align {
  text-align: right;
}

.icon-menu-1:before {
  content: '\f0c9';
}
.icon-menu:before {
  content: '\2630';
} /* '☰' */
.collapse {
  display: none;
}
.navbar-collapse.show {
  overflow-y: auto;
}
.yellow-boton {
  background: #ffff59;
  border-color: #ffff59;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  color: #333333;
  font-size: 20px;
  line-height: 12px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 12px 24px;
}

.hs-form .hs-button {
  color: black !important;
  background: #f1eb6c !important;
  margin-bottom: 10px !important;
  margin-right: 4px !important;
  padding: 11px 20px 10px 20px !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  text-shadow: none !important;
  border: none !important;
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
  text-transform: uppercase !important;
  -webkit-transition: all 200ms ease-in !important;
  -o-transition: all 200ms ease-in !important;
  -moz-transition: all 200ms ease-in !important;
  -webkit-box-shadow: 2px 3px 10px 0px #ddd !important;
  -moz-box-shadow: 2px 3px 10px 0px #ddd !important;
  box-shadow: 2px 3px 10px 0px #ddd !important;
  display: inline-block !important;
}

.hs-button {
  width: 100% !important;
}

.body-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.msgbanner {
  width: 32%;
  padding: 8% 0 0 0;
}

.header_area {
  width: 100% !important;
  /*height:100% !important;*/
  background-color: #fff !important;
  background-repeat: no-repeat;
  background-position: bottom center !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background: url('../assests/images/header.png') no-repeat;
}

 .header_area.help {
  background: url('../assests/images/header2.png') no-repeat;
}


.element-banner {
  position: relative;
  bottom: 0;
  z-index: 50;
  width: 100%;
}

.navbar-brand img {
  height: 40px !important;
}

.footer p {
  padding-top: 25px;
  margin-right: 30px;
  font-size: 20px !important;
  color: white;
}

.footer .navbar-nav > li > a {
  padding: 30px 10px;
}

.footer .navbar-nav {
  float: right;
}

.footer .link-header {
  font-size: 20px !important;
  color: white !important;
}

.footer {
  text-align: center;
}

.link-header {
  font-size: 25px !important;
  text-transform: none !important;
  color: white !important;
}

.link-header:hover {
  color: #313334 !important;
  text-decoration: none;
}

.navbar.fixed .navbar-brand img {
  height: 40px !important;
}

.navbar-header {
  background: none;
}

.navbar.fixed .navbar-header {
  background: url('../assests/images/header.png');
  background-position: center center;
  padding: 10px 0 25px 0;
}

.navbar.fixed .navbar-nav > li > a {
  padding: 22px 14px 0 14px;
}

.text-resume {
  font-size: 140%;
  color: #fff;
  line-height: 38px;
  /*padding:0 0 0 13%;*/
  font-weight: 400;
}

.area-full {
  width: 100%;
  background: #fff;
  position: relative;
  z-index: 200;
}

@media (min-width: 1400px) {
  .imgbanner {
    height: 800px !important;
    width: auto !important;
  }
}

.light-wrapper {
  background: #fff;
  margin: 0;
}

.form-group {
  margin-bottom: 35px;
}

.img80 {
  width: 80% !important;
}

.navbar-nav > li > a {
  font-size: 120% !important;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  padding: 40px 7px;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.powered {
  max-width: 100%;
}

.pull-right {
  float: right !important;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.link-header {
  font-size: 25px !important;
  text-transform: none !important;
  color: white !important;
}

@media (max-width: 991px) and (min-width: 768px) {
  .navbar-nav > li {
    margin-left: 5px;
  }
}

.powered {
  max-width: 70%;
}

@media (max-width: 991px) and (min-width: 768px) {
  .text-title {
    font-size: 270%;
  }
}

.navbar-nav > li {
  margin-left: 15px;
}

@media (min-width: 768px) {
  .navbar-nav > li {
    float: left;
  }
}

.nav > li {
  position: relative;
  display: block;
}

body,
li,
address {
  line-height: 24px;
}

@media (max-width: 767px) {
  .marcas {
    max-width: 100%;
    margin-top: 0;
  }
  .powered {
    max-width: 50%;
  }

  .navbar.fixed .navbar-header,
  .navbar .navbar-header {
    background: none !important;
  }
  .img80 {
    width: 100% !important;
  }
  .navbar-brand img {
    height: 35px !important;
  }
  .navbar .navbar-brand,
  .navbar.fixed .navbar-brand {
    padding-top: 20px;
  }
  .msgbanner {
    width: 42%;
  }
  .header_area {
    background: url('../assests/images/header2.png') no-repeat;
  }
  .text-resume {
    font-size: 15px;
    line-height: 18px;
    padding: 0 0 0 15px;
  }
  .text-title {
    font-size: 25px;
    line-height: 35px;
    padding: 10% 0 5% 15px;
  }
  .element-banner .col-xs-12 {
    padding: 0 !important;
  }
  .area-picture img {
    margin-right: -10px;
  }
  .area-picture {
    text-align: center;
    margin-bottom: 15px !important;
  }
  .content-text-middle {
    padding: 3% 10px 0 10px;
  }
  .content-text-middle2 {
    order: 2;
    text-align: left !important;
    padding: 0 3%;
  }
  .content-text-middle3 {
    padding: 0 3%;
  }
  .content-text-middle4 {
    padding: 0 3%;
  }
  .content-text-middle5 {
    order: 2;
    text-align: left !important;
    padding: 0 3%;
  }
  .text-social {
    text-align: center;
    display: block;
    margin: 20px!important;
    font-family: 'Ubuntu', sans-serif !important;
  }
  .boxElement {
    display: flex;
    flex-direction: column;
  }
  .boxElement img {
    margin: 0 !important;
  }
}

.ant-spin {
  color: #faad14 !important;
}
span > svg {
  width: 30px;
  height: 30px;
}

.link__button {
  display: inline-grid;
  position: absolute;
  left: 0;
  padding: 10px;
}

.helpIcon {
  width: 25px;
}

