.fJrVhw {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  justify-content: flex-start;
  height: 64px;
  background-color: rgb(255, 255, 255);
  padding: 0px 24px;
  border-bottom: 1px solid rgb(218, 222, 224);
  transition: color 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
}

.fJrVhw:focus {
  outline: -webkit-focus-ring-color auto 1px;
}

.fJrVhw a {
  color: #282f37;
  text-decoration: none;
}

.ixEuKd.active::after {
  background-color: rgb(38, 50, 56);
}

a.active.ixEuKd {
  border-bottom: 2px solid black;
}

.ixEuKd:hover::after,
.ixEuKd:focus::after,
.ixEuKd.active::after {
  width: 100%;
}

.ixEuKd::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 0%;
  height: 2px;
  transition: width 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
}

.ixEuKd {
  font-size: 16px;
  display: flex;

  align-items: center;
 
   margin: 0px 40px 0px 0px;
  cursor: pointer;
}

.navbarRight {
margin-left: auto;
margin-right: 10px;
}

@media screen and (max-width: 992px) {
  .fJrVhw {
    height: 48px;
  }
  .navbarRight {
    display: none;
    }
}
