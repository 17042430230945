.help .element-banner2 {
  position: relative;
  top: 25%;
  z-index: 50;
  width: 100%;
  padding-bottom: 5%;
  padding-top: 2%;
}

.cards {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.imgcards {
  width: 150px;
  margin: auto;
}

.card {
  margin: 20px;
  border-radius: 10%;
}

.card:hover {
  transform: scale(1.045);
}

.buttontext {
  background-color: #f0e849;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-weight: bold;
}

.buttontext:hover {
  background-color: #f0e849;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-weight: bold;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.formText {
  margin-top: 100px;
  margin-right: 20%;
  margin-bottom: 100px;
}

.ulgetstart {
  width: 90%;
  margin-top: 75px;
  margin-bottom: 90px;
  margin-left: 60px;
}

.helpText {
  text-align: center;
}

.textCollapse {
  margin-left: 20px;
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.textCollapse::after {
  margin-right: 100px;
}

.spanTextHelp {
  font-weight: normal;
  text-align: justify;
}

a.listgetstart {
  color: #333;
}

.imgCollapse{
  padding-top: 40px;
}

@media (max-width: 991px) {
  .cards {
    display: grid;
  }

  .formText {
    margin-left: 40px;
  }

  .ulgetstart {
    margin-right: 80px;
    margin-left: 25px;
  }

  .textCollapse {
    padding-right: 10px;
  }

  .imgCollapse{
    width: 300px;
  }
}
