@font-face {
  font-family: Inter;
  src: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.eot);
  src: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.eot#iefix)
      format('embedded-opentype'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.woff2)
      format('woff2'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.woff)
      format('woff'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.ttf)
      format('truetype'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.svg#Inter-Regular)
      format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.eot);
  src: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.eot#iefix)
      format('embedded-opentype'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.woff2)
      format('woff2'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.woff)
      format('woff'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.ttf)
      format('truetype'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.svg#Inter-Bold)
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.fDOEsD {
  background: rgb(255, 255, 255);
  width: 100%;
  display: block;
}
.fiqclI {
  display: flex !important;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 24px;
  height: 64px;
  border-bottom: 1px solid rgb(215, 220, 225);
  font-size: 14px;
  font-weight: bold;
}
.bwgTtw {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.fiqclI > div {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.fiqclI .sc-fOKMvo {
  padding: 0px;
  flex: 0 1 0%;
}
.bwgTtw span {
  text-align: left;
  white-space: nowrap;
  color: rgb(38, 50, 56);
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.32px;
}
.crRwcu {
  position: relative;
  margin-right: 32px;
  font-size: 14px;
  font-weight: normal;
}
.crRwcu::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 8px;
  background-color: rgb(57, 224, 155);
}
.fiqclI > svg {
  flex: 0 0 16px;
}
.kufnVs {
  width: 20px;
  height: 20px;
  fill: currentcolor;
  stroke: none;
  transition: transform 200ms linear 0s;
}
.EflSd {
  border-bottom: 1px solid rgb(215, 220, 225);
  position: relative;
}
.eQhQUy {
  width: 20px;
  height: 20px;
  fill: transparent;
  stroke: none;
}

.downloadButton {
  padding-left: 10px;
}

.admin-panel .btn-link[class*='animate'] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes spin-positioned-x {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(359deg);
  }
}

@keyframes spin-positioned-x {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(359deg);
  }
}

@-webkit-keyframes spin-positioned-xy {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@keyframes spin-positioned-xy {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@-webkit-keyframes breathing {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@-webkit-keyframes animate-buzz {
  0% {
    transform: translateX(3px) rotate(1deg);
  }
  2.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  5% {
    transform: translateX(3px) rotate(1deg);
  }
  7.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  10% {
    transform: translateX(2px) rotate(1deg);
  }
  12.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  15% {
    transform: translateX(2px) rotate(1deg);
  }
  17.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  20% {
    transform: translateX(1px) rotate(1deg);
  }
  22.5% {
    transform: translateX(-1px) rotate(-1deg);
  }
  25% {
    transform: translateX(0) rotate(0);
  }
}

@keyframes animate-buzz {
  0% {
    transform: translateX(3px) rotate(1deg);
  }
  2.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  5% {
    transform: translateX(3px) rotate(1deg);
  }
  7.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  10% {
    transform: translateX(2px) rotate(1deg);
  }
  12.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  15% {
    transform: translateX(2px) rotate(1deg);
  }
  17.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  20% {
    transform: translateX(1px) rotate(1deg);
  }
  22.5% {
    transform: translateX(-1px) rotate(-1deg);
  }
  25% {
    transform: translateX(0) rotate(0);
  }
}

.admin-panel .admin .link__highlight .animate-buzz.active,
.admin-panel .admin .link__highlight .animate-buzz:hover,
.admin-panel .service .animate-buzz {
  -webkit-animation-name: animate-buzz;
  animation-name: animate-buzz;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.admin-panel .service .animate-buzz:active,
.admin-panel .service .animate-buzz:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
}

@-webkit-keyframes animate-wobble {
  4.16% {
    transform: translateY(8px);
  }
  8.33% {
    transform: translateY(-6px);
  }
  12.48% {
    transform: translateY(4px);
  }
  16.66% {
    transform: translateY(-2px);
  }
  20.81% {
    transform: translateY(1px);
  }
  25% {
    transform: translateY(0);
  }
}

@keyframes animate-wobble {
  4.16% {
    transform: translateY(8px);
  }
  8.33% {
    transform: translateY(-6px);
  }
  12.48% {
    transform: translateY(4px);
  }
  16.66% {
    transform: translateY(-2px);
  }
  20.81% {
    transform: translateY(1px);
  }
  25% {
    transform: translateY(0);
  }
}

.admin-panel .admin .link__highlight .animate-wobble.active,
.admin-panel .admin .link__highlight .animate-wobble:hover,
.admin-panel .service .animate-wobble {
  -webkit-animation-name: animate-wobble;
  animation-name: animate-wobble;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.admin-panel .service .animate-wobble:active,
.admin-panel .service .animate-wobble:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
}

@-webkit-keyframes animate-pop {
  0% {
    transform: scale(1);
  }
  12.5% {
    transform: scale(1.05);
  }
  25% {
    transform: scale(1);
  }
}

@keyframes animate-pop {
  0% {
    transform: scale(1);
  }
  12.5% {
    transform: scale(1.05);
  }
  25% {
    transform: scale(1);
  }
}

.admin-panel .admin .link__highlight .animate-pop.active,
.admin-panel .admin .link__highlight .animate-pop:hover,
.admin-panel .service .animate-pop {
  -webkit-animation-name: animate-pop;
  animation-name: animate-pop;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.admin-panel .service .animate-pop:active,
.admin-panel .service .animate-pop:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
}

@-webkit-keyframes animate-swipe-admin {
  4.16%,
  8.33% {
    background-position: 0 0;
    color: #fff;
  }
  12.49%,
  20.83% {
    background-position: -100% 0;
    color: #777;
  }
  24.99%,
  29.16% {
    background-position: 0 0;
    color: #fff;
  }
  33.33% {
    background-position: 100% 0;
    color: #777;
  }
}

@keyframes animate-swipe-admin {
  4.16%,
  8.33% {
    background-position: 0 0;
    color: #fff;
  }
  12.49%,
  20.83% {
    background-position: -100% 0;
    color: #777;
  }
  24.99%,
  29.16% {
    background-position: 0 0;
    color: #fff;
  }
  33.33% {
    background-position: 100% 0;
    color: #777;
  }
}

.admin-panel .admin .link__highlight .animate-swipe {
  background-size: 200% auto;
  background-position: 100% 0;
  background-color: transparent !important;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  transition-timing-function: ease-in-out;
  background-image: linear-gradient(90deg, #777 50%, transparent 0);
  color: #777;
}

.admin-panel .admin .link__highlight .animate-swipe.active,
.admin-panel .admin .link__highlight .animate-swipe:hover {
  -webkit-animation-name: animate-swipe-admin;
  animation-name: animate-swipe-admin;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='american express'],
.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='diners club'],
.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='american_express'],
.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='diners_club'],
.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='discover'],
.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='jcb'],
.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='mastercard'],
.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='unknown'],
.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='visa'] {
  content: ' ';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 140px 129px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='american express'],
.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='american_express'] {
  background-position: 0 0;
  width: 40px;
  height: 25px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='diners club'],
.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='diners_club'] {
  background-position: -40px -50px;
  width: 40px;
  height: 25px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='discover'] {
  background-position: -80px 0;
  width: 40px;
  height: 25px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='jcb'] {
  background-position: -40px 0;
  width: 40px;
  height: 25px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='mastercard'] {
  background-position: 0 -25px;
  width: 40px;
  height: 25px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='unknown'] {
  background-position: -40px -25px;
  width: 40px;
  height: 25px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand[data-brand='visa'] {
  background-position: 0 -50px;
  width: 40px;
  height: 25px;
}

.admin-panel :after {
  content: 'small';
  display: none;
}

@media only screen and (min-width: 768px) {
  .admin-panel :after {
    content: 'medium';
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel :after {
    content: 'large';
  }
}

@media only screen and (min-width: 1260px) {
  .admin-panel :after {
    content: 'extra-large';
  }
}

.admin-panel .admin div.addnew-area {
  position: relative;
  margin: 24px 0;
}

@media only screen and (min-width: 768px) {
  .admin-panel .admin div.addnew-area {
    margin: 48px 0;
  }
}

.admin-panel .admin div.addnew-area .button {
  margin-left: 0 !important;
  width: 100%;
}

.admin-panel .admin div.addnew-area .dismissable {
  top: 13px;
}

@media only screen and (min-width: 768px) {
  .admin-panel .admin div.addnew-area .dismissable {
    position: absolute;
    left: 50%;
    top: 60px;
    transform: translateX(-50%);
    width: 360px;
  }
}

.admin-panel .link-list-group .reorder-handle {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 108px;
  float: none;
  cursor: move;
  text-align: center;
  background-color: #fff;
  border-right: 1px solid #d7dce1;
}

.admin-panel .link-list-group .reorder-handle > svg {
  position: absolute;
  width: 4px;
  height: 16px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.admin-panel .link-list-group .reorder-handle > svg path {
  fill: #acb5bf;
}

.admin-panel
  .link-list-group.hasHighlight
  .link:not(.highlighted)
  .link__inner
  .link__highlight
  div[class*='animate'] {
  opacity: 0.25;
  pointer-events: none;
}

.admin-panel
  .link-list-group.hasHighlight
  .link:not(.highlighted)
  .link__inner
  .link__highlight-overlay {
  pointer-events: auto;
  opacity: 1;
}

.admin-panel .link-list-group .link {
  min-height: 108px;
  display: block;
  padding: 0 0 16px;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  z-index: 2;
}

.admin-panel .link-list-group .link,
.admin-panel .link-list-group .link :not(input) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.admin-panel .link-list-group .link.sortable-chosen {
  cursor: -webkit-grabbing;
}

.admin-panel .link-list-group .link.sortable-ghost {
  opacity: 0;
}

.admin-panel .link-list-group .link.sortable-fallback {
  opacity: 1 !important;
}

.admin-panel .link-list-group .link.sortable-fallback .link__inner {
  box-shadow: 0 5px 25px rgba(31, 45, 61, 0.2);
}

.admin-panel
  .link-list-group
  .link.incomplete
  .link__inner
  label.link-active.checked {
  background-color: #ffcb03;
}

.admin-panel
  .link-list-group
  .link.incomplete
  .link__inner
  label.link-active.checked:after {
  transition: opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  content: '!';
  display: block;
  position: absolute;
  border: 1px solid #ffcb03;
  color: #ffcb03;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  font-weight: 700;
  left: -24px;
  opacity: 1;
  cursor: default;
  pointer-events: none;
}

.admin-panel
  .link-list-group
  .link.unsafe.incomplete
  .link__inner
  label.link-active.checked,
.admin-panel
  .link-list-group
  .link.unsafe
  .link__inner
  label.link-active.checked {
  background-color: #ff1731;
}

.admin-panel
  .link-list-group
  .link.unsafe.incomplete
  .link__inner
  label.link-active.checked:after,
.admin-panel
  .link-list-group
  .link.unsafe
  .link__inner
  label.link-active.checked:after {
  transition: opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  content: 'Ã—';
  display: block;
  position: absolute;
  border: 1px solid #ff1731;
  background-color: transparent;
  color: #ff1731;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  left: -24px;
  opacity: 1;
  cursor: default;
  pointer-events: none;
}

.admin-panel
  .link-list-group
  .link.scheduled-out
  .link__inner
  label.link-active.checked {
  background-color: #ffcb03;
}

.admin-panel
  .link-list-group
  .link.scheduled-out
  .link__inner
  .link__tab__navs
  .link__tab__nav--schedule {
  opacity: 1;
}

.admin-panel
  .link-list-group
  .link.scheduled-out
  .link__inner
  .link__tab__navs
  .link__tab__nav--schedule
  svg
  circle,
.admin-panel
  .link-list-group
  .link.scheduled-out
  .link__inner
  .link__tab__navs
  .link__tab__nav--schedule
  svg
  path {
  stroke: #ffcb03;
}

.admin-panel
  .link-list-group
  .link.scheduled-in
  .link__inner
  .link__tab__navs
  .link__tab__nav--schedule {
  opacity: 1;
}

.admin-panel
  .link-list-group
  .link.scheduled-in
  .link__inner
  .link__tab__navs
  .link__tab__nav--schedule
  svg
  circle,
.admin-panel
  .link-list-group
  .link.scheduled-in
  .link__inner
  .link__tab__navs
  .link__tab__nav--schedule
  svg
  path {
  stroke: #00d775;
}

.admin-panel .link-list-group .link .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 15px);
  background-color: #fff;
  z-index: 100;
  opacity: 0.4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-panel .link-list-group .link .overlay.deleted {
  opacity: 1;
  background-color: #ebeef1;
  transition: opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .link-list-group .link .overlay.deleted p {
  font-size: 12px;
}

.admin-panel .link-list-group .link .link__highlight div[class*='animate'] {
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .link-list-group .link .disabled .link__highlight > * {
  opacity: 0.25;
  pointer-events: none;
}

.admin-panel .link-list-group .link .disabled .link__highlight .panel__overlay {
  pointer-events: auto;
  opacity: 1;
}

.admin-panel .link-list-group .link .disabled {
  min-height: 160px;
}

.admin-panel .link-list-group .link .disabled .link__schedule > * {
  opacity: 0.25;
  pointer-events: none;
}

.admin-panel .link-list-group .link .disabled .panel__overlay {
  pointer-events: auto;
  opacity: 1;
}

.admin-panel .link-list-group .link .link__inner {
  display: block;
  position: relative;
  padding: 0 0 0 32px;
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 0 #d7dce1;
  transition: box-shadow 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  min-height: 135px;
  font-size: 14px;
}
.admin-panel .link-list-group .user .link__inner {
  min-height: 295px;
}

.admin-panel .link-list-group .link .link__inner .link-content {
  height: 160px;
  display: block;
  position: absolute;
  top: 0;
  left: 32px;
  right: 0;
}
.admin-panel .link-list-group .link .link__inner .link-content .button.yellow {
  width: 100px;
}

.admin-panel .link-list-group .link .link__inner .link-field input,
.admin-panel .link-list-group .link .link__inner .link-field span {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.admin-panel .link-list-group .link .link__inner .link-field span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 35px);
}

.admin-panel
  .link-list-group
  .link
  .link__inner
  .link-field.validation-failed
  input,
.admin-panel
  .link-list-group
  .link
  .link__inner
  .link-field.validation-failed
  span {
  color: #ff1731;
}

.admin-panel .link-list-group .link .link__inner .link-title {
  position: absolute;
  top: 25px;
  left: 12px;
  right: 70px;
  height: 22px;
  cursor: pointer;
}

.admin-panel .link-list-group .link .link__inner .link-title input,
.admin-panel .link-list-group .link .link__inner .link-title span {
  font-weight: 700;
}

.admin-panel .link-list-group .link .link__inner .link-title i {
  margin-bottom: 0;
}

.admin-panel .link-list-group .link .link__inner .link-url {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  white-space: normal;
  position: absolute;
  top: 60px;
  left: 12px;
  right: 70px;
  height: 22px;
}

.admin-panel .link-list-group .link .link__inner .link-url input,
.admin-panel .link-list-group .link .link__inner .link-url span {
  padding: 0;
  margin: 0;
  border: 0;
  color: #3d444b;
}

.admin-panel .link-list-group .link .link__inner .link-url i {
  margin-bottom: 2px;
}

.admin-panel .link-list-group .link .link__inner .link-title.placeholder span,
.admin-panel .link-list-group .link .link__inner .link-url.placeholder span {
  color: #686d73;
  font-size: large;
}

.admin-panel .link-list-group .link .link__inner .link-title input,
.admin-panel .link-list-group .link .link__inner .link-url input {
  display: none;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  border: 0;
  letter-spacing: 0;
  outline: none;
  width: 100%;
}

.admin-panel
  .link-list-group
  .link
  .link__inner
  .link-title
  input::-webkit-input-placeholder,
.admin-panel
  .link-list-group
  .link
  .link__inner
  .link-url
  input::-webkit-input-placeholder {
  opacity: 1;
  color: #686d73;
  font-size: large;
}

.admin-panel
  .link-list-group
  .link
  .link__inner
  .link-title
  input::-moz-placeholder,
.admin-panel
  .link-list-group
  .link
  .link__inner
  .link-url
  input::-moz-placeholder {
  opacity: 1;
  color: #686d73;
  font-size: large;
}

.admin-panel
  .link-list-group
  .link
  .link__inner
  .link-title
  input:-ms-input-placeholder,
.admin-panel
  .link-list-group
  .link
  .link__inner
  .link-url
  input:-ms-input-placeholder {
  opacity: 1;
  color: #686d73;
}

.admin-panel .link-list-group .link .link__inner .link-title span,
.admin-panel .link-list-group .link .link__inner .link-url span {
  display: inline-block;
  letter-spacing: 0;
}

.admin-panel .link-list-group .link .link__inner .link-title i,
.admin-panel .link-list-group .link .link__inner .link-url i {
  width: 17px;
  height: 11px;
  margin-left: 5px;
  vertical-align: top;
  cursor: pointer;
}

.admin-panel .link-list-group .link .link__inner .link-title i svg,
.admin-panel .link-list-group .link .link__inner .link-url i svg {
  fill: #bec1c3;
  width: 17px;
  height: 11px;
}

.admin-panel .link-list-group .link .link__inner .link-title.focused input,
.admin-panel .link-list-group .link .link__inner .link-url.focused input {
  display: block;
}

.admin-panel .link-list-group .link .link__inner .link-title.focused i,
.admin-panel .link-list-group .link .link__inner .link-title.focused span,
.admin-panel .link-list-group .link .link__inner .link-url.focused i,
.admin-panel .link-list-group .link .link__inner .link-url.focused span {
  display: none;
}

.admin-panel .link-list-group .link .link__inner label.link-active {
  transition: background-color 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  position: absolute;
  top: 12px;
  right: 12px;
  height: 20px;
  width: 32px;
  border-radius: 10px;
  background-color: #acb5bf;
  cursor: pointer;
}

.admin-panel .link-list-group .link .link__inner label.link-active input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 32px;
  height: 20px;
  cursor: pointer;
}

.admin-panel .link-list-group .link .link__inner label.link-active:after {
  opacity: 0;
}

.admin-panel .link-list-group .link .link__inner label.link-active:before {
  transition: left 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #fff;
  top: 1px;
  left: 1px;
  position: absolute;
}

.admin-panel .link-list-group .link .link__inner label.link-active.checked {
  background-color: #1f1c1c;
}

.admin-panel
  .link-list-group
  .link
  .link__inner
  label.link-active.checked:before {
  left: 13px;
}

.admin-panel [data-link-tab-amazon-affiliate] .link__affiliate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-wrap: wrap;
}

.admin-panel [data-link-tab-amazon-affiliate] .link__affiliate > div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 60px;
  margin: 0 5px 0 0;
}

.admin-panel [data-link-tab-amazon-affiliate] .link__affiliate > .active > div {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.admin-panel [data-link-tab-amazon-affiliate] .link__affiliate > div > div {
  width: 60px;
  height: 60px;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  border: 1px solid #efefef;
}

.admin-panel
  [data-link-tab-amazon-affiliate]
  .link__affiliate
  > div
  > div
  > span {
  pointer-events: none;
  margin-top: -21px;
  margin-left: -21px;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  display: block;
  transition: box-shadow 0.2s ease-in-out;
  padding: 10px;
  left: 50%;
  top: 50%;
  position: relative;
}

.admin-panel
  [data-link-tab-amazon-affiliate]
  .link__affiliate
  > div
  > div
  > span
  .icon--amazon {
  width: 22px;
  height: 22px;
  fill: #fff;
  left: 0;
}

.admin-panel
  [data-link-tab-amazon-affiliate]
  .link__affiliate
  > div
  > div.active,
.admin-panel
  [data-link-tab-amazon-affiliate]
  .link__affiliate
  > div
  > div:hover {
  box-shadow: 0 3px 2px rgba(100, 100, 100, 0.26);
  border-color: transparent;
}

.admin-panel [data-link-tab-amazon-affiliate] .amazon-handmade-normal div,
.admin-panel [data-link-tab-amazon-affiliate] .amazon-influencer-normal div {
  background-color: #fff;
}

.admin-panel
  [data-link-tab-amazon-affiliate]
  .amazon-handmade-normal
  div
  .icon--amazon,
.admin-panel
  [data-link-tab-amazon-affiliate]
  .amazon-influencer-normal
  div
  .icon--amazon {
  fill: #000 !important;
}

.admin-panel [data-link-tab-amazon-affiliate] .amazon-handmade-dot div,
.admin-panel [data-link-tab-amazon-affiliate] .amazon-influencer-dot div {
  background-color: #fff;
}

.admin-panel [data-link-tab-amazon-affiliate] .amazon-handmade-none div,
.admin-panel [data-link-tab-amazon-affiliate] .amazon-influencer-none div {
  background-color: #dfdfdf;
}

.admin-panel [data-link-tab-amazon-affiliate] .amazon-influencer-dot div span,
.admin-panel [data-link-tab-amazon-affiliate] .amazon-influencer-full div {
  background-color: #019083;
}

.admin-panel [data-link-tab-amazon-affiliate] .amazon-handmade-dot div span,
.admin-panel [data-link-tab-amazon-affiliate] .amazon-handmade-full div {
  background-color: #82dfe3;
}

.admin-panel [data-amazon-affiliate='0'] .link__tab__nav--amazon {
  display: none !important;
}

.admin-panel
  [data-amazon-affiliate]:not([data-amazon-affiliate='0'])
  .link__tab__nav--amazon {
  display: inline-block !important;
}

.admin-panel .link__tab_nav--amazon .icon--amazon {
  width: 13px !important;
  left: -3px;
  position: relative;
}

.admin-panel .link__tab__navs {
  position: absolute;
  right: 12px;
  bottom: 12px;
  display: flex;
}

.admin-panel .link__tab__navs a.link__tab__nav {
  font-size: 0;
  width: 16px;
  height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.admin-panel .link__tab__navs a.link__tab__nav--delete {
  margin-left: auto;
}

.admin-panel .link__tab__navs a.link__tab__nav:not(:last-of-type) {
  margin-right: 12px;
}

.admin-panel .link__tab__navs a.link__tab__nav svg {
  width: 16px;
  height: 16px;
}

.admin-panel .link__tab__navs a.link__tab__nav svg circle,
.admin-panel .link__tab__navs a.link__tab__nav svg path,
.admin-panel .link__tab__navs a.link__tab__nav svg rect {
  stroke: #687584;
  transition: stroke 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .link__tab__navs a.link__tab__nav.hidden {
  display: none !important;
}

.admin-panel .link__tab__navs a.link__tab__nav.active {
  color: #00b6ff;
}

.admin-panel .link__tab__navs a.link__tab__nav.active circle,
.admin-panel .link__tab__navs a.link__tab__nav.active path,
.admin-panel .link__tab__navs a.link__tab__nav.active rect {
  stroke: #00b6ff;
}

.admin-panel .link__tab__navs a.link__tab__nav.enabled {
  color: #00d775;
}

.admin-panel .link__tab__navs a.link__tab__nav.enabled circle,
.admin-panel .link__tab__navs a.link__tab__nav.enabled path,
.admin-panel .link__tab__navs a.link__tab__nav.enabled rect {
  stroke: #00d775;
}

.admin-panel .link__tab__navs a.link__tab__nav.invalid {
  color: #ffcb03;
}

.admin-panel .link__tab__navs a.link__tab__nav.invalid circle,
.admin-panel .link__tab__navs a.link__tab__nav.invalid path,
.admin-panel .link__tab__navs a.link__tab__nav.invalid rect {
  stroke: #ffcb03;
}

.admin-panel .link__tab__panels {
  background: #fff;
  margin-left: -32px;
  margin-top: 160px;
  color: #010203;
  height: 0;
}

.admin-panel .link__tab__panels .link__tab__panel {
  position: relative;
  overflow: hidden;
  padding: 46px 16px 16px;
  transition: none;
  background-color: #fff;
}

.admin-panel .link__tab__panels .link__tab__panel.loading {
  min-height: 120px;
}

.admin-panel .link__tab__panels .link__tab__panel.loading:before {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(30px) translate(-50%, -50%);
  background-image: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/loading-spinner.gif);
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.admin-panel .link__tab__panels .link__tab__panel .panel__header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ebeef1;
}

.admin-panel .link__tab__panels .link__tab__panel .panel__header h5 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 30px;
  color: #010203;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .panel__header
  a.x[data-close] {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  cursor: pointer;
  font-weight: 400;
  border: 2px solid transparent;
  top: 0;
  right: 0;
  font-size: 24px;
  color: #acb5bf;
  line-height: 23px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .panel__header
  a.x[data-close].btn {
  border: 2px solid #010203;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .panel__header
  a.x[data-close].btn:hover {
  color: #f4f4f4;
  text-decoration: none;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .panel__header
  a.x[data-close]:after {
  background-color: #010203;
}

.admin-panel .link__tab__panels .link__tab__panel .panel__body p,
.admin-panel .link__tab__panels .link__tab__panel .panel__overlay p {
  font-size: 14px;
  text-align: center;
}

.admin-panel .link__tab__panels .link__tab__panel .panel__body p:not(.error),
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .panel__overlay
  p:not(.error) {
  margin-top: 0 !important;
}

.admin-panel .link__tab__panels .link__tab__panel .panel__body p.error,
.admin-panel .link__tab__panels .link__tab__panel .panel__overlay p.error {
  margin: 10px 0 0;
  color: red;
  text-align: left;
  background-color: #fce2e5;
  border-radius: 5px;
  padding: 5px 10px;
}

.admin-panel .link__tab__panels .link__tab__panel .panel__overlay {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  padding: 16px;
  top: 30px;
  left: 0;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .link__tab__panels .link__tab__panel[data-link-tab-twitch],
.admin-panel .link__tab__panels .link__tab__panel[data-link-tab-youtube] {
  text-align: left;
}

.admin-panel .link__tab__panels .link__tab__panel .link__highlight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  flex-wrap: wrap;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link__highlight
  div[class*='animate'] {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  flex: 0 0 100%;
  border: 2px solid #777;
  color: #484848;
  font-size: 12px;
  height: 40px;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link__highlight
  div[class*='animate']:not(:last-of-type) {
  margin-bottom: 10px;
}

@media screen and (min-width: 600px) {
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link__highlight
    div[class*='animate'] {
    flex: 1;
    font-size: 16px;
    height: 60px;
  }
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link__highlight
    div[class*='animate']:not(:last-of-type) {
    margin-right: 15px;
    margin-bottom: 0;
  }
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link__highlight
  div[class*='animate'].active,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link__highlight
  div[class*='animate']:not(.active):hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.admin-panel .link__tab__panels .link__tab__panel .link__affiliate {
  padding: 20px 0;
}

@media screen and (min-width: 600px) {
  .admin-panel .link__tab__panels .link__tab__panel .link__affiliate {
    padding: 20px 10px;
  }
}

.admin-panel .link__tab__panels .link__tab__panel .totals {
  text-align: center;
  background-color: #f8f8f8;
  position: absolute;
  left: 0;
  right: 0;
  top: 31px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.admin-panel .link__tab__panels .link__tab__panel .totals div {
  display: inline-block;
  max-width: 130px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  margin-right: 10px;
  line-height: 30px;
  top: -1px;
  position: relative;
  color: rgba(1, 2, 3, 0.6);
}

@media only screen and (min-width: 768px) {
  .admin-panel .link__tab__panels .link__tab__panel .totals div {
    margin-right: 30px;
    max-width: 150px;
    font-size: 12px;
  }
}

.admin-panel .link__tab__panels .link__tab__panel .totals div:last-of-type {
  margin-right: 0;
}

.admin-panel .link__tab__panels .link__tab__panel .totals span.number {
  margin-left: 5px;
  color: rgba(1, 2, 3, 0.8);
}

.admin-panel .link__tab__panels .link__tab__panel .chart {
  padding: 50px 0 0;
}

@media only screen and (min-width: 768px) {
  .admin-panel .link__tab__panels .link__tab__panel .chart {
    padding-bottom: 10px;
    padding-left: 10px;
  }
}

.admin-panel .link__tab__panels .link__tab__panel a:not(.button) {
  color: #010203;
  padding: 5px 10px;
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  border: 2px solid transparent;
  vertical-align: middle;
}

.admin-panel .link__tab__panels .link__tab__panel a:not(.button):after {
  background-color: #010203;
}

.admin-panel .link__tab__panels .link__tab__panel .buttons {
  display: flex;
}

.admin-panel .link__tab__panels .link__tab__panel .buttons .button {
  flex: 1;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .buttons
  .button:not(:last-of-type) {
  margin-right: 16px;
}

.admin-panel .link__tab__panels .link__tab__panel .settings .setting__title {
  line-height: 18px;
}

.admin-panel .link__tab__panels .link__tab__panel .settings .setting {
  margin: 0;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-forwarding
  label {
  margin-bottom: 0;
}

.admin-panel .link__tab__panels .link__tab__panel .link-field.link-forwarding {
  display: none;
  margin-bottom: 20px;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-forwarding
  label
  span {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-forwarding
    label
    span {
    text-align: left;
  }
}

.admin-panel .link__tab__panels .link__tab__panel {
  display: none;
  color: #686d73;
  font-size: 35px;
  margin-bottom: 15px;
}

.admin-panel .link__tab__panels .link__tab__panel .active,
.admin-panel .link__tab__panels .link__tab__panel .active ~ .link-forwarding {
  display: block;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel.disabled-forwarding
  .link__forwarding,
.admin-panel
  .link__tab__panels
  .link__tab__panel.disabled-scheduling
  .link__forwarding,
.admin-panel .link__tab__panels .link__tab__panel.disabled .link__forwarding {
  min-height: 100px;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel.disabled-forwarding
  .link__forwarding
  > *,
.admin-panel
  .link__tab__panels
  .link__tab__panel.disabled-scheduling
  .link__forwarding
  > *,
.admin-panel
  .link__tab__panels
  .link__tab__panel.disabled
  .link__forwarding
  > * {
  opacity: 0.25;
  pointer-events: none;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel.disabled-scheduling
  .link__forwarding
  .panel__overlay {
  display: none;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel.disabled-scheduling
  .link__forwarding
  .panel__overlay.scheduling {
  display: flex;
  pointer-events: auto;
  opacity: 1;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel.disabled-forwarding
  .link__forwarding
  .panel__overlay {
  display: none;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel.disabled-forwarding
  .link__forwarding
  .panel__overlay.forwarding {
  display: flex;
  pointer-events: auto;
  opacity: 1;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel.disabled-forwarding
  .link__forwarding
  .panel__overlay.scheduling {
  display: none;
}

.admin-panel .link__tab__panels .link__tab__panel .countdown {
  position: relative;
  color: #0a0b0d;
}

.admin-panel .link__tab__panels .link__tab__panel .countdown p.copy {
  margin-bottom: 16px !important;
}

.admin-panel .link__tab__panels .link__tab__panel .countdown .clock {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.admin-panel .link__tab__panels .link__tab__panel .countdown.completed .clock {
  opacity: 0.5;
}

.admin-panel .link__tab__panels .link__tab__panel .countdown .unit {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
  margin: 0 10px 0 0;
}

.admin-panel .link__tab__panels .link__tab__panel .countdown .chars {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-panel .link__tab__panels .link__tab__panel .countdown .chars span {
  display: block;
  font-size: 32px;
  line-height: 32px;
}

.admin-panel .link__tab__panels .link__tab__panel .countdown .colon {
  position: absolute;
  right: -10px;
  font-size: 32px;
  line-height: 32px;
}

.admin-panel .link__tab__panels .link__tab__panel .countdown .label {
  font-size: 12px;
}

.admin-panel .link__tab__panels .link__tab__panel .countdown .expired {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 32px;
  font-weight: 700;
  color: #0a0b0d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-forwarding
  label,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-end
  label,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-start
  label {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-forwarding
    label,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-end
    label,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-start
    label {
    display: flex;
  }
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-forwarding
  label
  span,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-end
  label
  span,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-start
  label
  span {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  text-align: left;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-forwarding
    label
    span,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-end
    label
    span,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-start
    label
    span {
    font-size: 12px;
    width: auto;
    margin-right: 10px;
    min-width: 60px;
    flex: 1;
    text-align: right;
    height: 30px;
  }
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-forwarding
  label
  span
  i,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-end
  label
  span
  i,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-start
  label
  span
  i {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  text-decoration: underline;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-forwarding
  label
  input,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-end
  label
  input,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-start
  label
  input {
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ebeef1;
  padding: 5px;
  display: block;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-forwarding
    label
    input,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-end
    label
    input,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-start
    label
    input {
    height: 35px;
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-forwarding
    label
    input,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-end
    label
    input,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-start
    label
    input {
    padding: 1px 5px;
    font-size: 12px;
    display: inline-block;
    flex: 3;
    margin-right: 10px;
    width: auto;
  }
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-forwarding
  label
  select,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-end
  label
  select,
.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-start
  label
  select {
  font-size: 14px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ebeef1;
  display: block;
  padding: 5px;
}

@media only screen and (max-width: 767px) {
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-forwarding
    label
    select,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-end
    label
    select,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-start
    label
    select {
    height: 35px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-forwarding
    label
    select,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-end
    label
    select,
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-schedule-start
    label
    select {
    font-size: 12px;
    display: inline-block;
    flex: 3;
    width: 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel
    .link__tab__panels
    .link__tab__panel
    .link-field.link-forwarding
    label
    span {
    line-height: 30px;
  }
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-start {
  margin-top: 16px;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-start
  label {
  margin-bottom: 12px;
}

.admin-panel
  .link__tab__panels
  .link__tab__panel
  .link-field.link-schedule-end {
  margin-bottom: 16px;
}

.admin-panel .link-list-group {
  position: relative;
}

.admin-panel .link-list-group.loading:before {
  display: block;
  height: 100px;
}

.admin-panel .link-list-group.loading:after {
  display: block;
  visibility: visible;
  opacity: 1;
}

.admin-panel .link-list-group.loading .no-links {
  display: none;
}

.admin-panel .link-list-group:before {
  content: '';
  width: 100%;
  height: 0;
  margin: 0 auto 24px;
  transition: height 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  pointer-events: none;
}

.admin-panel .link-list-group:after {
  height: 24px;
  width: 24px;
  -webkit-animation: spin 0.4s linear infinite;
  animation: spin 0.4s linear infinite;
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/linktree-loader-24x24.svg');
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  content: '';
  position: absolute;
  display: none;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s,
    opacity 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0.25s;
  pointer-events: none;
  z-index: 1;
  -webkit-animation: spin-positioned-xy 0.4s linear infinite;
  animation: spin-positioned-xy 0.4s linear infinite;
}

.admin-panel [data-page-links] section.primary {
  padding-bottom: 200px;
  overflow-x: unset;
}

.admin-panel [data-page-links] .main .infinite-scroll-loader {
  position: relative;
  height: 0;
  overflow: visible;
}

.admin-panel [data-page-links] .main .infinite-scroll-loader span.loader {
  height: 24px;
  width: 24px;
  -webkit-animation: spin 0.4s linear infinite;
  animation: spin 0.4s linear infinite;
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/linktree-loader-24x24.svg');
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-50%);
  transition: all 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  display: block;
  position: absolute;
  top: 40px;
  left: 50%;
  z-index: -1;
  -webkit-animation: spin-positioned-x 0.4s linear infinite;
  animation: spin-positioned-x 0.4s linear infinite;
}

.admin-panel .noscript {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f5f6f8;
  z-index: 9999;
}

.admin-panel .noscript__message {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.admin-panel .noscript__logo {
  display: block;
  position: relative;
  width: 150px;
  height: 40px;
  margin: 0 auto 20px;
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/logo.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.admin-panel section.admin-sidebar {
  background-color: #fff;
  height: 48px;
  position: relative;
  z-index: 10001;
  border-bottom: 1px solid #d7dce1;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.admin-sidebar {
    height: 100%;
    max-height: 100vh;
    position: fixed;
    display: inline-block;
    width: 0px;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10001;
    border-bottom: 0;
    border-right: 1px solid #d7dce1;
  }
}

.admin-panel section.admin-sidebar .sidebar-logo-wrap {
  margin: 0 0 0 8px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  top: 4px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.admin-sidebar .sidebar-logo-wrap {
    margin: 0 auto;
    top: 12px;
  }
}

.admin-panel section.admin-sidebar .sidebar-logo-wrap a {
  cursor: pointer;
  display: block;
  width: 40px;
  height: 40px;
}

.admin-panel section.admin-sidebar .sidebar__icons {
  position: absolute;
  bottom: 84px;
  width: 64px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .admin-panel section.admin-sidebar .sidebar__icons {
    position: absolute;
    top: 0;
    right: 42px;
    height: 30px;
    top: 9px;
  }
}

.admin-panel section.admin-sidebar .sidebar__icons div.nav-item {
  width: 64px;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .admin-panel section.admin-sidebar .sidebar__icons div.nav-item {
    height: 30px;
  }
}

.admin-panel section.admin-sidebar .sidebar__icons div.nav-item > a {
  margin: 0 auto;
}

.admin-panel section.admin-sidebar .sidebar__icons div.nav-item svg {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 24px;
  fill: #acb5bf;
  transition: scale 0.2s cubic-bezier(1, 1.41, 1, 1.61);
  transform: scale(1);
}

.admin-panel section.admin-sidebar .sidebar__icons div.nav-item:hover svg {
  transform: scale(1.05);
  fill: rgba(172, 181, 191, 0.85);
}

.admin-panel section.admin-sidebar .sidebar__icons--support {
  visibility: visible;
}

.admin-panel section.admin-sidebar .sidebar__icons--headway {
  visibility: hidden;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.admin-sidebar .sidebar__icons--headway {
    visibility: visible;
  }
}

.admin-panel section.admin-sidebar .sidebar__icons--headway #HW_badge_cont {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
}

.admin-panel section.admin-sidebar .sidebar__icons--headway #HW_badge {
  background: #ff1731;
  top: 30px;
  left: 32px;
  visibility: hidden;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.admin-sidebar .sidebar__icons--headway #HW_badge {
    visibility: visible;
  }
}

.admin-panel
  section.admin-sidebar
  .sidebar__icons--headway
  span[data-count-unseen=''] {
  display: none !important;
}

.admin-panel
  section.admin-sidebar
  .sidebar__icons--headway
  span:not([data-count-unseen='']) {
  display: block !important;
}

.admin-panel section.admin-sidebar h1 {
  text-align: center;
  cursor: pointer;
  font-size: 0;
  width: 40px;
  height: 40px;
}

.admin-panel section.admin-sidebar h1 .logo {
  cursor: pointer;
  display: block;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 100%;
}

.admin-panel section.admin-sidebar h1 .logo svg {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 20px;
}

.admin-panel section.admin-sidebar .nav-item {
  /* position: relative; */
  width: 30px;
  padding: 0;
  cursor: pointer;
}

.admin-panel section.admin-sidebar .nav-item i {
  display: block;
  height: 2px;
  width: 24px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-1px);
  background-color: #0a0b0d;
}

.admin-panel section.admin-sidebar .nav-item i:before {
  top: -6px;
}

.admin-panel section.admin-sidebar .nav-item i:after,
.admin-panel section.admin-sidebar .nav-item i:before {
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  position: relative;
  background-color: #0a0b0d;
}

.admin-panel section.admin-sidebar .nav-item i:after {
  top: 4px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.admin-sidebar .nav-item i {
    display: none;
  }
}

.admin-panel .nav-item.login {
  position: absolute;
  right: 17px;
  top: 9px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .admin-panel .nav-item.login {
    text-align: left;
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .admin-panel .nav-item.login > a {
    display: none;
  }
}

.admin-panel .nav-item.login .img-circle {
  top: 53px;
  right: 45px;
  position: relative;
  z-index: 100;
  width: 30px;
  height: 30px;
  transition: visibility 0s linear 0.15s,
    opacity 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #ebeef1;
}

@media only screen and (min-width: 768px) {
  .admin-panel .nav-item.login .img-circle {
    top: auto;
    right: auto;
    width: 48px;
    height: 48px;
    opacity: 1;
    visibility: visible;
  }
}

.admin-panel .nav-item.login .img-circle img {
  position: absolute;
  width: 0;
  min-width: 30px;
  min-height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 768px) {
  .admin-panel .nav-item.login .img-circle img {
    min-width: 48px;
    min-height: 48px;
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel .nav-item.login {
    top: 5px;
    right: 0px;
    bottom: 0;
    margin-bottom: 24px;
    height: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel .nav-item:hover .img-circle,
  .admin-panel .nav-item:hover .sub-nav {
    transition: visibility 0s linear,
      opacity 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
    opacity: 1;
    visibility: visible;
  }
}

.admin-panel .nav-item.active .img-circle,
.admin-panel .nav-item.active .sub-nav {
  transition: visibility 0s linear,
    opacity 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 768px) {
  .admin-panel .nav-item {
    width: 64px;
  }
}

.admin-panel .sub-nav {
  max-height: 60vh;
  display: block;
  background-color: #fff;
  position: absolute;
  right: 50%;
  top: 24px;
  min-width: 245px;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  transition: visibility 0s linear 0.15s,
    opacity 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  opacity: 0;
  visibility: hidden;
  z-index: 1500;
  box-shadow: 0 0 0 1px rgba(136, 136, 136, 0.1),
    0 15px 35px 0 rgba(49, 49, 49, 0.05), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.admin-panel .sub-nav ul {
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .admin-panel .sub-nav {
    min-width: 300px;
    padding-top: 0;
    right: auto;
    left: -275px;
    text-align: left;
    top: auto;
    max-height: calc(100vh - 80px);
  }
}

.admin-panel .sub-nav .divider {
  padding: 10px 12px 5px;
  color: #0a0b0d;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.03em;
}

.admin-panel .sub-nav-item {
  list-style: none;
  list-style-type: none;
}

.admin-panel .sub-nav-item a,
.admin-panel .sub-nav-item p {
  display: block;
  color: #0a0b0d;
  padding: 10px 25px 10px 14px;
  margin: 10px;
  border-radius: 10px;
  position: relative;
  white-space: nowrap;
}

.admin-panel .sub-nav-item a:active,
.admin-panel .sub-nav-item a:focus,
.admin-panel .sub-nav-item a:hover,
.admin-panel .sub-nav-item a:visited,
.admin-panel .sub-nav-item p:active,
.admin-panel .sub-nav-item p:focus,
.admin-panel .sub-nav-item p:hover,
.admin-panel .sub-nav-item p:visited {
  text-decoration: none;
}

.admin-panel .sub-nav-item a:hover,
.admin-panel .sub-nav-item p:hover {
  color: #4f29cc;
  background-color: #eee8ff;
}

.admin-panel .sub-nav-item.primary a {
  padding-right: 30px;
}

.admin-panel .sub-nav-item.primary a svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  fill: #ffcb03;
}

.admin-panel .sub-nav-item.active a {
  background-color: #00d775;
}

.admin-panel .sub-nav-item.active a:hover {
  color: #0a0b0d;
}

.admin-panel .admin footer {
  left: 0;
  background: transparent;
}

.admin-panel .admin footer a {
  margin: 0 auto;
  display: block;
  width: 105px;
}

.admin-panel section.primary {
  position: relative;
  padding: 0 16px 100px;
  max-width: 672px;
  margin: 0 auto;
  overflow-x: no-scroll;
}

.admin-panel section.primary .primary.heading h2 {
  margin-top: 64px;
  font-size: 28px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.primary .primary.heading h2 {
    font-size: 32px;
  }
}

.admin-panel section.primary .primary.heading p {
  margin-bottom: 38px;
}

.admin-panel section.secondary .toolbar {
  display: block;
  position: relative;
  background-color: #fff;
}

.admin-panel section.secondary .toolbar .tip {
  position: absolute;
}

.admin-panel section.secondary .toolbar__inner {
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 14px;
  padding: 0 16px;
  border-bottom: 1px solid #d7dce1;
}

@media only screen and (min-width: 992px) {
  .admin-panel section.secondary .toolbar__inner {
    padding: 0 24px;
    height: 64px;
  }
}

.admin-panel section.secondary .toolbar__label {
  flex: 0;
  white-space: nowrap;
  font-weight: 700;
  margin-right: 4px;
}

.admin-panel section.secondary .toolbar__url {
  flex: 1;
  white-space: nowrap;
  min-width: 0;
  margin-right: 16px;
}

.admin-panel section.secondary .toolbar__url a {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
}

.admin-panel section.secondary .toolbar__button {
  flex: 0;
  white-space: nowrap;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #d7dce1;
  padding: 6px 12px;
  font-size: 14px;
  color: #686d73;
  text-decoration: none;
  cursor: pointer;
}

.admin-panel section.secondary .toolbar__button:hover {
  background-color: #f5f6f8;
}

.admin-panel section.preview .preview__title {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
  color: #888;
  text-align: center;
  position: relative;
  margin-top: 5vh;
  transform: translateY(-50%);
}

.admin-panel section.preview .preview-wrap {
  width: 352px;
  height: 730px;
  padding: 19px 16px;
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  transform: translateY(-50%) translateX(-50%) translateZ(0);
}

.admin-panel section.preview .preview-wrap {
  content: '';
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/preview-device.svg');
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.admin-panel section.preview .preview-wrap,
.admin-panel section.preview .preview-wrap * {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.admin-panel section.preview .preview-wrap .service-header {
  display: none !important;
}

.cardLoader {
  margin-top: 50px;
}

.loader {
  margin-top: 90%;
}

.admin-panel section.preview .preview-wrap span.loader {
  height: 24px;
  width: 24px;
  -webkit-animation: spin 0.4s linear infinite;
  animation: spin 0.4s linear infinite;
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/linktree-loader-24x24.svg');
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  display: block;
  position: absolute;
  top: 46px;
  left: 40px;
  opacity: 0;
  z-index: -1;
}

.admin-panel section.preview .preview-wrap.loading span.loader {
  opacity: 1;
  z-index: 1000;
}

.admin-panel section.preview .preview-inner {
  height: 100%;
  position: relative;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.admin-panel
  section.preview
  .preview-inner.has-forwarding
  .preview-area
  .service {
  padding-top: 60px;
}

.admin-panel section.preview .preview-inner.has-forwarding .preview-wrap:after {
  top: 110px;
}

.admin-panel section.preview .preview-area {
  position: relative;
  min-height: 100%;
  padding: 0 0 60px;
  z-index: 500;
}

.admin-panel section.secondary {
  transition: all 0.1s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  position: relative;
  padding: 0;
  width: 100%;
}

/* .admin-panel section.secondary section.preview {
  display: none;
} */
.admin-panel section.secondary .toolbar {
  display: none !important;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.secondary {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    min-height: 100vh;
    width: 300px;
    border-right: 1px solid #d7dce1;
  }
  .admin-panel section.secondary section.preview {
    display: block;
  }
  .admin-panel section.secondary .toolbar {
    display: block !important;
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel section.secondary {
    width: 435px;
  }
}

@media only screen and (min-width: 1260px) {
  .admin-panel section.secondary {
    width: 570px;
  }
}

.admin-panel section.secondary .inner {
  transition: all 0.5s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0.2s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 1;
}

.admin-panel section.secondary .inner.hidden {
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.secondary .inner.scrolled {
    position: fixed;
    left: auto;
  }
}

.admin-panel section.secondary .section__inner {
  overflow: hidden !important;
  position: relative;
  height: 100vh;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.secondary .section__inner {
    height: auto;
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel section.secondary .section__inner {
    top: 64px;
  }
}

.admin-panel .slideout-menu {
  position: fixed;
  left: auto;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 240px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
}

.admin-panel .slideout-panel {
  position: relative;
  z-index: 1;
}

.admin-panel .slideout-open,
.admin-panel .slideout-open .slideout-panel,
.admin-panel .slideout-open {
  overflow: hidden;
}

.admin-panel .slideout-open .slideout-menu {
  display: block;
}

.admin-panel .flatpickr-calendar {
  background: transparent;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 315px;
  box-sizing: border-box;
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08);
}

.admin-panel .flatpickr-calendar.inline,
.admin-panel .flatpickr-calendar.open {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px;
}

.admin-panel .flatpickr-calendar.open {
  display: inline-block;
  -webkit-animation: flatpickrFadeInDown 0.3s cubic-bezier(0, 1, 0.5, 1);
  animation: flatpickrFadeInDown 0.3s cubic-bezier(0, 1, 0.5, 1);
  z-index: 99999;
}

.admin-panel .flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.admin-panel .flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.admin-panel .flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.admin-panel .flatpickr-calendar.hasWeeks {
  width: auto;
}

.admin-panel .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 45px;
  border-top: 1px solid rgba(72, 72, 72, 0.1);
}

.admin-panel
  .flatpickr-calendar.showTimeInput.hasTime
  .flatpickr-innerContainer {
  border-bottom: 0;
}

.admin-panel .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border: 1px solid rgba(72, 72, 72, 0.1);
}

.admin-panel .flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.admin-panel .flatpickr-calendar:after,
.admin-panel .flatpickr-calendar:before {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}

.admin-panel .flatpickr-calendar.rightMost:after,
.admin-panel .flatpickr-calendar.rightMost:before {
  left: auto;
  right: 22px;
}

.admin-panel .flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.admin-panel .flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.admin-panel .flatpickr-calendar.arrowTop:after,
.admin-panel .flatpickr-calendar.arrowTop:before {
  bottom: 100%;
}

.admin-panel .flatpickr-calendar.arrowTop:before {
  border-bottom-color: rgba(72, 72, 72, 0.1);
}

.admin-panel .flatpickr-calendar.arrowTop:after {
  border-bottom-color: #38e29b;
}

.admin-panel .flatpickr-calendar.arrowBottom:after,
.admin-panel .flatpickr-calendar.arrowBottom:before {
  top: 100%;
}

.admin-panel .flatpickr-calendar.arrowBottom:before {
  border-top-color: rgba(72, 72, 72, 0.1);
}

.admin-panel .flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
  transform: translateY(-1px);
}

.admin-panel .flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.admin-panel .flatpickr-month {
  border-radius: 5px 5px 0 0;
  background: #38e29b;
  color: #fff;
  fill: #fff;
  height: 28px;
  line-height: 24px;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.admin-panel .flatpickr-next-month,
.admin-panel .flatpickr-prev-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 8px;
  height: 28px;
  line-height: 28px;
  z-index: 100;
}

.admin-panel .flatpickr-next-month i,
.admin-panel .flatpickr-prev-month i {
  position: relative;
}

.admin-panel .flatpickr-next-month.flatpickr-prev-month,
.admin-panel .flatpickr-prev-month.flatpickr-prev-month {
  left: calc(3.57% - 1.5px);
}

.admin-panel .flatpickr-next-month.flatpickr-next-month,
.admin-panel .flatpickr-prev-month.flatpickr-next-month {
  right: calc(3.57% - 1.5px);
}

.admin-panel .flatpickr-next-month:hover,
.admin-panel .flatpickr-prev-month:hover {
  color: #f4f4f4;
}

.admin-panel .flatpickr-next-month:hover svg,
.admin-panel .flatpickr-prev-month:hover svg {
  fill: #f64747;
}

.admin-panel .flatpickr-next-month svg,
.admin-panel .flatpickr-prev-month svg {
  width: 14px;
}

.admin-panel .flatpickr-next-month svg path,
.admin-panel .flatpickr-prev-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

.admin-panel .numInputWrapper {
  position: relative;
  height: auto;
}

.admin-panel .numInputWrapper input,
.admin-panel .numInputWrapper span {
  display: inline-block;
}

.admin-panel .numInputWrapper input {
  width: 100%;
}

.admin-panel .numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(72, 72, 72, 0.05);
  box-sizing: border-box;
}

.admin-panel .numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.admin-panel .numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.admin-panel .numInputWrapper span:after {
  display: block;
  content: '';
  position: absolute;
  top: 33%;
}

.admin-panel .numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.admin-panel .numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(72, 72, 72, 0.6);
}

.admin-panel .numInputWrapper span.arrowDown {
  top: 50%;
}

.admin-panel .numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(72, 72, 72, 0.6);
}

.admin-panel .numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.admin-panel .numInputWrapper span svg path {
  fill: rgba(255, 255, 255, 0.5);
}

.admin-panel .numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.admin-panel .numInputWrapper:hover span {
  opacity: 1;
}

.admin-panel .flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  top: 5px;
  display: inline-block;
  text-align: center;
}

.admin-panel .flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 7px;
  padding: 0;
}

.admin-panel .flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.admin-panel .flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch \0;
  display: inline-block;
}

.admin-panel .flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.admin-panel .flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}

.admin-panel .flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
}

.admin-panel .flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.admin-panel .flatpickr-current-month input.cur-year[disabled],
.admin-panel .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  pointer-events: none;
}

.admin-panel .flatpickr-weekdays {
  background: #38e29b;
  text-align: center;
  overflow: hidden;
  padding: 0 6px;
}

.admin-panel .flatpickr-days {
  padding: 5px 0 2px;
  outline: 0;
  text-align: left;
  width: 315px;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  border-right: 1px solid rgba(72, 72, 72, 0.1);
  border-left: 1px solid rgba(72, 72, 72, 0.1);
}

.admin-panel .flatpickr-day.inRange,
.admin-panel .flatpickr-day.nextMonthDay.inRange,
.admin-panel .flatpickr-day.nextMonthDay.today.inRange,
.admin-panel .flatpickr-day.nextMonthDay:focus,
.admin-panel .flatpickr-day.nextMonthDay:hover,
.admin-panel .flatpickr-day.prevMonthDay.inRange,
.admin-panel .flatpickr-day.prevMonthDay.today.inRange,
.admin-panel .flatpickr-day.prevMonthDay:focus,
.admin-panel .flatpickr-day.prevMonthDay:hover,
.admin-panel .flatpickr-day.selected,
.admin-panel .flatpickr-day.today.inRange,
.admin-panel .flatpickr-day:focus,
.admin-panel .flatpickr-day:hover {
  cursor: pointer;
  outline: 0;
  background: #f5f6f8;
  border-color: #f5f6f8;
}

.admin-panel .flatpickr-day.today {
  border: 1px solid #acb5bf;
}

.admin-panel .flatpickr-day.today:focus,
.admin-panel .flatpickr-day.today:hover {
  border-color: #ebeef1;
  background: #ebeef1;
  color: #fff;
}

.admin-panel .flatpickr-day.endRange,
.admin-panel .flatpickr-day.endRange.nextMonthDay,
.admin-panel .flatpickr-day.endRange.prevMonthDay,
.admin-panel .flatpickr-day.endRange:focus,
.admin-panel .flatpickr-day.endRange:hover,
.admin-panel .flatpickr-day.selected,
.admin-panel .flatpickr-day.selected.nextMonthDay,
.admin-panel .flatpickr-day.selected.prevMonthDay,
.admin-panel .flatpickr-day.selected:focus,
.admin-panel .flatpickr-day.selected:hover,
.admin-panel .flatpickr-day.startRange,
.admin-panel .flatpickr-day.startRange.nextMonthDay,
.admin-panel .flatpickr-day.startRange.prevMonthDay,
.admin-panel .flatpickr-day.startRange:focus,
.admin-panel .flatpickr-day.startRange:hover {
  background: #38e29b;
  color: #fff;
  border-color: #38e29b;
}

.admin-panel .flatpickr-day.endRange.startRange,
.admin-panel .flatpickr-day.selected.startRange,
.admin-panel .flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px;
}

.admin-panel .flatpickr-day.endRange.endRange,
.admin-panel .flatpickr-day.selected.endRange,
.admin-panel .flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0;
}

.admin-panel .flatpickr-day.endRange.startRange.endRange,
.admin-panel .flatpickr-day.selected.startRange.endRange,
.admin-panel .flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}

.admin-panel .flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #f5f6f8, 5px 0 0 #f5f6f8;
}

.admin-panel .flatpickr-day.disabled,
.admin-panel .flatpickr-day.disabled:hover {
  pointer-events: none;
}

.admin-panel .flatpickr-day.disabled,
.admin-panel .flatpickr-day.disabled:hover,
.admin-panel .flatpickr-day.nextMonthDay,
.admin-panel .flatpickr-day.notAllowed,
.admin-panel .flatpickr-day.notAllowed.nextMonthDay,
.admin-panel .flatpickr-day.notAllowed.prevMonthDay,
.admin-panel .flatpickr-day.prevMonthDay {
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.admin-panel span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  color: rgba(0, 0, 0, 0.54);
  height: 27.3333333333px;
  line-height: 24px;
  background: #38e29b;
  text-align: center;
  display: block;
  float: left;
  width: 14.28%;
  font-weight: 700;
  margin: 0;
  padding-top: 3.3333333333px;
}

.admin-panel .rangeMode .flatpickr-day {
  margin-top: 1px;
}

.admin-panel .flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}

.admin-panel .flatpickr-weekwrapper .flatpickr-weeks {
  padding: 1px 12px 0;
  border-left: 1px solid rgba(72, 72, 72, 0.1);
  box-shadow: 1px 0 0 rgba(72, 72, 72, 0.1);
}

.admin-panel .flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
}

.admin-panel .flatpickr-weekwrapper span.flatpickr-day {
  display: block;
  width: 100%;
  max-width: none;
}

.admin-panel .flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid rgba(72, 72, 72, 0.1);
}

.admin-panel .flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.admin-panel .flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  transition: height 0.33s cubic-bezier(0, 1, 0.5, 1);
  display: flex;
  background: #fff;
  border-radius: 0 0 5px 5px;
}

.admin-panel .flatpickr-time:after {
  content: '';
  display: table;
  clear: both;
}

.admin-panel .flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.admin-panel .flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848;
}

.admin-panel .flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848;
}

.admin-panel .flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.admin-panel .flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.admin-panel .flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #484848;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
}

.admin-panel .flatpickr-time input.flatpickr-hour {
  font-weight: 700;
}

.admin-panel .flatpickr-time input.flatpickr-minute,
.admin-panel .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.admin-panel .flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.admin-panel .flatpickr-time .flatpickr-am-pm,
.admin-panel .flatpickr-time .flatpickr-time-separator {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #484848;
  font-weight: 700;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.admin-panel .flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.admin-panel .flatpickr-time .flatpickr-am-pm:focus,
.admin-panel .flatpickr-time .flatpickr-am-pm:hover {
  background: #ececec;
}

.admin-panel .hasTime .flatpickr-days,
.admin-panel .hasWeeks .flatpickr-days {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.admin-panel .hasWeeks .flatpickr-days {
  border-left: 0;
}

@media (-ms-high-contrast: none) {
  .admin-panel .flatpickr-month {
    padding: 0;
  }
  .admin-panel .flatpickr-month svg {
    top: 0 !important;
  }
}

.admin-panel .flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes flatpickrFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes flatpickrFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.admin-panel .flatpickr-calendar .flatpickr-current-month.slideLeft,
.admin-panel .flatpickr-calendar .flatpickr-current-month.slideRight {
  display: none !important;
}

.admin-panel .flatpickr-calendar .flatpickr-current-month.slideLeftNew {
  transform: translateZ(0) !important;
}

.admin-panel .flatpickr-calendar .dayContainer.slideLeftNew {
  display: block !important;
}

.admin-panel .flatpickr-calendar .dayContainer.slideLeft,
.admin-panel .flatpickr-calendar .dayContainer.slideLeftNew.slideRight,
.admin-panel .flatpickr-calendar .dayContainer.slideRight {
  display: none !important;
}

.admin-panel .flatpickr-calendar .flatpickr-day {
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 24.285714%;
  flex-basis: 22.285714%;
  max-width: 39px !important;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}

.admin-panel .flatpickr-calendar .dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 302.875px;
  min-width: 290.875px;
  max-width: 302.875px;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  transform: translateZ(0);
  opacity: 1;
}

.admin-panel .color-picker {
  display: inline-flex;
  flex-direction: row;
}

.admin-panel .color-picker.error .color-input {
  border-color: #ff1731;
}

.admin-panel .color-picker.disabled .color-display:active,
.admin-panel .color-picker.disabled .color-input {
  pointer-events: none;
}

.admin-panel .color-picker .color-display,
.admin-panel .color-picker .color-input {
  border-radius: 8px;
  border: 1px solid #d7dce1;
}

.admin-panel .color-picker .color-display {
  width: 48px;
  height: 48px;
  margin-right: 12px;
  cursor: pointer;
}

.admin-panel .color-picker .color-input {
  color: #0a0b0d;
  padding: 0 12px;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  min-width: 85px;
  max-width: 100px;
  width: 100%;
  transition: border-color 0.2s ease-in-out;
}

.admin-panel .tippy-tooltip.colorpicker-theme {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d7dce1;
}

.admin-panel .tippy-tooltip.colorpicker-theme .tippy-content {
  padding: 8px !important;
}

.admin-panel section.payment-message {
  position: relative;
  text-align: center;
  padding: 16px;
  font-weight: 700;
  font-size: 14px;
}

.admin-panel section.payment-message span {
  max-width: 465px;
  width: 90%;
}

.admin-panel section.payment-warning-message {
  background-color: #ff1731;
  color: #fff;
}

.admin-panel section.payment-pending-message {
  background-color: rgba(255, 203, 3, 0.5);
}

.admin-panel div.multi-select-heading {
  margin-top: 26px;
}

.admin-panel div.multi-select-heading span {
  font-size: 16px;
  display: block;
}

.admin-panel div.multi-select-heading span:first-of-type {
  line-height: 1.75;
  letter-spacing: -0.32px;
  color: #323232;
  margin-bottom: 8px;
  font-weight: 700;
  display: block;
}

.admin-panel div.multi-select-heading span:nth-of-type(2) {
  line-height: 1.5;
  color: rgba(50, 50, 50, 0.6);
  letter-spacing: -0.16px;
}

.admin-panel div.multi-select-other {
  display: flex;
  align-items: flex-start;
}

.admin-panel div.multi-select-other label {
  flex: 0;
}

.admin-panel div.multi-select-other input[type='text'] {
  flex: 1;
  opacity: 0;
  border: 0;
  border-bottom: 2px solid #666;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  outline: 0;
  width: 100%;
  transition: border-bottom-color 0.2s ease-in-out;
}

.admin-panel div.multi-select-other input[type='text']:focus {
  border-color: #28bf7b;
}

.admin-panel
  div.multi-select-other
  input[type='checkbox']:checked
  ~ input[type='text'] {
  opacity: 1;
  pointer-events: auto;
}

.admin-panel div.multi-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -8px;
  margin-top: 26px;
  overflow: hidden;
}

.admin-panel div.multi-select label {
  border-radius: 16px;
  background-color: #f2f2f2;
  cursor: pointer;
  padding: 8px 16px;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: -0.24px;
  color: #323232;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-right: 8px;
}

@media (max-width: 600px) {
  .admin-panel div.multi-select label {
    margin-bottom: 12px;
  }
}

.admin-panel div.multi-select label::-moz-selection {
  background: transparent;
}

.admin-panel div.multi-select label::selection {
  background: transparent;
}

.admin-panel div.multi-select input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  left: -9999px;
}

.admin-panel
  div.multi-select
  input[type='checkbox']:focus:not(:checked)
  + label {
  background-color: #e5e5e5;
}

.admin-panel div.multi-select input[type='checkbox']:checked + label {
  color: #fff;
  background-color: #28bf7b;
}

.admin-panel label.active-input {
  height: 35px;
  position: relative;
  color: #686d73;
  display: block;
  margin-top: 28px;
  margin-bottom: 0;
}

.admin-panel label.active-input > .span {
  position: absolute;
  top: 0;
  left: 0;
  width: 85%;
  height: 100%;
  font-weight: 300;
  line-height: 32px;
  color: #686d73;
  border-bottom: 1px solid #d7dce1;
  transition: border-bottom-color 0.2s ease-in-out;
  cursor: text;
  pointer-events: none;
}

.admin-panel label.active-input > span span {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 50%;
  transition: transform 0.2s ease-in-out;
  cursor: text;
}

.admin-panel label.active-input > span.spinner {
  -webkit-animation: spin-positioned-xy 0.4s linear infinite;
  animation: spin-positioned-xy 0.4s linear infinite;
  border: 0 !important;
  position: absolute;
  margin: 0;
  top: 50%;
  right: 0;
  left: auto;
}

.admin-panel label.active-input.with-prepend-text {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.admin-panel label.active-input.with-prepend-text .prepend-text {
  flex: 0 auto;
  order: 1;
  pointer-events: none;
  padding-bottom: 2px;
  opacity: 0.5;
  transition: opacity 0.2s 0s;
  color: #282f37 !important;
}

.admin-panel label.active-input.with-prepend-text input {
  order: 2;
  flex: 1;
}

.admin-panel
  label.active-input.with-prepend-text
  input.is-empty
  ~ .prepend-text {
  opacity: 0;
}

.admin-panel
  label.active-input.with-prepend-text
  input.is-focused
  ~ .prepend-text {
  opacity: 0.5;
  transition: opacity 0.6s 0.2s;
}

.admin-panel label.active-input.error {
  color: #ff1731;
}

.admin-panel label.active-input.error > span {
  color: #ff1731;
  border-bottom-color: #ff1731 !important;
}

.admin-panel label.active-input.error .field {
  color: #ff1731;
}

.admin-panel label.active-input.hidden {
  position: absolute;
  opacity: 0;
}

.admin-panel label.active-input.visible {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.admin-panel label.active-input .field.is-focused + span span,
.admin-panel label.active-input .field:not(.is-empty) + span span,
.admin-panel label.active-input select.field span span {
  transform: scale(0.68) translateY(-31px);
  cursor: default;
}

.admin-panel label.active-input .field.is-focused + span,
.admin-panel label.active-input select.field:focus + span {
  border-bottom-color: #00d775;
}

.admin-panel label.active-input .field {
  background: transparent;
  font-weight: 300;
  border: 0;
  color: #282f37;
  outline: none;
  display: block;
  width: 100%;
  line-height: 32px;
  padding-bottom: 3px;
  transition: opacity 0.2s ease-in-out;
}

.admin-panel label.active-input .field::-webkit-input-placeholder {
  color: #686d73;
}

.admin-panel label.active-input .field::-moz-placeholder {
  color: #686d73;
}

.admin-panel label.active-input .field:-ms-input-placeholder {
  color: #686d73;
}

.admin-panel label.active-input .field.is-empty:not(.is-focused) {
  opacity: 0;
}

.admin-panel label.active-input select.field {
  cursor: pointer;
  height: 100%;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='#666'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 10px;
  background-position: calc(100% - 10px) 50%;
  background-repeat: no-repeat;
}

.admin-panel .account-validation-error,
.admin-panel [data-active-input-error] {
  display: block;
  max-height: 0;
  overflow: hidden;
  position: relative;
  background-color: #ff4c63;
  color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 12px;
  transition: max-height 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    padding 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  padding: 0 10px;
  line-height: 20px;
}

.admin-panel .account-validation-error.active,
.admin-panel [data-active-input-error].active {
  max-height: 100px;
  padding: 5px 10px;
}

.admin-panel .account-validation-error li,
.admin-panel .account-validation-error ul,
.admin-panel [data-active-input-error] li,
.admin-panel [data-active-input-error] ul {
  padding: 0;
  margin: 0;
}

.admin-panel .account-validation-error {
  text-align: center;
  max-height: 100px;
  padding: 5px 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0 auto;
  max-width: 343px;
}

.admin-panel .account-validation-error + .fields {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.admin-panel .dialog {
  z-index: 10001;
  pointer-events: none;
  perspective: 2000px;
}

.admin-panel .dialog,
.admin-panel .dialog:before {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition-delay: 0.2s;
  transition: opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .dialog:before {
  content: '';
  z-index: 1;
  background-color: rgba(31, 45, 61, 0.08);
}

.admin-panel .dialog__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: top center;
  transform: rotateX(-30deg) translate(-50%, -50%);
  z-index: 2;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    transform 0.35s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  transition-delay: 0.35s;
  box-shadow: 0 0 0 1px rgba(136, 136, 136, 0.1),
    0 15px 35px 0 rgba(49, 49, 49, 0.05), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  max-width: 500px;
}

@media only screen and (max-width: 767px) {
  .admin-panel .dialog__inner {
    max-height: 90vh;
    max-width: 90vw;
    width: 100%;
  }
}

.admin-panel .dialog__header {
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #384656;
  color: #fff;
  padding: 20px 60px 20px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 16px;
}

.admin-panel .dialog__header div.x {
  position: absolute;
  width: 60px;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  padding: 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 20px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .dialog__header div.x:hover {
  opacity: 0.7;
}

.admin-panel .dialog__body {
  padding: 20px;
}

.admin-panel .dialog__body h5:first-of-type {
  margin-top: 0;
}

.admin-panel .dialog__body p {
  margin: 0.75rem 0;
}

.admin-panel .dialog__body p:first-of-type {
  margin-top: 0;
}

.admin-panel .dialog__body p:last-of-type {
  margin-bottom: 0;
}

.admin-panel .dialog__body a {
  text-decoration: underline;
}

.admin-panel .dialog__body p.buttons {
  margin-top: 1.5rem;
  text-align: center;
  display: flex;
}

.admin-panel .dialog__body p.buttons .button {
  margin-right: 8px;
}

.admin-panel .dialog__body .btn {
  transition: opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  font-size: 12px;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .admin-panel .dialog__body .btn {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0 !important;
  }
  .admin-panel .dialog__body .btn:last-of-type {
    margin-bottom: 0;
  }
}

.admin-panel .dialog__body .btn:first-of-type {
  margin-left: 0;
}

.admin-panel .dialog__body .btn:hover {
  opacity: 0.7;
}

.admin-panel .dialog--warning .dialog__header {
  background-color: #ff1731;
}

.admin-panel .dialog.dialog-active {
  opacity: 1;
  pointer-events: all;
  transition-delay: 0s;
}

.admin-panel .dialog.dialog-active:before {
  opacity: 1;
}

.admin-panel .dialog.dialog-active .dialog__inner {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition-delay: 0.15s;
}

.admin-panel .dialog--unsubscribe {
  background: rgba(31, 45, 61, 0.75);
}

.admin-panel .dialog--unsubscribe .dialog__inner {
  max-width: 650px;
}

.admin-panel .dialog--unsubscribe .dialog--padding {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.admin-panel .dialog--unsubscribe .dialog--sidepadding {
  padding-left: 40px;
  padding-right: 40px;
}

.admin-panel .loading-overlay {
  display: block;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel > .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10002;
}

.admin-panel > .loading-overlay.active {
  pointer-events: all;
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel > .loading-overlay:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/loading-spinner.gif');
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
  transition: all 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  content: '';
  display: block;
}

.admin-panel .section {
  margin-bottom: 32px;
}

@media only screen and (min-width: 768px) {
  .admin-panel .section {
    margin-bottom: 48px;
  }
}

.admin-panel .section h2 {
  margin-top: 96px;
  margin-bottom: 8px;
}

.admin-panel .section h3 {
  margin-top: 32px;
  margin-bottom: 12px;
}

@media only screen and (min-width: 768px) {
  .admin-panel .section h3 {
    margin-top: 48px;
    margin-bottom: 16px;
  }
}

.admin-panel .section h5 {
  margin-top: 24px;
  margin-bottom: 8px;
}

.admin-panel .section .section__inner {
  background-color: #fff;
  border-left: 0;
  border-radius: 4px;
  border-top: 0;
  padding: 24px 16px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .admin-panel .section .section__inner {
    padding: 32px 24px;
  }
}

.admin-panel
  .section
  .section__inner
  .setting:first-child
  .setting__heading:first-child
  h5 {
  margin-top: 0;
}

.admin-panel .section .section__inner .setting .setting__tip {
  font-size: 14px;
  line-height: 1.57;
  margin: 0;
}

.admin-panel .section .section__inner .setting .setting__heading {
  display: flex;
  align-items: end;
}

.admin-panel .section .section__inner .setting .setting__heading:only-child h5 {
  margin: 0;
}

.admin-panel .section .section__inner .setting .setting__heading h5 {
  flex: 0 auto;
}

.admin-panel .section .section__inner .setting .setting__heading a {
  flex: 1 auto;
}

.admin-panel .section .section__inner .setting .setting__body {
  transition: opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  opacity: 0.4;
}

.admin-panel .section .section__inner .setting .setting__body.active {
  opacity: 1;
}

.admin-panel .section .section__inner .setting .setting__input-group {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1),
    opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .section .section__inner .setting .setting__input-group.active {
  opacity: 1;
  overflow: visible;
  transition: max-height 0.3s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99) 175ms;
}

.admin-panel .section .section__inner .setting .setting__checkbox {
  flex: 0 auto;
  height: 20px;
  width: 32px;
  border-radius: 10px;
  background-color: #bbb;
  cursor: pointer;
  position: relative;
  margin-bottom: 0;
  margin-left: auto;
  transition: background-color 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .section .section__inner .setting .setting__checkbox:before {
  transition: left 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  background-color: #fff;
  top: 1px;
  left: 1px;
  position: absolute;
}

.admin-panel .section .section__inner .setting .setting__checkbox.checked {
  background-color: #39e09b;
}

.admin-panel
  .section
  .section__inner
  .setting
  .setting__checkbox.checked:before {
  left: 13px;
}

.admin-panel .section .section__inner .setting .setting__checkbox input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 32px;
  height: 20px;
  cursor: pointer;
}

.admin-panel .section--legacy .section__inner {
  padding: 20px !important;
}

.admin-panel .section--medium .section__inner {
  padding: 24px 24px 32px !important;
}

.admin-panel .section--small .section__inner {
  padding: 13px 24px !important;
}

.admin-panel .grid {
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, 1fr);
}

.admin-panel .grid.grid-type--card {
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
}

.admin-panel .grid.grid-type--card .grid__inner {
  padding-top: 150%;
}

.admin-panel .grid.grid-type--square {
  grid-template-columns: repeat(auto-fit, minmax(99px, 1fr));
}

.admin-panel .grid.grid-type--square .grid__inner {
  padding-top: 100%;
}

.admin-panel .grid.grid-type--rectangle {
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 350px) {
  .admin-panel .grid.grid-type--rectangle {
    grid-template-columns: 1fr;
  }
}

.admin-panel .grid.grid-type--rectangle .grid__inner {
  padding-top: 25%;
}

.admin-panel .grid .grid__item {
  display: flex;
  flex-direction: column;
}

.admin-panel .grid .grid__item .grid__inner {
  display: block;
  position: relative;
}

.admin-panel .grid .grid__item .grid__inner .grid__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.admin-panel .grid .grid__item .grid__title {
  text-align: center;
  line-height: 1.57;
  font-size: 14px;
  padding-top: 8px;
}

.admin-panel .admin {
  background-color: #f5f6f8;
  overflow-x: hidden;
}

@media only screen and (min-width: 768px) {
  .admin-panel .admin {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel .main-wrap {
    /* margin-left: 64px; */
    max-height: 100vh;
    overflow: hidden;
  }
}

.admin-panel .admin section.main.admin {
  padding-bottom: 0 !important;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.admin-panel section.main.admin .section__inner {
  position: relative;
}

.admin-panel section.main.admin .primary {
  width: 100%;
}

.admin-panel section.main.admin .primary .btn {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .admin-panel section.main.admin .primary .btn.edit-link {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .admin-panel section.main.admin .primary .btn {
    padding: 0.3rem 0.5rem;
  }
}

.admin-panel section.main.admin .primary > .main {
  left: 0;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .admin-panel .main-wrap {
    margin-left: 300px;
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel .main-wrap {
    margin-left: 435px;
  }
}

@media only screen and (min-width: 1260px) {
  .admin-panel .main-wrap {
    margin-left: 570px;
  }
}

.admin-panel .admin span.text-error {
  color: #ff1731;
}

.admin-panel .section.background .grid__inner {
  border-radius: 8px;
}

.admin-panel .section.background .grid__inner--flat {
  background-color: #3d444b;
}

.admin-panel .section.background .grid__inner--colordown {
  background-color: #686d73;
  background: linear-gradient(0deg, #686d73, #3d444b);
}

.admin-panel .section.background .grid__inner--colorup {
  background-color: #3d444b;
  background: linear-gradient(0deg, #3d444b, #686d73);
}

.admin-panel .section.background .grid__inner--custom {
  border: 1px dashed #3d444b;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
}

.admin-panel .section.background .grid__inner--custom .grid__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 42px;
  width: 42px;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px;
  background-size: calc(100% - 8px);
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("data:image/svg+xml;charset=utf-8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Crect width='15' height='15' x='.5' y='.5' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' rx='3'/%3E%3Ccircle cx='11.5' cy='4.5' r='2' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' d='M10.123 13.142L6.429 7.5l-3.694 5.642'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' d='M9.18 11.702L10.621 9.5l2.385 3.642'/%3E%3C/svg%3E");
}

.admin-panel .section.buttons .grid__inner[class*='fill'] {
  background-color: #282f37;
}

.admin-panel .section.buttons .grid__inner[class*='outline'] {
  border: 2px solid #282f37;
}

.admin-panel .section.buttons .grid__inner[class*='circular'] {
  border-radius: 100px;
}

.admin-panel .section.buttons .grid__inner[class*='rounded'] {
  border-radius: 8px;
}

.admin-panel .section.buttons .grid__inner[class*='hardshadow'] {
  border: 2px solid #282f37;
  box-shadow: 4px 4px 0 0 #282f37;
}

.admin-panel .section.buttons .grid__inner[class*='softshadow'] {
  border: 2px solid #282f37;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);
}

.admin-panel .section.buttons .grid__inner--formal-2-stroke {
  position: relative;
}

.admin-panel .section.buttons .grid__inner--formal-2-stroke:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
  display: block;
  border: 2px solid #282f37;
  padding: 0;
}

.admin-panel .section.buttons .grid__inner--formal-2-stroke:after {
  content: '';
  position: absolute;
  top: 0;
  left: 5px;
  right: 5px;
  bottom: 0;
  display: block;
  border: 2px solid #282f37;
  padding: 0;
}

.admin-panel .section.buttons .grid__inner--torn-edges {
  position: relative;
}

.admin-panel .section.buttons .grid__inner--torn-edges .grid__content {
  background-color: #282f37;
}

.admin-panel .section.buttons .grid__inner--torn-edges:before {
  -webkit-mask-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/link/torn-edges-top.svg');
  mask-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/link/torn-edges-top.svg');
  top: -4px;
}

.admin-panel .section.buttons .grid__inner--torn-edges:after,
.admin-panel .section.buttons .grid__inner--torn-edges:before {
  content: '';
  position: absolute;
  display: block;
  padding: 0;
  left: 0;
  right: 0;
  height: 5px;
  -webkit-mask-size: 640px 5px;
  mask-size: 640px 5px;
  -webkit-mask-position: 0 top;
  mask-position: 0 top;
  background-color: #282f37;
}

.admin-panel .section.buttons .grid__inner--torn-edges:after {
  -webkit-mask-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/link/torn-edges-bottom.svg');
  mask-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/link/torn-edges-bottom.svg');
  bottom: -4px;
}

.admin-panel .section.buttons .grid__inner--jiggly-edges {
  position: relative;
}

.admin-panel .section.buttons .grid__inner--jiggly-edges .grid__content {
  background-color: #282f37;
}

.admin-panel .section.buttons .grid__inner--jiggly-edges:before {
  -webkit-mask-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/link/jiggly-edges-top.svg');
  mask-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/link/jiggly-edges-top.svg');
  top: -2px;
}

.admin-panel .section.buttons .grid__inner--jiggly-edges:after,
.admin-panel .section.buttons .grid__inner--jiggly-edges:before {
  content: '';
  position: absolute;
  display: block;
  padding: 0;
  left: 0;
  right: 0;
  height: 3px;
  -webkit-mask-size: 640px 3px;
  mask-size: 640px 3px;
  -webkit-mask-position: 0 top;
  mask-position: 0 top;
  background-color: #282f37;
}

.admin-panel .section.buttons .grid__inner--jiggly-edges:after {
  -webkit-mask-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/link/jiggly-edges-bottom.svg');
  mask-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/link/jiggly-edges-bottom.svg');
  bottom: -2px;
}

.admin-panel .section.theme .grid__inner {
  border-radius: 8px;
  background-position: 50%;
  background-size: cover;
  background-color: #e9eaeb;
  border: 1px solid #ebeef1;
}

.admin-panel .section.theme .grid__inner--custom {
  border: 1px dashed #3d444b;
  background: none;
}

.admin-panel .section.theme .grid__inner--custom:before {
  content: 'Create your own';
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  padding: 8px;
  font-weight: 600;
  color: #0a0b0d;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-panel .section.theme .grid__inner--snow {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-snow.png');
}

.admin-panel .section.theme .grid__inner--smoke {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-smoke.png');
}

.admin-panel .section.theme .grid__inner--leaf {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-leaf.png');
}

.admin-panel .section.theme .grid__inner--moon {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-moon.png');
}

.admin-panel .section.theme .grid__inner--mint {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-mint.png');
}

.admin-panel .section.theme .grid__inner--miami {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-miami.png');
}

.admin-panel .section.theme .grid__inner--noir {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-noir.png');
}

.admin-panel .section.theme .grid__inner--bloom {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-bloom.png');
}

.admin-panel .section.theme .grid__inner--spray {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-spray.png');
}

.admin-panel .section.theme .grid__inner--block-colors {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-block-colors.png');
}

.admin-panel .section.theme .grid__inner--confetti {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-confetti.png');
}

.admin-panel .section.theme .grid__inner--starry-night {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-starry-night.png');
}

.admin-panel .section.theme .grid__inner--rainbow {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-rainbow.png');
}

.admin-panel .section.theme .grid__inner--valentine-2020 {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-valentine-2020.png');
}

.admin-panel .section.theme .grid__inner--valentine-rainbow-2020 {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-valentine-rainbow-2020.png');
}

.admin-panel .section.theme .grid__inner--selenagomez {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-selenagomez.png');
}

.admin-panel .section.theme .grid__inner--dillonfrancis {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-dillonfrancis.png');
}

.admin-panel .section.theme .grid__inner--neikedmusic {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-neikedmusic.png');
}

.admin-panel .section.theme .grid__inner--valentinokhan {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-valentinokhan.png');
}

.admin-panel .section.theme .grid__inner--diplo {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-diplo.png');
}

.admin-panel .section.theme .grid__inner--whatsonot {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-whatsonot.png');
}

.admin-panel .section.theme .grid__inner--majorlazer {
  background-image: url('https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/themes/selector-majorlazer.png');
}

.admin-panel .section.fonts .grid__inner {
  border-radius: 8px;
  border: 1px solid #ebeef1;
}

.admin-panel .section.fonts .grid__inner .grid__content svg {
  height: 100%;
  width: 100%;
}

.admin-panel .section.fonts .grid__inner .grid__content svg text {
  fill: #3d444b;
  font-size: 40px;
  dominant-baseline: central;
  text-anchor: middle;
}

.admin-panel .setting.button-style .setting__heading:first-child h5 {
  margin-top: 0;
}

.admin-panel .setting.background-color .setting__heading h5,
.admin-panel .setting.button-color .setting__heading h5,
.admin-panel .setting.font-color .setting__heading h5 {
  margin-top: 48px;
}

.admin-panel .active-highlight {
  transition: all 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  position: absolute;
  border: 2px solid #d7dce1;
  border-radius: 12px;
  pointer-events: none;
}

.admin-panel .section.background[data-visibility='PREVIEW_ACCESS'] h3,
.admin-panel .section.buttons[data-visibility='PREVIEW_ACCESS'] h3,
.admin-panel .section.fonts[data-visibility='PREVIEW_ACCESS'] h3,
.admin-panel .section.whitelabel[data-visibility='PREVIEW_ACCESS'] h5 {
  display: flex;
  align-items: center;
  position: relative;
}

.admin-panel .section.background[data-visibility='PREVIEW_ACCESS'] h3:after,
.admin-panel .section.buttons[data-visibility='PREVIEW_ACCESS'] h3:after,
.admin-panel .section.fonts[data-visibility='PREVIEW_ACCESS'] h3:after,
.admin-panel .section.whitelabel[data-visibility='PREVIEW_ACCESS'] h5:after {
  content: 'PRO';
  background-image: url("data:image/svg+xml;charset=utf-8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h16v16H0z'/%3E%3Cpath fill='%23fff' d='M13 6h-1V4a4 4 0 00-8 0v2H3a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2V8a2 2 0 00-2-2zm-5 6a1 1 0 111-1 1 1 0 01-1 1zM6 6V4a2 2 0 014 0v2z'/%3E%3C/svg%3E");
  background-size: 12px;
  background-position: top 6px right 8px;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  background-color: #0a0b0d;
  text-transform: uppercase;
  padding: 2px 24px 2px 8px;
  cursor: pointer;
  margin-left: 8px;
}

.admin-panel .section.theme .grid__item[data-visibility='PREVIEW_ACCESS'] {
  position: relative;
}

.admin-panel
  .section.theme
  .grid__item[data-visibility='PREVIEW_ACCESS']
  .grid__inner {
  opacity: 0.45;
}

.admin-panel
  .section.theme
  .grid__item[data-visibility='PREVIEW_ACCESS']
  .grid__inner--custom {
  opacity: 1;
  border-color: rgba(61, 68, 75, 0.45);
}

.admin-panel
  .section.theme
  .grid__item[data-visibility='PREVIEW_ACCESS']:after {
  content: 'PRO';
  background-image: url("data:image/svg+xml;charset=utf-8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h16v16H0z'/%3E%3Cpath fill='%23fff' d='M13 6h-1V4a4 4 0 00-8 0v2H3a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2V8a2 2 0 00-2-2zm-5 6a1 1 0 111-1 1 1 0 01-1 1zM6 6V4a2 2 0 014 0v2z'/%3E%3C/svg%3E");
  background-size: 12px;
  background-position: top 6px right 8px;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  background-color: #0a0b0d;
  text-transform: uppercase;
  padding: 2px 24px 2px 8px;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
}

.admin-panel .section.background[data-visibility='PREVIEW_ACCESS'] .setting,
.admin-panel .section.buttons[data-visibility='PREVIEW_ACCESS'] .setting,
.admin-panel .section.fonts[data-visibility='PREVIEW_ACCESS'] .setting,
.admin-panel
  .section.whitelabel[data-visibility='PREVIEW_ACCESS']
  h5
  + .setting__checkbox {
  opacity: 0.25;
}

.admin-panel .grid .grid__item .grid__inner {
  cursor: pointer;
}

.admin-panel [data-page-settings] section.primary {
  overflow: initial;
}

.admin-panel .grid-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 32px;
  position: relative;
}

@media (max-width: 659px) {
  .admin-panel .grid-container {
    grid-gap: 24px;
  }
}

.admin-panel .grid-container--3 {
  grid-template-columns: repeat(3, 1fr);
}

.admin-panel .grid-container--4 {
  grid-template-columns: repeat(4, 1fr);
}

.admin-panel .grid-container--5 {
  grid-template-columns: repeat(5, 1fr);
}

.admin-panel .grid-container .grid-item {
  display: flex;
  margin: 0;
}

.admin-panel .grid-container .grid-item input {
  display: none;
}

.admin-panel .grid-container .grid-item:before {
  content: '';
  padding-top: 100%;
  display: block;
}

.admin-panel .grid-container .grid-item--inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  border-radius: 8px;
  width: 100%;
}

@media (max-width: 659px) {
  .admin-panel .theme-options .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 659px) {
  .admin-panel .background-styles .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 499px) {
  .admin-panel .font-styles .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 500px) and (max-width: 659px) {
  .admin-panel .font-styles .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.admin-panel .settings-wrap .social-links .social-link:not(:last-child) {
  margin-bottom: 24px;
}

.admin-panel
  .settings-wrap
  .social-links
  .social-link:first-of-type
  .active-input {
  margin-top: 8px;
}

.admin-panel
  .settings-wrap
  .analytics
  .utm-parameters
  .setting__input-group.active {
  max-height: 230px;
}

.admin-panel .settings-wrap .active-highlight {
  transition: all 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  position: absolute;
  border: 2px solid #d4d5d7;
  border-radius: 10px;
}

.admin-panel [data-page-setup] section.main.admin section.primary {
  overflow: visible;
}

.admin-panel [data-page-setup] section.main.admin section.primary h3 {
  font-size: 24px;
  margin: 2em auto 0;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-setup] section.main.admin section.primary h3 {
    font-size: 30px;
  }
}

.admin-panel
  [data-page-setup]
  section.main.admin
  section.primary
  div.submit
  button.btn {
  display: block;
  margin: 1em auto;
  min-width: 40%;
}

@media only screen and (max-width: 767px) {
  .admin-panel
    [data-page-setup]
    section.main.admin
    section.primary
    div.submit
    button.btn {
    width: 100%;
    padding: 0.5rem;
  }
}

.admin-panel [data-page-setup] div.form-wrap .captcha {
  margin-top: 24px;
  margin-bottom: -4px;
}

.admin-panel [data-page-setup] div.form-wrap .captcha .g-recaptcha div {
  margin: auto;
}

.admin-panel [data-page-setup] div.form-wrap .captcha .g-recaptcha + div {
  max-width: 304px;
  margin: auto;
}

.admin-panel [data-page-setup] div.form-wrap .tcs {
  text-align: center;
}

.admin-panel [data-page-setup] div.form-wrap .tcs span {
  font-size: 16px;
}

.admin-panel [data-page-setup] div.form-wrap > form > p {
  text-align: center;
  margin: 1em auto 2em;
  font-size: 1.2em;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-setup] div.form-wrap > form > p {
    margin: 2em auto;
  }
}

.admin-panel [data-page-setup] section.fields {
  background-color: #fff;
  border-left: 0;
  border-radius: 4px;
  border-top: 0;
  padding: 24px 16px;
  position: relative;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-setup] section.fields {
    padding: 32px 24px;
  }
}

.admin-panel
  [data-page-setup]
  section.fields
  div:first-child
  label.active-input {
  margin-top: 10px;
}

.admin-panel [data-page-setup] .why {
  text-align: center;
}

.admin-panel [data-page-setup] section.main.admin {
  position: static;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-setup] section.main.admin {
    height: 100vh;
  }
}

.admin-panel [data-page-setup] .body__inner {
  box-shadow: none;
  z-index: 1;
  position: relative;
}

.admin-panel [data-page-setup] div.main-wrap {
  margin-left: 0;
  margin-right: 0;
  overflow: visible;
  position: relative;
}

.admin-panel [data-page-setup] button[type='submit'].disabled {
  opacity: 0.5 !important;
  cursor: default;
}

.admin-panel [data-page-setup] .why {
  position: relative;
  margin: 1em 0 0;
  pointer-events: none;
}

.admin-panel [data-page-setup] .why .tip {
  transition: visibility 0s linear 0.2s,
    opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  opacity: 0;
  visibility: hidden;
}

.admin-panel [data-page-setup] .why:hover .tip {
  transition: visibility 0s linear,
    opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  opacity: 1;
  visibility: visible;
}

.admin-panel [data-page-setup] .why a {
  cursor: pointer;
  color: #a9abaf;
  pointer-events: all;
}

.admin-panel [data-page-setup] .why a:hover {
  text-decoration: none;
}

.admin-panel [data-page-setup] .why .tip {
  position: absolute;
  top: 30px;
}

.admin-panel [data-page-plan] section.main.admin {
  position: static;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-plan] section.main.admin {
    height: 100vh;
  }
}

.admin-panel [data-page-plan] section.main.admin section.primary {
  overflow: visible;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-plan] section.main.admin section.primary {
    max-width: 650px;
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel [data-page-plan] section.main.admin section.primary {
    max-width: 800px;
  }
}

.admin-panel [data-page-plan] section.main.admin section.primary h2 {
  margin: 2em auto 1.5em;
}

.admin-panel [data-page-plan] section.main.admin section.primary h3 {
  font-size: 24px;
  margin: 2em auto 0;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-plan] section.main.admin section.primary h3 {
    font-size: 30px;
  }
}

.admin-panel [data-page-plan] section.plans {
  margin-bottom: 2em;
}

.admin-panel [data-page-plan] section.plans .button input[type='radio'] {
  position: absolute;
  visibility: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.admin-panel [data-page-plan] .body__inner {
  box-shadow: none;
  z-index: 1;
  position: relative;
}

.admin-panel [data-page-plan] div.main-wrap {
  margin-left: 0;
  margin-right: 0;
  overflow: visible;
  position: relative;
}

.admin-panel [data-page-plan] div.plan-select {
  text-align: center;
  margin: 0;
}

.admin-panel [data-page-plan] div.plan-select > form > p {
  margin: 1em auto 2em;
  font-size: 1.2em;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-plan] div.plan-select > form > p {
    max-width: 90%;
    margin: 2em auto 4em;
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-plan] div.plan-select {
    width: 100%;
    left: 50%;
    margin: 10vh auto 0;
    padding-bottom: 100px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.admin-panel [data-page-plan] div.plan-select h4 {
  text-align: center;
  margin-bottom: 20px;
}

.admin-panel [data-page-plan] .why {
  position: relative;
  margin: 1em 0 0;
  pointer-events: none;
}

.admin-panel [data-page-plan] .why .tip {
  transition: visibility 0s linear 0.2s,
    opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  opacity: 0;
  visibility: hidden;
}

.admin-panel [data-page-plan] .why:hover .tip {
  transition: visibility 0s linear,
    opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  opacity: 1;
  visibility: visible;
}

.admin-panel [data-page-plan] .why a {
  cursor: pointer;
  color: #a9abaf;
  pointer-events: all;
}

.admin-panel [data-page-plan] .why a:hover {
  text-decoration: none;
}

.admin-panel [data-page-plan] .why .tip {
  position: absolute;
  top: 30px;
}

.admin-panel [data-page-pro] section.main.admin section.primary sup.pro {
  opacity: 0.7;
}

.admin-panel [data-page-pro] section.main.admin section.primary a.btn {
  font-size: 0.9em;
  padding: 10px 20px;
  width: 100%;
  max-width: 250px;
  margin: 0 !important;
}

@media only screen and (max-width: 767px) {
  .admin-panel [data-page-pro] section.main.admin section.primary a.btn {
    padding: 10px 20px;
  }
}

.admin-panel [data-page-pro] section.main.admin section.primary h3 {
  margin-top: 2em;
}

.admin-panel [data-page-pro] section.main.admin section.primary section.plans {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-pro] section.main.admin section.primary {
    padding-bottom: 160px;
  }
  .admin-panel [data-page-pro] section.main.admin section.primary h3 {
    margin-top: 3em;
    margin-bottom: 1.5em;
  }
  .admin-panel
    [data-page-pro]
    section.main.admin
    section.primary
    section.plans
    > section {
    padding: 0 40px 80px;
  }
  .admin-panel
    [data-page-pro]
    section.main.admin
    section.primary
    section.plans
    ul {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel [data-page-pro] section.main.admin section.primary {
    max-width: 760px;
  }
  .admin-panel
    [data-page-pro]
    section.main.admin
    section.primary
    section.plans
    > section {
    padding: 0 50px 80px;
  }
  .admin-panel
    [data-page-pro]
    section.main.admin
    section.primary
    section.plans
    ul {
    padding-left: 0;
  }
}

.admin-panel [data-page-account] section.main.admin .submit {
  margin-top: 24px;
}

.admin-panel [data-page-account] section.main.admin .trees .tree {
  background-color: #fff;
  border-left: 0;
  border-radius: 4px;
  border-top: 0;
  position: relative;
  margin-bottom: 6px;
  padding: 0 0 0 54px;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-account] section.main.admin .trees .tree {
    padding: 32px 24px;
    margin-bottom: 10px;
    padding: 0 0 0 64px;
  }
}

.admin-panel [data-page-account] section.main.admin .trees .tree:last-of-type {
  margin-bottom: 0;
}

.admin-panel [data-page-account] section.main.admin .trees .tree__sidebar {
  width: 54px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-right: 1px solid #f5f6f8;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-account] section.main.admin .trees .tree__sidebar {
    width: 64px;
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__sidebar
  .img-circle {
  top: 12px;
  left: 6px;
  position: relative;
  z-index: 100;
  width: 42px;
  height: 42px;
  transition: visibility 0s linear 0.15s,
    opacity 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
  border-radius: 50%;
  margin: 0;
  z-index: 0;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__sidebar
    .img-circle {
    top: 10px;
    left: 10px;
    width: 44px;
    height: 44px;
    opacity: 1;
    visibility: visible;
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__sidebar
  .img-circle
  img {
  position: absolute;
  width: 0;
  min-width: 42px;
  min-height: 42px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 768px) {
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__sidebar
    .img-circle
    img {
    min-width: 44px;
    min-height: 44px;
  }
}

.admin-panel [data-page-account] section.main.admin .trees .tree__section {
  position: relative;
  padding: 12px;
  border-bottom: 1px solid #f5f6f8;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section
  .btn-primary {
  height: 30px;
  line-height: 20px;
  width: 160px;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-account] section.main.admin .trees .tree__section {
    padding: 20px;
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section:nth-of-type(2) {
  border-top-right-radius: 3px;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section:last-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
}

.admin-panel [data-page-account] section.main.admin .trees .tree__section h5 {
  text-align: left;
  margin-top: 9px;
  margin-bottom: 3px;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-account] section.main.admin .trees .tree__section h5 {
    margin-top: 15px;
    margin-bottom: 5px;
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section
  h5:first-of-type {
  margin-top: 0;
}

.admin-panel [data-page-account] section.main.admin .trees .tree__section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section
  ul
  li {
  font-size: 13px;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--header {
  padding: 12px 60px 12px 12px;
  line-height: 34px;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--header {
    padding: 10px 60px 10px 20px;
    line-height: 38px;
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--footer {
    flex-direction: row;
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--footer
  .button {
  margin-bottom: 12px;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--footer
    .button {
    margin-bottom: 0;
  }
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--footer
    .button:not(:last-child) {
    margin-right: 12px;
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree
  .tree__sidebar
  svg {
  position: absolute;
  right: 10px;
  top: 40px;
  width: 10px;
  height: 10px;
  fill: #ffcb03;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li {
  cursor: default;
  position: relative;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li.user {
  padding: 5px 8px 8px;
  background-color: rgba(0, 0, 0, 0.02);
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 3px;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li.user
  .remove {
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 0;
  transform-origin: center;
  transition: transform 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    opacity 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li.user
  .remove:before {
  content: 'â€”';
  display: block;
  font-size: 16px;
  border-radius: 100px;
  width: 16px;
  height: 16px;
  text-align: center;
  background-color: #e04539;
  color: #fff;
  font-weight: 700;
  line-height: 16px;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--admins
    ul
    li.user
    .remove:before {
    width: 18px;
    height: 18px;
    line-height: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--admins
    ul
    li.user
    .remove {
    right: -9px;
    top: 48%;
    opacity: 0;
    transform: translateY(-50%) scale(0);
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--admins
    ul
    li.user {
    margin-right: 0;
  }
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--admins
    ul
    li.user:hover
    .remove {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--admins
    ul
    li.user:hover
    .remove:hover {
    transform: translateY(-50%) scale(1.15);
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li
  span.username {
  opacity: 0.8;
  max-width: 100%;
  overflow: hidden;
  display: block;
  vertical-align: middle;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--admins
    ul
    li
    span.username {
    max-width: 75%;
    display: inline-block;
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li
  span.tag {
  display: inline-block;
  margin-left: 2px;
  font-weight: 700;
  font-size: 11px;
  opacity: 0.15;
  color: #fff;
  background-color: #282f37;
  padding: 1px 4px;
  vertical-align: middle;
  border-radius: 3px;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li
  span.tag:nth-of-type(2) {
  margin-left: 0;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    [data-page-account]
    section.main.admin
    .trees
    .tree__section--admins
    ul
    li
    span.tag:nth-of-type(2) {
    margin-left: 15px;
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li:not(.user)
  a {
  border-bottom: 1px dotted #0a0b0d;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li:not(.user)
  a:active,
.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li:not(.user)
  a:focus,
.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li:not(.user)
  a:hover,
.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li:not(.user)
  a:visited {
  color: #0a0b0d;
  text-decoration: none;
  border-bottom: 1px solid #0a0b0d;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree__section--admins
  ul
  li:last-of-type {
  margin-top: 5px;
  padding-left: 8px;
}

.admin-panel [data-page-account] section.main.admin .trees .tree span.name {
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  opacity: 0.7;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree
  .dialog
  .button {
  margin-top: 20px;
  width: 100%;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree
  .dialog
  label.active-input {
  margin-top: 5px;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree
  .dialog
  .field {
  padding-left: 20px;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree
  .dialog
  .field
  + span
  + i {
  position: absolute;
  top: 4px;
  left: 3px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree
  .dialog
  .field.is-focused
  + span
  + i,
.admin-panel
  [data-page-account]
  section.main.admin
  .trees
  .tree
  .dialog
  .field:not(.is-empty)
  + span
  + i {
  opacity: 0.7;
}

.admin-panel [data-page-account] section.main.admin .actions .action,
.admin-panel [data-page-account] section.main.admin .delete .action {
  margin: 0 0 12px;
  display: block;
}

@media only screen and (min-width: 768px) {
  .admin-panel [data-page-account] section.main.admin .actions .action,
  .admin-panel [data-page-account] section.main.admin .delete .action {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 12px;
  }
}

.admin-panel
  [data-page-account]
  section.main.admin
  .actions
  .action:last-of-type,
.admin-panel
  [data-page-account]
  section.main.admin
  .delete
  .action:last-of-type {
  margin-right: 0;
  margin-bottom: 0;
}

.admin-panel section.main.admin .subscription {
  display: block;
  transform: translateY(-5px);
  line-height: 20px;
}

@media only screen and (min-width: 851px) {
  .admin-panel section.main.admin .subscription {
    height: auto;
    display: inline-block;
    transform: translateY(-1px);
    line-height: 1.5;
  }
}

.admin-panel section.main.admin .subscription a.downgrade {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
  opacity: 0.5;
}

.admin-panel section.main.admin .subscription a.button {
  display: inline-block;
  padding: 2px 8px;
  font-size: 12px;
  text-transform: uppercase;
}

.admin-panel section.main.admin .subscription__downgrade {
  display: inline-block;
  margin-left: 0;
  padding: 0;
}

@media only screen and (min-width: 851px) {
  .admin-panel section.main.admin .subscription__downgrade {
    padding: 0 0 0 10px;
  }
}

.admin-panel section.main.admin .subscription__downgrade a.button {
  margin-left: 0 !important;
}

.admin-panel section.main.admin .subscription__downgrade a.button:hover {
  color: #fff;
}

.admin-panel section.main.admin .subscription__downgrade-confirm {
  display: none;
  margin-left: 0;
  border-radius: 2px;
  padding: 0;
  font-size: 12px;
  width: 100%;
}

@media only screen and (min-width: 851px) {
  .admin-panel section.main.admin .subscription__downgrade-confirm {
    padding: 0 0 0 10px;
    width: auto;
  }
}

.admin-panel section.main.admin .subscription__downgrade-confirm span {
  opacity: 0.5;
}

.admin-panel section.main.admin .subscription__downgrade-confirm a.button {
  margin-top: 5px;
  margin-left: 0 !important;
  transform: translateY(-2px);
  display: block;
  width: 100%;
}

@media only screen and (min-width: 851px) {
  .admin-panel section.main.admin .subscription__downgrade-confirm a.button {
    display: inline-block;
    margin-top: 0;
    margin-left: 10px !important;
    width: auto;
  }
}

.admin-panel
  section.main.admin
  .subscription__downgrade-confirm
  a.button:hover {
  color: #fff;
}

.admin-panel section.main.admin .subscription__downgrade-confirm {
  margin-top: 3px;
}

@media only screen and (min-width: 851px) {
  .admin-panel section.main.admin .subscription__downgrade-confirm {
    margin-top: auto;
  }
}

.admin-panel section.main.admin .plan__label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
}

.admin-panel section.main.admin .plan__label:not(:last-of-type) {
  margin-bottom: 16px;
}

.admin-panel section.main.admin .plan__label-info {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.admin-panel section.main.admin .plan__label-title {
  display: block;
  font-weight: 700;
  margin-bottom: 4px;
}

.admin-panel section.main.admin .plan__label-title .strikethrough {
  text-decoration: line-through;
}

.admin-panel section.main.admin .plan__label-description {
  display: block;
  color: #7e8287;
}

.admin-panel section.main.admin .plan .tree {
  background-color: #fff;
  border-left: 0;
  border-radius: 4px;
  border-top: 0;
  position: relative;
  margin-bottom: 6px;
  padding: 0 0 0 54px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin .plan .tree {
    padding: 32px 24px;
    margin-bottom: 10px;
    padding: 0 0 0 64px;
  }
}

.admin-panel section.main.admin .plan .tree:last-of-type {
  margin-bottom: 0;
}

.admin-panel section.main.admin .plan .tree__sidebar {
  width: 54px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-right: 1px solid #f5f6f8;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin .plan .tree__sidebar {
    width: 64px;
  }
}

.admin-panel section.main.admin .plan .tree__sidebar .img-circle {
  top: 12px;
  left: 6px;
  position: relative;
  z-index: 100;
  width: 42px;
  height: 42px;
  transition: visibility 0s linear 0.15s,
    opacity 0.15s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  display: block;
  border-radius: 50%;
  margin: 0;
  z-index: 0;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin .plan .tree__sidebar .img-circle {
    top: 10px;
    left: 10px;
    width: 44px;
    height: 44px;
    opacity: 1;
    visibility: visible;
  }
}

.admin-panel section.main.admin .plan .tree__sidebar .img-circle img {
  position: absolute;
  width: 0;
  min-width: 42px;
  min-height: 42px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin .plan .tree__sidebar .img-circle img {
    min-width: 44px;
    min-height: 44px;
  }
}

.admin-panel section.main.admin .plan .tree__section {
  position: relative;
  padding: 12px;
  border-bottom: 1px solid #f5f6f8;
}

.admin-panel section.main.admin .plan .tree__section .btn-primary {
  height: 30px;
  line-height: 20px;
  width: 160px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin .plan .tree__section {
    padding: 20px;
  }
}

.admin-panel section.main.admin .plan .tree__section:nth-of-type(2) {
  border-top-right-radius: 3px;
}

.admin-panel section.main.admin .plan .tree__section:last-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
}

.admin-panel section.main.admin .plan .tree__section h5 {
  text-align: left;
  margin-top: 9px;
  margin-bottom: 3px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin .plan .tree__section h5 {
    margin-top: 15px;
    margin-bottom: 5px;
  }
}

.admin-panel section.main.admin .plan .tree__section h5:first-of-type {
  margin-top: 0;
}

.admin-panel section.main.admin .plan .tree__section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.admin-panel section.main.admin .plan .tree__section ul li {
  font-size: 13px;
}

.admin-panel section.main.admin .plan .tree__section--header {
  padding: 12px 60px 12px 12px;
  line-height: 34px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin .plan .tree__section--header {
    padding: 10px 60px 10px 20px;
    line-height: 38px;
  }
}

.admin-panel section.main.admin .plan .tree__section--footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin .plan .tree__section--footer {
    flex-direction: row;
  }
}

.admin-panel section.main.admin .plan .tree__section--footer .button {
  margin-bottom: 12px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin .plan .tree__section--footer .button {
    margin-bottom: 0;
  }
  .admin-panel
    section.main.admin
    .plan
    .tree__section--footer
    .button:not(:last-child) {
    margin-right: 12px;
  }
}

.admin-panel section.main.admin .plan .tree__section--plan {
  display: flex;
  flex-direction: column;
}

.admin-panel section.main.admin .plan .tree__section--details .name {
  text-transform: uppercase;
  opacity: 0.8;
}

.admin-panel section.main.admin .plan .tree__section--details .price {
  font-size: 24px;
  margin-top: 5px;
}

.admin-panel section.main.admin .plan .tree__section--details .frequency {
  font-size: 16px;
  margin-left: 5px;
  color: #7e8287;
}

.admin-panel section.main.admin .plan .tree__section--details .frequency sup {
  font-size: 80%;
  font-weight: 400;
  margin-left: 1px;
  top: 0;
}

.admin-panel
  section.main.admin
  .plan
  .tree__section--details
  .coupon-description {
  display: block;
  opacity: 0.5;
  font-size: 16px;
}

.admin-panel
  section.main.admin
  .plan
  .tree__section--details
  [data-coupon-price-container] {
  display: none;
}

.admin-panel
  section.main.admin
  .plan
  .tree__section--details.coupon-applied
  [data-plan-price] {
  text-decoration: line-through;
}

.admin-panel
  section.main.admin
  .plan
  .tree__section--details.coupon-applied
  [data-coupon-price-container] {
  display: unset;
}

.admin-panel section.main.admin .plan .tree__section--coupon .coupon h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.admin-panel
  section.main.admin
  .plan
  .tree__section--coupon
  .coupon
  .coupon-code__messaging {
  color: #3d444b;
  max-height: 0;
  overflow: hidden;
  display: block;
  padding: 10px;
  background-color: #c2c9d1;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.admin-panel
  section.main.admin
  .plan
  .tree__section--coupon
  .coupon
  .coupon-code__messaging:empty {
  padding-top: 0;
  padding-bottom: 0;
}

.admin-panel
  section.main.admin
  .plan
  .tree__section--coupon
  .coupon
  .coupon-code__messaging:not(:empty) {
  max-height: 200px;
  margin-top: 10px;
}

.admin-panel section.main.admin div.billing .section__inner {
  padding-bottom: 25px;
}

.admin-panel section.main.admin div.billing .section__inner > h5:first-of-type {
  margin-top: 0;
}

.admin-panel
  section.main.admin
  div.billing
  .section__inner
  h5
  + div
  label.active-input {
  margin-top: 25px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin div.billing .billing-lastrow {
    display: flex;
  }
  .admin-panel section.main.admin div.billing .billing-lastrow > div {
    flex: 1;
    margin-right: 20px;
  }
  .admin-panel
    section.main.admin
    div.billing
    .billing-lastrow
    > div:last-of-type {
    margin-right: 0;
  }
}

.admin-panel section.main.admin div.billing .billing-small {
  display: flex;
}

.admin-panel section.main.admin div.billing .billing-small > div {
  flex: 1;
  margin-right: 20px;
}

.admin-panel section.main.admin div.billing .billing-small > div:last-of-type {
  margin-right: 0;
}

.admin-panel section.main.admin div.payment .section__inner > h5:first-of-type {
  margin-top: 0;
}

.admin-panel section.main.admin div.payment h3 {
  margin-top: 45px;
}

.admin-panel section.main.admin div.payment .card-option {
  position: relative;
  display: block;
  margin-bottom: 0;
  cursor: pointer;
}

.admin-panel section.main.admin div.payment .card-option input {
  display: inline-block;
  vertical-align: top;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option:not(.new-card):first-of-type {
  padding-top: 10px;
}

.admin-panel section.main.admin div.payment .card-option .card__details {
  font-family: monospace;
  margin: -5px 0 5px 15px;
  padding: 50px 0 0 10px;
  display: inline-block;
  width: 200px;
  height: 110px;
  background-color: rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border: 0;
  position: relative;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 1px 1px 0 rgba(31, 45, 61, 0.2);
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin div.payment .card-option .card__details {
    margin: -5px 0 5px 20px;
  }
}

.admin-panel section.main.admin div.payment .card-option .card__details span {
  display: block;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.brand {
  font-size: 0;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.number {
  font-size: 16px;
  line-height: 15px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.name {
  text-transform: uppercase;
  font-size: 12px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option
  .card__details
  span.expiry {
  font-size: 12px;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option.expired
  .card__details {
  cursor: default;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option.expired
  .card__details:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: -1px;
  bottom: -1px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

.admin-panel
  section.main.admin
  div.payment
  .card-option.expired
  .card__details:after {
  content: 'EXPIRED';
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #ff1731;
  display: block;
  text-align: center;
  line-height: 100%;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
}

.admin-panel section.main.admin div.payment .new-card {
  margin-top: 5px;
  padding-right: 10px;
  position: relative;
}

.admin-panel section.main.admin div.payment .new-card:first-of-type {
  margin-top: 0;
}

.admin-panel
  section.main.admin
  div.payment
  .new-card
  div[data-active-input-error] {
  margin-bottom: 20px;
}

.admin-panel section.main.admin div.payment .new-card > label {
  position: relative;
  z-index: 10;
  margin-bottom: 0;
}

.admin-panel section.main.admin div.payment .new-card > label input {
  vertical-align: middle;
}

.admin-panel section.main.admin div.payment .new-card > label span {
  vertical-align: middle;
  margin-left: 10px;
  margin-top: 1px;
  padding-left: 5px;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin div.payment .new-card > label span {
    padding-left: 10px;
  }
}

.admin-panel section.main.admin div.payment .new-card .card__fields {
  margin-left: 28px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.main.admin div.payment .new-card .card__fields {
    margin-left: 34px;
  }
}

.admin-panel section.main.admin div.payment .new-card .card__fields button {
  float: left;
  display: block;
  background: #38e29b;
  color: #fff;
  border-radius: 2px;
  border: 0;
  margin-top: 20px;
  font-size: 19px;
  font-weight: 400;
  width: 100%;
  height: 47px;
  line-height: 45px;
  outline: none;
}

.admin-panel
  section.main.admin
  div.payment
  .new-card
  .card__fields
  button:focus {
  background: #24b47e;
}

.admin-panel
  section.main.admin
  div.payment
  .new-card
  .card__fields
  button:active {
  background: #159570;
}

.admin-panel section.main.admin div.payment .new-card .card__fields .outcome {
  float: left;
  width: 100%;
  padding-top: 8px;
  min-height: 20px;
  text-align: center;
}

.admin-panel section.main.admin div.payment .new-card .card__fields .success {
  display: none;
  font-size: 15px;
}

.admin-panel
  section.main.admin
  div.payment
  .new-card
  .card__fields
  .error.visible,
.admin-panel
  section.main.admin
  div.payment
  .new-card
  .card__fields
  .success.visible {
  display: inline;
}

.admin-panel section.main.admin div.payment .new-card .card__fields .success {
  color: #38e29b;
}

.admin-panel
  section.main.admin
  div.payment
  .new-card
  .card__fields
  .success
  .token {
  font-weight: 500;
  font-size: 15px;
}

.admin-panel
  section.main.admin
  div.payment
  .new-card
  .StripeElement--invalid
  + span {
  color: #ff1731;
  border-bottom-color: #ff1731;
}

.admin-panel section.main.admin div[data-process-errors] {
  display: none;
}

.admin-panel section.main.admin div[data-process-errors].active {
  display: block;
  background-color: #ff4c63;
  border: 0;
  border-radius: 2px;
  padding: 5px 10px;
  margin-top: 40px;
  color: #fff;
}

.admin-panel section.main.admin div.tcs {
  margin-top: 35px;
}

.admin-panel section.main.admin div.tcs label {
  width: 100%;
}

.admin-panel section.main.admin div.tcs label input {
  display: inline-block;
  vertical-align: middle;
}

.admin-panel section.main.admin div.tcs label span {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  max-width: 85%;
  margin-left: 10px;
  padding-top: 1px;
}

.admin-panel section.main.admin div.tcs label span a {
  color: rgba(10, 11, 13, 0.7);
  text-decoration: underline;
}

.admin-panel section.main.admin div.tcs label span a:hover {
  opacity: 0.7;
}

.admin-panel section.main.admin div.submit {
  margin-top: 50px;
  margin-bottom: 60px;
}

.admin-panel [data-page-instagram-flow-post-deprecation],
.admin-panel [data-page-instagram-flow-pre-deprecation] {
  background-color: #fff;
}

.admin-panel [data-page-instagram-flow-post-deprecation] div.main-wrap,
.admin-panel [data-page-instagram-flow-pre-deprecation] div.main-wrap {
  margin-left: 0;
  overflow: auto;
  max-height: none;
}

.admin-panel section.preview .message-block {
  display: flex;
  align-items: flex-start;
}

.admin-panel section.preview .message-block > div {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.admin-panel section.preview .forwarding-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.7);
  line-height: 1em;
  z-index: 999;
  display: flex;
}

.admin-panel section.preview .forwarding-message .forwarded-link {
  position: relative;
  flex-basis: 50%;
  overflow: hidden;
  padding: 20px 15px 24px;
}

.admin-panel section.preview .forwarding-message .line-seperator {
  height: 60px;
  width: 1px;
  background-color: #939393;
  display: flex;
  margin-top: 10px;
}

.admin-panel section.preview .forwarding-message p {
  margin: 0 0 2px;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}

.admin-panel section.preview .forwarding-message a,
.admin-panel section.preview .forwarding-message p {
  color: #fff;
}

.admin-panel section.preview .forwarding-message .url {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.admin-panel section.preview .forwarding-message .edit {
  display: flex;
  width: 60px;
  height: 70px;
  margin-top: 5px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  z-index: 3;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.admin-panel section.preview .forwarding-message .edit:hover {
  color: #fff;
  opacity: 1;
  text-decoration: none;
}

.admin-panel section.preview .forwarding-message .icon {
  margin: 0.2rem 0.5rem 0 0;
  background-color: #000;
  border-radius: 50%;
  padding: 7px;
  display: block;
  width: 38px;
  max-width: 38px;
  height: 38px;
}

.admin-panel section.preview .forwarding-message .icon svg {
  width: 100%;
  height: 100%;
}

.admin-panel section.preview .forwarding-message .icon svg circle,
.admin-panel section.preview .forwarding-message .icon svg path {
  stroke: #fff;
}

.admin-panel section.preview .forwarding-message .icon svg g {
  opacity: 1;
}

.admin-panel section.preview .forwarding-message .icon svg g path {
  fill: #fff;
}

.admin-panel .admin {
  padding-bottom: 0 !important;
}

.admin-panel .admin .addnew-area .btn-block,
.admin-panel .admin header .login .btn-primary {
  font-weight: 700;
  text-transform: none;
  font-size: 1rem;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
}

.admin-panel .admin .no-links p {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

@media only screen and (min-width: 768px) {
  .admin-panel .admin .no-links p {
    font-size: 20px;
  }
}

.admin-panel .admin .modal .modal-body > div:not(:last-of-type),
.admin-panel .admin .modal .modal-body input:not(:last-of-type) {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .admin-panel .admin #nav-admin {
    z-index: 1000;
    position: relative;
  }
}

.admin-panel .admin #email-modal .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.admin-panel .admin #email-modal .modal-footer {
  position: relative;
}

.admin-panel .admin #email-modal a.privacypolicy {
  text-decoration: underline;
  color: #22f;
}

.admin-panel .admin #email-modal a.cancel {
  font-size: 12px;
  color: #aaa;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.admin-panel .admin label.active-input a.info {
  position: absolute;
  right: 0;
  top: 45%;
  transform: translate(-50%, -50%);
}

.admin-panel a.info {
  margin-left: 8px;
  opacity: 0.8;
}

.admin-panel a.info:hover {
  opacity: 1;
}

.admin-panel a.info svg {
  height: 12px;
  width: 12px;
}

.admin-panel .fsp-picker {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.admin-panel .fsp-picker .fsp-modal {
  box-shadow: none;
}

.admin-panel .fsp-picker .fsp-picker__brand-container {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.admin-panel .theme.admin #fc_frame,
.admin-panel .theme.admin #launcher-frame,
.admin-panel .theme.admin #lightbox-frame {
  z-index: 1147483647 !important;
}

.admin-panel .theme.admin .btn {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.admin-panel .theme.admin .btn-primary {
  color: #fff;
  background-color: #38e29b;
  transition: background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  border-radius: 10px;
  border: 0;
}

.admin-panel .theme.admin .btn-primary:hover {
  opacity: 0.7;
  background-color: #38e29b;
}

.admin-panel .theme.admin .btn-secondary {
  background-color: #acb5bf;
  color: #1d2329;
  border: 0;
  border-radius: 10px;
}

.admin-panel .theme.admin .btn-secondary:hover {
  opacity: 0.7;
  background-color: #686d73;
}

.admin-panel .theme.admin .btn-swish {
  color: #38e29b;
  padding: 5px 10px;
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  border: 2px solid transparent;
  vertical-align: middle;
}

.admin-panel .theme.admin .btn-swish.btn {
  border: 2px solid #38e29b;
}

.admin-panel .theme.admin .btn-swish.btn:hover {
  color: #f4f4f4;
  text-decoration: none;
}

.admin-panel .theme.admin .btn-swish:after {
  background-color: #38e29b;
}

.admin-panel .sidebar-logo-wrap {
  top: 20px;
  display: block;
}

.admin-panel .secondary.admin.has-toolbar,
.admin-panel .sidebar-logo-wrap {
  transition: all 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .spinner {
  height: 24px;
  width: 24px;
  -webkit-animation: spin 0.4s linear infinite;
  animation: spin 0.4s linear infinite;
  background-image: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/linktree-loader-24x24.svg);
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  border: 0;
}

.admin-panel .spinner.small {
  height: 20px;
  width: 20px;
}

.admin-panel .spinner.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation: spin-positioned-xy 0.4s linear infinite;
  animation: spin-positioned-xy 0.4s linear infinite;
}

.admin-panel #fc_frame,
.admin-panel #launcher-frame,
.admin-panel #lightbox-frame {
  z-index: 1147483647 !important;
}

.admin-panel #modal__portal {
  z-index: 10002;
}
