@font-face {
  font-family: Montserrat;
  src: url('../font/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.login .global {
  position: absolute;
  text-align: center;
  height: auto;
  min-height: 100%;
  box-sizing: content-box !important;
}

.login .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.login .background-sign-in {
  width: 54%;
  height: auto;
  min-height: 100%;
  box-shadow: 0 2px 4px 0 #dcdcdc;
  background-color: #333;
  text-align: center;
}

.login .container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.login .align-self-center {
  align-self: center !important;
}

.login .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.login .col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.login .col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.login input {
  display: block;
  margin: 0 auto;
  width: 80%;
  border: 1px solid #d0d0d0;
  font-size: 14px;
  color: #929292;
  padding: 12px 10px;
  border-radius: 12px;
  background-color: #fffdfb;
  letter-spacing: normal;
  height: calc(2.75rem + 2px);
}

.login .btn {
  margin: 5% auto;
  width: 80%;
  height: 48px;
  border-radius: 12px;
  background-image: linear-gradient(to left, #ffcd33, #fff432);
  font-size: large;
}

.login button:not(:disabled),
.login [type='button']:not(:disabled),
.login [type='reset']:not(:disabled),
.login [type='submit']:not(:disabled) {
  cursor: pointer;
}

.login .get-started {
  width: 80%;
  margin: 0 auto;
  position: relative;
  height: 38px;
  border-radius: 23.5px;
  background-color: transparent;
  border-width: 0.06rem;
  font-size: 0.7vw;
  margin-bottom: 3rem;
  min-width: '135px';
}

.login .no-account {
  font-family: Montserrat, cursive;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #f5f5f5;
  font-size: 12px;
}

.login .no-account-login {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #f5f5f5;
}

.login h1,
.login h5,
.login span {
  font-family: Montserrat !important;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f5f5f5;
}

.login .mt-5,
.login .my-5 {
  margin-top: 3rem !important;
}

.login .Remember-me {
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  padding-top: 5%;
  margin-bottom: 3rem;
}

.login .BackToLoginDiv {
  width: 162px;
  height: 15px;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #f5f5f5;
  margin-top: 1.5rem;
}

.login .BackToLogin {
  width: 40%;
  height: 38px;
  border-radius: 23.5px;
  background-color: transparent;
  border-width: 0.06rem;
  font-size: large !important;
  margin-bottom: 3rem;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.login .justify-content-center {
  justify-content: center !important;
}

.login .Forgot-password {
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
}

.login .Background-Right {
  height: auto !important;
  min-height: 100% !important;
  background-image: url('../assests/images/loginBackground.jpg');
  position: absolute;
  right: 10px;
  width: 47%;
  background-size: contain;
  background-repeat: round;
}

.login h1,
.login h5,
.login span {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #f5f5f5;
}

.login h1,
.login .h1 {
  font-size: 2.5rem !important;
}

.login h5,
.login .h5 {
  font-size: 1.25rem;
}

.login .was-validated .form-control:invalid,
.login .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.login .validation-error {
  display: block;
  margin: 0 auto;
  color: white;
  font-size: 1rem;
  margin-top: 10px
}

.login .col-1,
.login .col-2,
.login .col-3,
.login .col-4,
.login .col-5,
.login .col-6,
.login .col-7,
.login .col-8,
.login .col-9,
.login .col-10,
.login .col-11,
.login .col-12,
.login .col,
.login .col-auto,
.login .col-sm-1,
.login .col-sm-2,
.login .col-sm-3,
.login .col-sm-4,
.login .col-sm-5,
.login .col-sm-6,
.login .col-sm-7,
.login .col-sm-8,
.login .col-sm-9,
.login .col-sm-10,
.login .col-sm-11,
.login .col-sm-12,
.login .col-sm,
.login .col-sm-auto,
.login .col-md-1,
.login .col-md-2,
.login .col-md-3,
.login .col-md-4,
.login .col-md-5,
.login .col-md-6,
.login .col-md-7,
.login .col-md-8,
.login .col-md-9,
.login .col-md-10,
.login .col-md-11,
.login .col-md-12,
.login .col-md,
.login .col-md-auto,
.login .col-lg-1,
.login .col-lg-2,
.login .col-lg-3,
.login .col-lg-4,
.login .col-lg-5,
.login .col-lg-6,
.login .col-lg-7,
.login .col-lg-8,
.login .col-lg-9,
.login .col-lg-10,
.login .col-lg-11,
.login .col-lg-12,
.login .col-lg,
.login .col-lg-auto,
.login .col-xl-1,
.login .col-xl-2,
.login .col-xl-3,
.login .col-xl-4,
.login .col-xl-5,
.login .col-xl-6,
.login .col-xl-7,
.login .col-xl-8,
.login .col-xl-9,
.login .col-xl-10,
.login .col-xl-11,
.login .col-xl-12,
.login .col-xl,
.login .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 992px) {
  .login .Background-Right {
    display: none;
  }
  .login .background-sign-in {
    width: 100%;
  }
  .login .col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .login .btn {
    margin: 15px auto 0 auto;
    width: 80%;
  }
}
