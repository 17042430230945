/* Settings component Theme thumbnails */

.admin-panel .userpreview {
  /* Theme 1 is applied by default */
  /* Theme 2 */
  /* Theme 3 */
  /* Theme 4 */
  /* Theme 5 */
  /* Theme 6 */
  /* Theme 7 */
  /* Theme 8 */
}

.admin-panel .userpreview .footer {
margin-top: 40px;
  bottom: 8px;
  left: 0;
  width: 100%;
  padding-bottom: 15px;
}

.admin-panel .userpreview .footer > ul > li {
  display: inline-block;
  margin-left: 5px;
}

.admin-panel .userpreview .pull-center {
  text-align: center;
}

.admin-panel  a > svg {
  width: 25px;
  height: 25px;
}
.social > ul > li {
  display: inline-block;
  margin-left: 5px;
}
.social{
  margin-top:20px;
}
 .disabled{
  fill:grey;
}

.admin-panel .userpreview #CustomAvatar,
.admin-panel .userpreview .CustomAvatar {
  position: relative;
  display: flex;
  flex-direction: row;
}

.admin-panel .userpreview .upload-modal-button {
  display: grid;
  grid-template-rows: 40px;
  grid-template-columns: repeat(2, 1fr);
  align-self: center;
  gap: 16px;
  margin: 25px 45px;
}

.admin-panel .userpreview .avatar {
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-color: #ebeef1;
  height: 96px;
  width: 96px;
  flex: 0 1 auto;
  border-radius: 100%;
  overflow: hidden;
  background-position: center center;
  padding: 0px;
  margin: 0px 16px 0px 0px;
}

.admin-panel .userpreview .setting-page-button {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr;
  flex: 1 1 0%;
  gap: 8px;
}

.admin-panel .userpreview .setting-page-button .button {
  width: 100%;
}

.admin-panel .userpreview .preview-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.admin-panel .userpreview .box {
  color: #fff;
  border-radius: 2px;
  height: 81px;
  width: 81px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.admin-panel .userpreview .dot {
  margin-left: 20px;
}

.admin-panel .userpreview .default-theme {
  padding: 5px;
  border: 3px solid #fff;
  border-radius: 10px;
}

.admin-panel .userpreview .themes-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 22px;
  justify-items: center;
}

@media only screen and (max-width: 470px) {
  .admin-panel .userpreview .themes-container {
    grid-template-columns: 1fr 1fr;
  }
}

.admin-panel .userpreview .avatarStyle {
  border-radius: 50%;
  width: 96px;
  height: 96px;
}

.admin-panel .userpreview .userContainer {
  text-align: center;
  font-size: 12px;
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.admin-panel .userpreview .linkContainer {
  border-style: solid;
  border-color: #1f1c1c;
  margin-bottom: 12px;
  padding: 17px;
  text-decoration: none;
  color: white;
  overflow: hidden;
}

.admin-panel .userpreview .link {
  background-color: white;
}

.admin-panel .userpreview .link:hover {
  background-color: white;
}

.admin-panel .userpreview .linkContainer {
  border-color: #1f1c1c;
  color: white;
}

.admin-panel .userpreview .linkContainer:hover {
  color: #1f1c1c;
}

.admin-panel .userpreview .linkContainer,
.admin-panel .userpreview .linksList {
  display: block;
  text-align: center;
}

.admin-panel .userpreview .linksList {
  color: #1f1c1c;
  max-width: 680px;
  position: center;
  margin: auto;
}

.admin-panel .userpreview .link {
  font-weight: 700;
  background-color: #1f1c1c;
}

.admin-panel .userpreview .theme-1-thumb {
  background-color: #ffffff;
  border: 2px solid #1f1c1c;
  flex-grow: 1;
  border-radius: 10px;
}

.admin-panel .userpreview .theme-1-thumb .dot {
  background-color: #1f1c1c;
}

.admin-panel .userpreview .theme-6-thumb {
  background-color: #1f1c1c;
  flex-grow: 1;
  border-radius: 10px;
}

.admin-panel .userpreview .theme-6-thumb .dot {
  background-color: rgb(255, 244, 50);
}

.admin-panel .userpreview .theme-7-thumb {
  background-color: #0095f6;
  flex-grow: 1;
  border-radius: 10px;
}

.admin-panel .userpreview .theme-7-thumb .dot {
  background-color: white;
}

.admin-panel .userpreview .theme-2-thumb {
  background: rgb(255, 244, 50);
  /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    rgb(255, 244, 50),
    rgb(255, 205, 51)
  );
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, rgb(255, 244, 50), rgb(255, 205, 51));
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  flex-grow: 1;
  border-radius: 10px;
}

.admin-panel .userpreview .theme-2-thumb .dot {
  background: #1f1c1c;
  border: 2px solid #1f1c1c;
  margin-top: 18.25px;
}

.admin-panel .userpreview .theme-5-thumb {
  background-color: #1f1c1c;
  flex-grow: 1;
  border-radius: 10px;
}

.admin-panel .userpreview .theme-5-thumb .dot {
  background-color: white;
}

.admin-panel .userpreview .theme-8-thumb {
  background: #e04184;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  flex-grow: 1;
  border-radius: 10px;
}

.admin-panel .userpreview .theme-8-thumb .dot {
  background: white;
  border: 2px solid white;
  margin-top: 18.25px;
}

.admin-panel .userpreview .theme-3-thumb {
  background: white;
  border: 2px solid #0095f6;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  flex-grow: 1;
  border-radius: 10px;
}

.admin-panel .userpreview .theme-3-thumb .dot {
  background: #0095f6;
  margin-top: 18.25px;
}

.admin-panel .userpreview .theme-4-thumb {
  background: white;
  border: 2px solid #e04184;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  flex-grow: 1;
  border-radius: 10px;
}

.admin-panel .userpreview .theme-4-thumb .dot {
  background: #e04184;
  margin-top: 18.25px;
}

.admin-panel .userpreview .dot {
  height: 50%;
  width: 50%;
  border-radius: 50%;
  display: inline-block;
  margin-top: 20.25px;
}

.admin-panel .userpreview .theme-6 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #1f1c1c;
  overflow: auto;
}

.admin-panel .userpreview .theme-6 .link {
  background-color: rgb(255, 244, 50);
}

.admin-panel .userpreview .theme-6 .link:hover {
  background-color: #1f1c1c;
}

.admin-panel .userpreview .theme-6 .linkContainer {
  border-color: rgb(255, 244, 50);
  color: #1f1c1c;
}

.admin-panel .userpreview .theme-6 .linkContainer:hover {
  color: rgb(255, 244, 50);
}

.admin-panel .userpreview .theme-6 h2 {
  color: rgb(255, 244, 50);
}
.admin-panel .userpreview .theme-6 svg{
  fill:white;
}
.admin-panel .userpreview .theme-7 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #0095f6;
  overflow: auto;
}

.admin-panel .userpreview .theme-7 .link {
  background-color: white;
}

.admin-panel .userpreview .theme-7 .link:hover {
  background-color: #0095f6;
}

.admin-panel .userpreview .theme-7 .linkContainer {
  border-color: white;
  color: #0095f6;
}

.admin-panel .userpreview .theme-7 .linkContainer:hover {
  color: white;
}

.admin-panel .userpreview .theme-7 h2 {
  color: white;
}

.admin-panel .userpreview .theme-2 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgb(255, 205, 51);
  background: -webkit-linear-gradient(
    to bottom,
    rgb(255, 244, 50),
    rgb(255, 205, 51)
  );
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, rgb(255, 244, 50), rgb(255, 205, 51));
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: auto;
}

.admin-panel .userpreview .theme-2 .link {
  background-color: transparent;
}

.admin-panel .userpreview .theme-2 .link:hover {
  background-color: #1f1c1c;
}

.admin-panel .userpreview .theme-2 .linkContainer {
  border-color: #1f1c1c;
  color: #1f1c1c;
}

.admin-panel .userpreview .theme-2 .linkContainer:hover {
  color: rgb(255, 205, 51);
}

.admin-panel .userpreview .theme-2 h2 {
  color: #1f1c1c;
}

.admin-panel .userpreview .theme-5 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #1f1c1c;
  overflow: auto;
}

.admin-panel .userpreview .theme-5 .link {
  background-color: white;
}

.admin-panel .userpreview .theme-5 .link:hover {
  background-color: #1f1c1c;
}

.admin-panel .userpreview .theme-5 .linkContainer {
  border-color: white;
  color: #1f1c1c;
}

.admin-panel .userpreview .theme-5 .linkContainer:hover {
  color: white;
}

.admin-panel .userpreview .theme-5 h2 {
  color: white;
}
.admin-panel .userpreview .theme-5 svg{
  fill:white;
}
.admin-panel .userpreview .theme-8 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #e04184;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: auto;
}

.admin-panel .userpreview .theme-8 .link {
  background-color: transparent;
}

.admin-panel .userpreview .theme-8 .link:hover {
  background-color: white;
}

.admin-panel .userpreview .theme-8 .linkContainer {
  border-color: white;
  color: white;
}

.admin-panel .userpreview .theme-8 .linkContainer:hover {
  color: #e04184;
}

.admin-panel .userpreview .theme-8 h2 {
  color: white;
}

.admin-panel .userpreview .theme-3 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: white;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: auto;
}

.admin-panel .userpreview .theme-3 .link {
  background-color: transparent;
}

.admin-panel .userpreview .theme-3 .link:hover {
  background-color: #0095f6;
}

.admin-panel .userpreview .theme-3 .linkContainer {
  border-color: #0095f6;
  color: #0095f6;
}

.admin-panel .userpreview .theme-3 .linkContainer:hover {
  color: white;
}

.admin-panel .userpreview .theme-3 h2 {
  color: #0095f6;
}

.admin-panel .userpreview .theme-4 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: white;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: auto;
}

.admin-panel .userpreview .theme-4 .link {
  background-color: transparent;
}

.admin-panel .userpreview .theme-4 .link:hover {
  background-color: #e04184;
}

.admin-panel .userpreview .theme-4 .linkContainer {
  border-color: #e04184;
  color: #e04184;
}

.admin-panel .userpreview .theme-4 .linkContainer:hover {
  color: white;
}

.admin-panel .userpreview .theme-4 h2 {
  color: #e04184;
}
