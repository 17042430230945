

.admin-panel *,
.admin-panel :after,
.admin-panel :before {
  box-sizing: border-box;
}

.admin-panel * {
  font-family: Karla, sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden !important;
}

@-ms-viewport {
  width: device-width;
}

.admin-panel article,
.admin-panel aside,
.admin-panel dialog,
.admin-panel figcaption,
.admin-panel figure,
.admin-panel footer,
.admin-panel header,
.admin-panel hgroup,
.admin-panel main,
.admin-panel nav,
.admin-panel section {
  display: block;
}

.admin-panel [tabindex='-1']:focus {
  outline: 0 !important;
}

.admin-panel hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.admin-panel abbr[data-original-title],
.admin-panel abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

.admin-panel address {
  font-style: normal;
  line-height: inherit;
}

.admin-panel address,
.admin-panel dl,
.admin-panel ol,
.admin-panel ul {
  margin-bottom: 1rem;
}

.admin-panel dl,
.admin-panel ol,
.admin-panel ul {
  margin-top: 0;
}

.admin-panel ol ol,
.admin-panel ol ul,
.admin-panel ul ol,
.admin-panel ul ul {
  margin-bottom: 0;
}

.admin-panel dt {
  font-weight: 700;
}

.admin-panel dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

.admin-panel dfn {
  font-style: italic;
}

.admin-panel b,
.admin-panel strong {
  font-weight: bolder;
}

.admin-panel small {
  font-size: 80%;
}

.admin-panel sub,
.admin-panel sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.admin-panel sub {
  bottom: -0.25em;
}

.admin-panel sup {
  top: -0.5em;
}

.admin-panel code,
.admin-panel kbd,
.admin-panel pre,
.admin-panel samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

.admin-panel pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

.admin-panel figure {
  margin: 0 0 1rem;
}

.admin-panel img {
  vertical-align: middle;
  border-style: none;
}

.admin-panel svg:not(:root) {
  overflow: hidden;
}

.admin-panel table {
  border-collapse: collapse;
}

.admin-panel th {
  text-align: inherit;
}

.admin-panel label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.admin-panel button {
  border-radius: 0;
}

.admin-panel button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.admin-panel button,
.admin-panel input,
.admin-panel optgroup,
.admin-panel select,
.admin-panel textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.admin-panel button,
.admin-panel input {
  overflow: visible;
}

.admin-panel button,
.admin-panel select {
  text-transform: none;
}

.admin-panel [type='reset'],
.admin-panel [type='submit'],
.admin-panel button,
.admin-panel html [type='button'] {
  -webkit-appearance: button;
}

.admin-panel [type='button']::-moz-focus-inner,
.admin-panel [type='reset']::-moz-focus-inner,
.admin-panel [type='submit']::-moz-focus-inner,
.admin-panel button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

.admin-panel input[type='checkbox'],
.admin-panel input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.admin-panel input[type='date'],
.admin-panel input[type='datetime-local'],
.admin-panel input[type='month'],
.admin-panel input[type='time'] {
  -webkit-appearance: listbox;
}

.admin-panel textarea {
  overflow: auto;
  resize: vertical;
}

.admin-panel fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.admin-panel legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.admin-panel progress {
  vertical-align: baseline;
}

.admin-panel [type='number']::-webkit-inner-spin-button,
.admin-panel [type='number']::-webkit-outer-spin-button {
  height: auto;
}

.admin-panel [type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

.admin-panel [type='search']::-webkit-search-cancel-button,
.admin-panel [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

.admin-panel ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.admin-panel output {
  display: inline-block;
}

.admin-panel summary {
  display: list-item;
  cursor: pointer;
}

.admin-panel template {
  display: none;
}

.admin-panel [hidden] {
  display: none !important;
}

.admin-panel hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.admin-panel .small,
.admin-panel small {
  font-size: 80%;
  font-weight: 400;
}

.admin-panel .mark,
.admin-panel mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.admin-panel .list-inline {
  padding-left: 0;
  list-style: none;
}

.admin-panel .list-inline-item {
  display: inline-block;
}

.admin-panel .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.admin-panel .figure {
  display: inline-block;
}

.admin-panel .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.admin-panel code,
.admin-panel kbd,
.admin-panel pre,
.admin-panel samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;
}

.admin-panel code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

.admin-panel a > code {
  color: inherit;
}

.admin-panel kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

.admin-panel kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

.admin-panel pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

.admin-panel pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.admin-panel .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .admin-panel .container {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  .admin-panel .container {
    max-width: 960px;
  }
}

.admin-panel .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.admin-panel .col-lg-7,
.admin-panel .col-lg-8 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .admin-panel .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .admin-panel .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

.admin-panel .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.admin-panel .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.admin-panel .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.admin-panel .form-control:-ms-input-placeholder,
.admin-panel .form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.admin-panel .form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.admin-panel .form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.admin-panel .form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.admin-panel .form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.admin-panel .form-control:disabled,
.admin-panel .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.admin-panel select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.admin-panel .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.admin-panel .form-row > .col,
.admin-panel .form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.admin-panel .btn {
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.admin-panel .btn:focus,
.admin-panel .btn:hover {
  text-decoration: none;
}

.admin-panel .btn.focus,
.admin-panel .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.admin-panel .btn.disabled,
.admin-panel .btn:disabled {
  opacity: 0.65;
}

.admin-panel .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.admin-panel .btn:not(:disabled):not(.disabled).active,
.admin-panel .btn:not(:disabled):not(.disabled):active {
  background-image: none;
}

.admin-panel a.btn.disabled,
.admin-panel fieldset:disabled a.btn {
  pointer-events: none;
}

.admin-panel .btn-link,
.admin-panel .btn-link:hover {
  background-color: transparent;
}

.admin-panel .btn-link.focus,
.admin-panel .btn-link:focus,
.admin-panel .btn-link:hover {
  text-decoration: underline;
  border-color: transparent;
}

.admin-panel .btn-link.focus,
.admin-panel .btn-link:focus {
  box-shadow: none;
}

.admin-panel .btn-link.disabled,
.admin-panel .btn-link:disabled {
  color: #6c757d;
}

.admin-panel .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.admin-panel .btn-block {
  display: block;
  width: 100%;
}

.admin-panel .btn-block + .btn-block {
  margin-top: 0.5rem;
}

.admin-panel input[type='button'].btn-block,
.admin-panel input[type='reset'].btn-block,
.admin-panel input[type='submit'].btn-block {
  width: 100%;
}

.admin-panel .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.admin-panel .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.admin-panel .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.admin-panel .alert-success hr {
  border-top-color: #b1dfbb;
}

.admin-panel .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.admin-panel .close:focus,
.admin-panel .close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.admin-panel .close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.admin-panel button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.admin-panel .visible {
  visibility: visible !important;
}

.admin-panel .invisible {
  visibility: hidden !important;
}

.admin-panel #FilestackModal {
  z-index: 2147483647 !important;
}

.admin-panel #FilestackModal,
.admin-panel .link__tab__nav--stats {
  position: relative;
}

@font-face {
  font-family: Inter;
  src: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.eot);
  src: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.eot#iefix)
      format('embedded-opentype'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.woff2)
      format('woff2'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.woff)
      format('woff'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.ttf)
      format('truetype'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/regular.svg#Inter-Regular)
      format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.eot);
  src: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.eot#iefix)
      format('embedded-opentype'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.woff2)
      format('woff2'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.woff)
      format('woff'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.ttf)
      format('truetype'),
    url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/fonts/inter/bold.svg#Inter-Bold)
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


.admin-panel .btn-link[class*='animate'] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes spin-positioned-x {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(359deg);
  }
}

@keyframes spin-positioned-x {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(359deg);
  }
}

@-webkit-keyframes spin-positioned-xy {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@keyframes spin-positioned-xy {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@-webkit-keyframes breathing {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@-webkit-keyframes animate-buzz {
  0% {
    transform: translateX(3px) rotate(1deg);
  }
  2.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  5% {
    transform: translateX(3px) rotate(1deg);
  }
  7.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  10% {
    transform: translateX(2px) rotate(1deg);
  }
  12.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  15% {
    transform: translateX(2px) rotate(1deg);
  }
  17.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  20% {
    transform: translateX(1px) rotate(1deg);
  }
  22.5% {
    transform: translateX(-1px) rotate(-1deg);
  }
  25% {
    transform: translateX(0) rotate(0);
  }
}

@keyframes animate-buzz {
  0% {
    transform: translateX(3px) rotate(1deg);
  }
  2.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  5% {
    transform: translateX(3px) rotate(1deg);
  }
  7.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  10% {
    transform: translateX(2px) rotate(1deg);
  }
  12.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  15% {
    transform: translateX(2px) rotate(1deg);
  }
  17.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  20% {
    transform: translateX(1px) rotate(1deg);
  }
  22.5% {
    transform: translateX(-1px) rotate(-1deg);
  }
  25% {
    transform: translateX(0) rotate(0);
  }
}

.admin-panel .admin .link__highlight .animate-buzz.active,
.admin-panel .admin .link__highlight .animate-buzz:hover,
.admin-panel .service .animate-buzz {
  -webkit-animation-name: animate-buzz;
  animation-name: animate-buzz;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.admin-panel .service .animate-buzz:active,
.admin-panel .service .animate-buzz:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
}

@-webkit-keyframes animate-wobble {
  4.16% {
    transform: translateY(8px);
  }
  8.33% {
    transform: translateY(-6px);
  }
  12.48% {
    transform: translateY(4px);
  }
  16.66% {
    transform: translateY(-2px);
  }
  20.81% {
    transform: translateY(1px);
  }
  25% {
    transform: translateY(0);
  }
}

@keyframes animate-wobble {
  4.16% {
    transform: translateY(8px);
  }
  8.33% {
    transform: translateY(-6px);
  }
  12.48% {
    transform: translateY(4px);
  }
  16.66% {
    transform: translateY(-2px);
  }
  20.81% {
    transform: translateY(1px);
  }
  25% {
    transform: translateY(0);
  }
}

.admin-panel .admin .link__highlight .animate-wobble.active,
.admin-panel .admin .link__highlight .animate-wobble:hover,
.admin-panel .service .animate-wobble {
  -webkit-animation-name: animate-wobble;
  animation-name: animate-wobble;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.admin-panel .service .animate-wobble:active,
.admin-panel .service .animate-wobble:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
}

@-webkit-keyframes animate-pop {
  0% {
    transform: scale(1);
  }
  12.5% {
    transform: scale(1.05);
  }
  25% {
    transform: scale(1);
  }
}

@keyframes animate-pop {
  0% {
    transform: scale(1);
  }
  12.5% {
    transform: scale(1.05);
  }
  25% {
    transform: scale(1);
  }
}

.admin-panel .admin .link__highlight .animate-pop.active,
.admin-panel .admin .link__highlight .animate-pop:hover,
.admin-panel .service .animate-pop {
  -webkit-animation-name: animate-pop;
  animation-name: animate-pop;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.admin-panel .service .animate-pop:active,
.admin-panel .service .animate-pop:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
}

@-webkit-keyframes animate-swipe-admin {
  4.16%,
  8.33% {
    background-position: 0 0;
    color: #fff;
  }
  12.49%,
  20.83% {
    background-position: -100% 0;
    color: #777;
  }
  24.99%,
  29.16% {
    background-position: 0 0;
    color: #fff;
  }
  33.33% {
    background-position: 100% 0;
    color: #777;
  }
}

@keyframes animate-swipe-admin {
  4.16%,
  8.33% {
    background-position: 0 0;
    color: #fff;
  }
  12.49%,
  20.83% {
    background-position: -100% 0;
    color: #777;
  }
  24.99%,
  29.16% {
    background-position: 0 0;
    color: #fff;
  }
  33.33% {
    background-position: 100% 0;
    color: #777;
  }
}

.admin-panel .admin .link__highlight .animate-swipe {
  background-size: 200% auto;
  background-position: 100% 0;
  background-color: transparent !important;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  transition-timing-function: ease-in-out;
  background-image: linear-gradient(90deg, #777 50%, transparent 0);
  color: #777;
}

.admin-panel .admin .link__highlight .animate-swipe.active,
.admin-panel .admin .link__highlight .animate-swipe:hover {
  -webkit-animation-name: animate-swipe-admin;
  animation-name: animate-swipe-admin;
}

.admin-panel body:after {
  content: 'small';
  display: none;
}

@media only screen and (min-width: 768px) {
  .admin-panel body:after {
    content: 'medium';
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel body:after {
    content: 'large';
  }
}

@media only screen and (min-width: 1260px) {
  .admin-panel body:after {
    content: 'extra-large';
  }
}

.admin-panel .ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.admin-panel .ps__rail-x {
  height: 15px;
  bottom: 0;
}

.admin-panel .ps__rail-x,
.admin-panel .ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  position: absolute;
}

.admin-panel .ps__rail-y {
  width: 15px;
  right: 0;
}

.admin-panel .ps--active-x > .ps__rail-x,
.admin-panel .ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.admin-panel .ps--focus > .ps__rail-x,
.admin-panel .ps--focus > .ps__rail-y,
.admin-panel .ps--scrolling-x > .ps__rail-x,
.admin-panel .ps--scrolling-y > .ps__rail-y,
.admin-panel .ps:hover > .ps__rail-x,
.admin-panel .ps:hover > .ps__rail-y {
  opacity: 0.6;
}

.admin-panel .ps__rail-x:focus,
.admin-panel .ps__rail-x:hover,
.admin-panel .ps__rail-y:focus,
.admin-panel .ps__rail-y:hover {
  background-color: #eee;
  opacity: 0.9;
}

.admin-panel .ps__thumb-x {
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  bottom: 2px;
}

.admin-panel .ps__thumb-x,
.admin-panel .ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  position: absolute;
}

.admin-panel .ps__thumb-y {
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  right: 2px;
}

.admin-panel .ps__rail-x:focus > .ps__thumb-x,
.admin-panel .ps__rail-x:hover > .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.admin-panel .ps__rail-y:focus > .ps__thumb-y,
.admin-panel .ps__rail-y:hover > .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

@supports (-ms-overflow-style: none) {
  .admin-panel .ps {
    overflow: auto !important;
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .admin-panel .ps {
    overflow: auto !important;
  }
}

.admin-panel .ps-container {
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

@supports (-ms-overflow-style: none) {
  .admin-panel .ps-container {
    overflow: auto !important;
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .admin-panel .ps-container {
    overflow: auto !important;
  }
}

.admin-panel .ps-container.ps-active-x > .ps-scrollbar-x-rail,
.admin-panel .ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}

.admin-panel .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}

.admin-panel
  .ps-container.ps-in-scrolling.ps-x
  > .ps-scrollbar-x-rail
  > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}

.admin-panel .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}

.admin-panel
  .ps-container.ps-in-scrolling.ps-y
  > .ps-scrollbar-y-rail
  > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}

.admin-panel .ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  bottom: 0;
  height: 15px;
}

.admin-panel .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  bottom: 2px;
  height: 6px;
}

.admin-panel .ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x,
.admin-panel .ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  height: 11px;
}

.admin-panel .ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  right: 0;
  width: 15px;
}

.admin-panel .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  right: 2px;
  width: 6px;
}

.admin-panel .ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y,
.admin-panel .ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  width: 11px;
}

.admin-panel .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}

.admin-panel
  .ps-container:hover.ps-in-scrolling.ps-x
  > .ps-scrollbar-x-rail
  > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}

.admin-panel .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}

.admin-panel
  .ps-container:hover.ps-in-scrolling.ps-y
  > .ps-scrollbar-y-rail
  > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}

.admin-panel .ps-container:hover > .ps-scrollbar-x-rail,
.admin-panel .ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.admin-panel .ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}

.admin-panel
  .ps-container:hover
  > .ps-scrollbar-x-rail:hover
  > .ps-scrollbar-x {
  background-color: #999;
}

.admin-panel .ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}

.admin-panel
  .ps-container:hover
  > .ps-scrollbar-y-rail:hover
  > .ps-scrollbar-y {
  background-color: #999;
}

.admin-panel .Scp {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
}

.admin-panel .Scp-saturation {
  position: relative;
  height: 100%;
  margin-right: 8px;
  border-radius: 2px;
}

.admin-panel .Scp-brightness {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: linear-gradient(rgba(255, 255, 255, 0), #000);
}

.admin-panel .Scp-sbSelector {
  border: 2px solid #fff;
  position: absolute;
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 10px;
  top: -7px;
  left: -7px;
  box-sizing: border-box;
  z-index: 10;
}

.admin-panel .Scp-hue {
  position: relative;
  border-radius: 2px;
  width: 24px;
  height: 100%;
  background: linear-gradient(
    red,
    #f0f 17%,
    #00f 34%,
    #0ff 50%,
    #0f0 67%,
    #ff0 84%,
    red
  );
}

.admin-panel .Scp-hSelector {
  position: absolute;
  right: -10%;
  height: 12px;
  width: 120%;
  transform: translateY(-50%);
  border-radius: 2px;
  border: 1px solid #d7dce1;
  background-color: #fff;
}

.admin-panel body {
  width: 100%;
  -moz-osx-font-smoothing: grayscale;
}

.admin-panel .lte8 body {
  min-width: 1200px;
}

@media only screen and (max-width: 767px) {
  .admin-panel #__bs_notify__ {
    padding: 5px !important;
    font-size: 10px;
    border-radius: 0 !important;
  }
}

.admin-panel .is-Flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .admin-panel .hide-mobile {
    display: none !important;
  }
}

.admin-panel html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-height: 100%;
}

@media only screen and (max-width: 767px) {
  .admin-panel html {
    height: auto;
  }
}

.admin-panel *,
.admin-panel :after,
.admin-panel :before {
  box-sizing: inherit;
}

.admin-panel body {
  position: relative;
  padding: 0 0 60px;
  margin: 0;
  max-width: 100%;
  background-color: #fff;
  min-height: 100vh;
}

@media only screen and (max-width: 767px) {
  .admin-panel body {
    padding: 0 0 80px;
  }
}

.admin-panel .content-wrapper {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (min-width: 768px) {
  .admin-panel .content-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel .content-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.admin-panel .content-wrapper article {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 768px) {
  .admin-panel .content-wrapper article {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel .content-wrapper article {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.admin-panel .content-wrapper article.post-type-page {
  margin-top: 20px;
}

.admin-panel .left-gutter {
  margin-left: 5px;
}

.admin-panel .right-gutter {
  margin-right: 5px;
}

.admin-panel .full-height {
  height: 100%;
}

.admin-panel body {
  -webkit-font-smoothing: antialiased;
}

.admin-panel body,
.admin-panel h1,
.admin-panel h2,
.admin-panel h3,
.admin-panel h4,
.admin-panel h5,
.admin-panel h6 {
  font-family: 'Montserrat', sans-serif;
  color: #0a0b0d;
}

.admin-panel h1,
.admin-panel h2,
.admin-panel h3,
.admin-panel h4,
.admin-panel h5,
.admin-panel h6 {
  font-weight: 700;
  letter-spacing: normal;
  margin-bottom: 0;
  margin-top: 0;
}

.admin-panel a,
.admin-panel label,
.admin-panel p {
  letter-spacing: 0.02em;
}

.admin-panel h1 {
  font-size: 64px;
}

@media only screen and (min-width: 768px) {
  .admin-panel h1 {
    font-size: 70px;
  }
}

.admin-panel h2 {
  font-size: 24px;
}

.admin-panel h3 {
  font-size: 20px;
}

.admin-panel h4 {
  font-size: 30px;
}

.admin-panel h5 {
  font-size: 14px;
}

.admin-panel h6,
.admin-panel p {
  font-size: 16px;
}

.admin-panel p {
  line-height: 1.5;
}

.admin-panel b,
.admin-panel strong {
  font-weight: 700;
}

.admin-panel a {
  color: #282f37;
  text-decoration: none;
}

.admin-panel a:hover {
  color: currentColor;
}

.admin-panel .main-wrap header.site-header {
  position: relative;
  height: 50px;
  width: 100%;
  background-color: #f5f6f8;
  color: #fff;
}

.admin-panel .main-wrap header.site-header .socials {
  display: none;
}

@media only screen and (min-width: 992px) {
  .admin-panel .main-wrap header.site-header .socials {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    margin-left: 5px;
    justify-content: center;
    align-items: center;
  }
  .admin-panel .main-wrap header.site-header .socials a {
    margin-right: 10px;
  }
  .admin-panel .main-wrap header.site-header .socials a svg {
    display: block;
  }
}

.admin-panel .main-wrap header.site-header .site-logo-wrap {
  position: relative;
  text-align: center;
  height: 50px;
}

@media only screen and (min-width: 768px) {
  .admin-panel .main-wrap header.site-header .site-logo-wrap {
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel .main-wrap header.site-header .site-logo-wrap {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.admin-panel .main-wrap header.site-header h1 {
  position: relative;
  z-index: 100;
  font-size: 0;
  text-align: left;
  height: 50px;
}

.admin-panel .main-wrap header.site-header h1 .logo {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/logo.svg);
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 120px;
  height: 50px;
}

@media only screen and (min-width: 768px) {
  .admin-panel .main-wrap header.site-header h1 .logo {
    position: relative;
    width: 135px;
    height: 20px;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.admin-panel .main-wrap header.site-header .login {
  z-index: 1039;
}

@media only screen and (max-width: 767px) {
  .admin-panel .main-wrap header.site-header .login {
    margin-right: 0;
    background-color: #ddd;
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel .main-wrap header.site-header .login {
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
  }
}

.admin-panel .main-wrap header.site-header .login .inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
}

.admin-panel .main-wrap header.site-header .login .inner img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.admin-panel .main-wrap header.site-header .login .inner .btn-secondary {
  display: inline-block;
  padding-left: 0.8rem;
  margin-right: 10px;
}

@media only screen and (min-width: 768px) {
  .admin-panel
    .main-wrap
    header.site-header
    .login
    .inner
    .btn-secondary.only-mobile {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .admin-panel
    .main-wrap
    header.site-header
    .login
    .inner
    .btn-secondary.only-desktop {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .admin-panel .main-wrap header.site-header .login .inner .btn-secondary {
    font-size: 15px;
    margin-right: 0;
    width: 25%;
  }
}

.admin-panel .main-wrap header.site-header .login .inner .btn-secondary.user {
  padding-top: 5px;
}

.admin-panel .main-wrap header.site-header .login .inner .btn-secondary span {
  margin-right: 5px;
  margin-top: -2px;
  width: 10.8px;
  height: 11px;
  vertical-align: middle;
  display: inline-block;
  line-height: 11px;
}

.admin-panel
  .main-wrap
  header.site-header
  .login
  .inner
  .btn-secondary
  span
  svg {
  width: 10.8px;
  height: 11px;
  fill: transparent;
  stroke: currentColor;
}

@media only screen and (max-width: 767px) {
  .admin-panel .main-wrap header.site-header .login .inner .btn-primary {
    display: none;
  }
}

.admin-panel .main-wrap header.site-header img.user-img {
  width: 115px;
}

.admin-panel .main-wrap header.site-header a.user-name {
  font-size: 15px;
}

@media only screen and (max-width: 767px) {
  .admin-panel .main-wrap header.site-header {
    height: 90px;
    padding-bottom: 10px;
  }
  .admin-panel .main-wrap header.site-header .login {
    height: 40px;
  }
  .admin-panel .main-wrap header.site-header .logo-wrap {
    height: 50px;
  }
}

.admin-panel .main-wrap .logo-wrap {
  height: 50px;
  width: 150px;
  margin-left: 10px;
}

@media only screen and (min-width: 768px) {
  .admin-panel .main-wrap .logo-wrap {
    width: 199px;
    margin-left: 40px;
  }
}

.admin-panel .main-wrap .logo-wrap a {
  display: block;
  height: 100%;
}

.admin-panel .main-wrap .logo-wrap h1 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.admin-panel .service header {
  position: absolute;
  top: 0;
  right: 0;
}

.admin-panel .service header .login {
  height: 90px;
  z-index: 1039;
}

.admin-panel .service header .login .inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.admin-panel .service header .login .inner img {
  width: 32px;
  height: 32px;
  margin-right: 15px;
}

.admin-panel .service header .logo-wrap {
  height: 90px;
}

.admin-panel .service header a.btn.btn-primary {
  border-width: 0 !important;
  padding: 7px;
}

.admin-panel .service header a.btn.btn-primary span {
  margin: 0;
}

.admin-panel footer {
  background: #fff;
  font-size: 14px;
  width: 100%;
  height: 80px;
}

@media only screen and (min-width: 768px) {
  .admin-panel footer {
    position: absolute;
    bottom: 0;
  }
}

.admin-panel footer a {
  left: 15px;
  position: absolute;
}

.admin-panel footer .copyright {
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  font-size: 11px;
  position: relative;
  display: block;
  text-align: center;
  margin-top: 10px;
  right: 0;
}

.admin-panel footer > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.admin-panel footer a {
  position: relative;
  display: block;
  text-align: center;
  left: 0;
}

.admin-panel footer .blstr-logo {
  width: 100px;
  margin-bottom: 20px;
}

.admin-panel footer .socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.admin-panel footer .socials a {
  padding: 0 2px;
}

.admin-panel footer .socials a svg {
  display: block;
}

.admin-panel footer .footer-links {
  text-align: center;
  margin-right: 0;
  margin-bottom: 10px;
}

.admin-panel footer .footer-links a {
  display: inline-block;
}

.admin-panel footer .footer-links a:not(:last-of-type):after {
  content: '';
  margin: 0 10px;
  display: inline-block;
}

.admin-panel .service section.service-footer {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  padding-bottom: 15px;
}

.admin-panel .service section.service-footer h1 {
  position: relative;
  z-index: 100;
  font-size: 0;
}

.admin-panel .service section.service-footer h1 .logo {
  background-image: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/logo.svg);
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 150px;
  height: 20px;
}

.admin-panel .service section.service-footer a {
  display: inline-block;
  vertical-align: middle;
}

.admin-panel .service section.service-footer .blstr {
  margin-top: 8px;
  margin-left: 10px;
  width: 83px;
  height: 16px;
  background-image: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/by_bolster.svg);
  display: inline-block;
  opacity: 0.3;
  position: relative;
  z-index: 100;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
}

.admin-panel .service section.service-footer .service-logo-wrap,
.admin-panel .service section.service-footer .site-logo-wrap {
  margin-left: -10px;
  display: block;
  text-align: center;
}

.admin-panel .service section.service-footer .copyright {
  bottom: 0;
  font-size: 11px;
  position: relative;
  display: block;
  text-align: center;
  margin-top: 10px;
  right: 0;
}

.admin-panel .service.luminance-dark .service-footer .logo {
  background-image: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/logo_white_trees_white.svg);
}

.admin-panel .mobile-menu__toggle {
  text-indent: 150%;
  white-space: nowrap;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: #282f37;
  border-radius: 0;
  outline: none;
  overflow: visible;
  position: absolute;
  z-index: 1;
  width: 24px;
  height: 100%;
  right: 0;
  padding: 0 20px;
  width: 64px;
}

.admin-panel .mobile-menu__toggle,
.admin-panel .mobile-menu__toggle i {
  top: 50%;
  transform: translateY(-50%);
}

.admin-panel .mobile-menu__toggle i,
.admin-panel .mobile-menu__toggle i:after,
.admin-panel .mobile-menu__toggle i:before {
  position: absolute;
  display: block;
  width: 24px;
  height: 2px;
  border-radius: 2px;
  background: #fff;
  transition-property: transform, background;
  transition-duration: 0.1;
  transition-timing-function: ease-out;
}

.admin-panel .mobile-menu__toggle i:after,
.admin-panel .mobile-menu__toggle i:before {
  content: ' ';
  top: 0;
  left: 0;
}

.admin-panel .mobile-menu__toggle i:before {
  transform: translateY(-8px);
}

.admin-panel .mobile-menu__toggle i:after {
  transform: translateY(8px);
}

.admin-panel .is-menu-open .mobile-menu__toggle i {
  background: rgba(255, 255, 255, 0);
}

.admin-panel .is-menu-open .mobile-menu__toggle i:before {
  transform: rotate(45deg);
}

.admin-panel .is-menu-open .mobile-menu__toggle i:after {
  transform: rotate(-45deg);
}

.admin-panel .is-menu-open .mobile-menu__toggle i,
.admin-panel .is-menu-open .mobile-menu__toggle i:after,
.admin-panel .is-menu-open .mobile-menu__toggle i:before {
  transition-duration: 0.1;
  transition-timing-function: ease-in;
}

@media only screen and (min-width: 768px) {
  .admin-panel .mobile-menu__toggle {
    display: none;
  }
}

.admin-panel .button {
  position: relative;
  display: block;
  text-align: center;
  outline: 0 !important;
  background-color: #3d444b;
  color: #fff;
  padding: 9px 14px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
}

.admin-panel .button:hover {
  background-color: #53585f;
  color: #fff;
}

.admin-panel .button[disabled] {
  pointer-events: none;
  color: #686d73 !important;
  background-color: #ebeef1 !important;
}

@media only screen and (max-width: 767px) {
  .admin-panel .button {
    width: 100%;
  }
}

.admin-panel .button.fill {
  display: block;
  width: 100%;
}

.admin-panel .button.inline {
  display: inline-block;
}

.admin-panel .button.large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px !important;
  border-radius: 12px;
}

.admin-panel .button.green {
  background-color: #38e29b;
}

.admin-panel .button.green.loading,
.admin-panel .button.green:hover {
  background-color: #7aebbc;
}

.admin-panel .button.grey {
  background-color: #ebeef1;
  color: #686d73;
}

.admin-panel .button.red {
  background-color: #ff1731;
}

.admin-panel .button.red.loading,
.admin-panel .button.red:hover {
  background-color: #ff4c63;
}

.admin-panel .button.yellow {
  height: 3em;
  border-radius: 12px;
  background-color: #ffcd33;
  color: #81681b;
}

.admin-panel .button.yellow.loading,
.admin-panel .button.yellow:hover {
  background-color: #ffd740;
  color: #fff;
}

.admin-panel .button.black {
  background-color: #0a0b0d;
  color: #fff;
}

.admin-panel .button.black.loading,
.admin-panel .button.black:hover {
  background-color: #1d2329;
  color: #fff;
}

.admin-panel .button.outline {
  background-color: transparent;
  border: 2px solid #3d444b;
  color: #3d444b;
  padding: 7px 12px;
}

.admin-panel .button.outline:hover {
  color: #fff;
  border-color: #3d444b;
  background-color: #3d444b;
}

.admin-panel .button.swish {
  background-color: transparent;
  border: 2px solid #3d444b;
  color: #3d444b;
  padding: 7px 12px;
  z-index: 2;
  overflow: hidden;
}

.admin-panel .button.swish:after {
  content: '';
  position: absolute;
  background-color: #3d444b;
  width: 0;
  height: 120%;
  left: -10%;
  bottom: 0;
  transition: all 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  transform: skewX(15deg);
  z-index: -1;
}

.admin-panel .button.swish:hover {
  cursor: pointer;
  opacity: 1 !important;
  color: #fff;
}

.admin-panel .button.swish:hover:after {
  left: -10%;
  width: 120%;
}

.admin-panel .button.clear {
  background-color: transparent;
  color: #3d444b;
}

.admin-panel .button.clear:hover {
  background-color: #e9eaeb;
}

.admin-panel .btn {
  position: relative;
  outline: 0 !important;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  border-radius: 0;
  text-decoration: none;
  display: inline-block;
  line-height: 1.5;
}

.admin-panel .btn,
.admin-panel .btn:focus,
.admin-panel .btn:hover {
  color: #282f37;
}

.admin-panel .btn[disabled] {
  pointer-events: none;
}

.admin-panel .btn-swish {
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 2;
  background: transparent !important;
}

.admin-panel .btn-swish,
.admin-panel .btn-swish:after {
  transition: all 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .btn-swish:after {
  position: absolute;
  content: '';
  width: 0;
  left: 50%;
  bottom: 0;
  height: 3px;
  height: 120%;
  left: -10%;
  transform: skewX(15deg);
  z-index: -1;
}

.admin-panel .btn-swish:hover {
  cursor: pointer;
  opacity: 1 !important;
}

.admin-panel .btn-swish:hover:after {
  left: -10%;
  width: 120%;
}

.admin-panel .btn-swish.btn-inverted {
  background-color: transparent !important;
}

.admin-panel .btn-swish.btn-inverted:after {
  left: -10%;
  width: 120%;
}

.admin-panel .btn-swish.btn-inverted:hover:after {
  left: 110%;
  width: 0;
}

.admin-panel .btn-primary {
  background-color: transparent;
}

.admin-panel .btn-primary:hover {
  color: #fff;
}

.admin-panel .btn-primary.active,
.admin-panel .btn-primary.active.focus,
.admin-panel .btn-primary.active:focus,
.admin-panel .btn-primary.active:hover,
.admin-panel .btn-primary.focus,
.admin-panel .btn-primary:active,
.admin-panel .btn-primary:active.focus,
.admin-panel .btn-primary:active:focus,
.admin-panel .btn-primary:active:hover,
.admin-panel .btn-primary:focus {
  box-shadow: none;
  color: #fff;
}

.admin-panel .btn-primary:disabled,
.admin-panel .btn-primary:disabled:hover {
  color: #fff;
}

.admin-panel .btn-default {
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background-color: #ebeef1;
  border: 2px solid #ebeef1;
}

.admin-panel .btn-default:hover {
  opacity: 0.7;
}

.admin-panel .btn-secondary,
.admin-panel .btn-secondary:hover {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.admin-panel .btn-secondary.active,
.admin-panel .btn-secondary.active.focus,
.admin-panel .btn-secondary.active:focus,
.admin-panel .btn-secondary.active:hover,
.admin-panel .btn-secondary.focus,
.admin-panel .btn-secondary:active,
.admin-panel .btn-secondary:active.focus,
.admin-panel .btn-secondary:active:focus,
.admin-panel .btn-secondary:active:hover,
.admin-panel .btn-secondary:focus {
  box-shadow: none;
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.admin-panel .btn-secondary.active.focus:after,
.admin-panel .btn-secondary.active:focus:after,
.admin-panel .btn-secondary.active:hover:after,
.admin-panel .btn-secondary.focus:after,
.admin-panel .btn-secondary:active.focus:after,
.admin-panel .btn-secondary:active:focus:after,
.admin-panel .btn-secondary:active:hover:after,
.admin-panel .btn-secondary:focus:after {
  opacity: 0.4;
}

.admin-panel .btn-secondary:disabled,
.admin-panel .btn-secondary:disabled:hover {
  color: #fff;
}

.admin-panel .btn-link,
.admin-panel .btn-link:focus,
.admin-panel .btn-link:hover {
  text-decoration: none;
}

.admin-panel
  .bootstrap-switch
  .bootstrap-switch-handle-on.bootstrap-switch-primary {
  background: #38e29b;
}

.admin-panel .bootstrap-switch {
  border: 2px solid #38e29b;
}

.admin-panel .dismissable {
  z-index: 10000000;
  display: none;
}

.admin-panel .message {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  color: #fff;
  font-weight: 700;
  z-index: 10003;
}

.admin-panel .message.show {
  display: block;
  background: #38e29b;
}

.admin-panel .message span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 18px;
  width: 80%;
  text-align: center;
}

.admin-panel .message[data-message-type='success'] {
  background: #38e29b;
}

.admin-panel .message[data-message-type='error'] {
  background: #ff1731;
}

.admin-panel .message__form {
  position: relative;
  display: block;
  max-width: 100%;
}

.admin-panel .message__form label {
  height: 35px;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 30px;
  margin-bottom: 0;
}

.admin-panel .message__form label > span {
  display: block;
  width: 100%;
  height: 100%;
  font-weight: 300;
  line-height: 32px;
  color: #fff;
  border-bottom: 2px solid #fff;
  transition: border-bottom-color 0.2s ease-in-out;
  cursor: text;
  pointer-events: none;
}

.admin-panel .message__form label > span span {
  transform-origin: 0 50%;
  transition: transform 0.2s ease-in-out;
  cursor: text;
}

.admin-panel .message__form label.error {
  color: #ff1731;
}

.admin-panel .message__form label.error > span {
  color: #ff1731;
  border-bottom-color: #ff1731 !important;
}

.admin-panel .message__form label.error .field {
  color: #ff1731;
}

.admin-panel .message__form label .field.is-focused + span span,
.admin-panel .message__form label .field:not(.is-empty) + span span {
  transform: scale(0.68) translateY(-31px);
  cursor: default;
}

.admin-panel .message__form label .field.is-focused + span {
  border-bottom-color: #38e29b;
}

.admin-panel .message__form label .field {
  background: transparent;
  font-weight: 300;
  border: 0;
  color: #282f37;
  outline: none;
  cursor: text;
  display: block;
  width: 100%;
  line-height: 32px;
  padding-bottom: 3px;
  transition: opacity 0.2s ease-in-out;
}

.admin-panel .message__form label .field::-webkit-input-placeholder {
  color: #888;
}

.admin-panel .message__form label .field::-moz-placeholder {
  color: #888;
}

.admin-panel .message__form label .field:-ms-input-placeholder {
  color: #424770;
}

.admin-panel .message__form label .field.is-empty:not(.is-focused) {
  opacity: 0;
}

.admin-panel .message__form [data-active-input-error] {
  display: block;
  max-height: 0;
  overflow: hidden;
  position: relative;
  background-color: #ff4c63;
  color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 12px;
  transition: max-height 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    padding 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  padding: 0 10px;
  line-height: 20px;
}

.admin-panel .message__form [data-active-input-error].active {
  max-height: 100px;
  padding: 5px 10px;
}

.admin-panel .message__form [data-active-input-error] li,
.admin-panel .message__form [data-active-input-error] ul {
  padding: 0;
  margin: 0;
}

.admin-panel .tip {
  position: relative;
  z-index: 10001;
  text-align: center;
  background-color: #1f2d3d;
  color: #f4f4f4;
  font-size: 16px;
  border-radius: 8px;
  padding: 16px 16px 24px;
  width: 100%;
  max-width: 320px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) {
  .admin-panel .tip {
    max-width: none;
    width: 100%;
  }
}

.admin-panel .tip-small {
  max-width: 240px;
}

.admin-panel .tip:before {
  display: block;
  width: 100%;
  height: 10px;
}

.admin-panel .tip:after,
.admin-panel .tip:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.admin-panel .tip:after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #1f2d3d;
}

.admin-panel .tip a {
  color: #f4f4f4;
  text-decoration: underline;
}

.admin-panel .tip a:hover {
  text-decoration: none;
}

.admin-panel .tip a[data-close] {
  transition: all 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  display: block;
  text-transform: uppercase;
  position: absolute;
  bottom: 7px;
  right: 7px;
  color: #fff;
  opacity: 0.5;
  text-shadow: 0;
  font-size: 12px;
  font-weight: 400;
  vertical-align: middle;
  text-shadow: none !important;
  letter-spacing: 0.03em;
}

.admin-panel .tip a[data-close]:hover {
  opacity: 1;
}

.admin-panel .tip a[data-close]:after {
  content: 'Got it';
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
  margin-left: 5px;
}

.admin-panel .tip.bottom:before {
  display: block;
  top: -10px;
  width: 100%;
  height: 10px;
}

.admin-panel .tip.bottom:after,
.admin-panel .tip.bottom:before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.admin-panel .tip.bottom:after {
  bottom: -15px;
  top: auto;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #1f2d3d;
  border-bottom: none;
}

.admin-panel .tip.affiliate {
  position: absolute;
  box-shadow: 0 2px 5px #333;
  font-weight: 300;
  font-size: 14px;
  z-index: 10000;
  padding: 10px 6px;
  left: auto;
  transform: none;
}

.admin-panel .tip.affiliate .text-section {
  padding: 0 10px 16px;
}

.admin-panel .tip.affiliate .button-section {
  cursor: pointer;
}

.admin-panel .tip.affiliate .button-section a {
  text-decoration: none !important;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

.admin-panel .tip.affiliate .button-section a:hover {
  opacity: 1;
}

.admin-panel .tip.affiliate .button-section .tip-answer-yes {
  border: 1px solid #cfcfcf;
  background-color: transparent;
  border-radius: 2px;
  margin-right: 5px;
  color: #dfdfdf;
  padding: 3px 8px;
  letter-spacing: 0.5px;
  text-decoration: none !important;
}

.admin-panel .tip.affiliate .button-section .tip-answer-no {
  opacity: 0.6;
  border: none;
  background-color: transparent;
  color: #fff;
  margin-left: 10px;
  letter-spacing: 0.5px;
  text-decoration: none;
}

.admin-panel .tip.affiliate.eligible {
  top: -115px;
  left: 17px;
  width: 260px;
}

.admin-panel .tip.affiliate {
  width: 300px;
  top: -103px;
  right: 0;
}

@media (max-width: 399px) {
  .admin-panel .tip.affiliate:after {
    left: 43px;
  }
}

@media (min-width: 400px) {
  .admin-panel .tip.affiliate {
    right: 77px;
  }
}

@media only screen and (min-width: 768px) {
  .admin-panel .tip.affiliate {
    top: -93px;
    left: 16px;
  }
}

.admin-panel .tip.affiliate .button-section a:first-child {
  padding: 3px 16px;
}

.admin-panel .tippy-tooltip[data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}

.admin-panel .tippy-iOS {
  cursor: pointer !important;
  -webkit-tap-highlight-color: transparent;
}

.admin-panel .tippy-popper {
  pointer-events: none;
  max-width: calc(100vw - 10px);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform;
}

.admin-panel .tippy-tooltip {
  position: relative;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  background-color: #333;
  transition-property: visibility, opacity, transform;
  outline: 0;
}

.admin-panel .tippy-tooltip[data-placement^='top'] > .tippy-arrow {
  border-width: 8px 8px 0;
  border-top-color: #333;
  margin: 0 3px;
  transform-origin: 50% 0;
  bottom: -7px;
}

.admin-panel .tippy-tooltip[data-placement^='bottom'] > .tippy-arrow {
  border-width: 0 8px 8px;
  border-bottom-color: #333;
  margin: 0 3px;
  transform-origin: 50% 7px;
  top: -7px;
}

.admin-panel .tippy-tooltip[data-placement^='left'] > .tippy-arrow {
  border-width: 8px 0 8px 8px;
  border-left-color: #333;
  margin: 3px 0;
  transform-origin: 0 50%;
  right: -7px;
}

.admin-panel .tippy-tooltip[data-placement^='right'] > .tippy-arrow {
  border-width: 8px 8px 8px 0;
  border-right-color: #333;
  margin: 3px 0;
  transform-origin: 7px 50%;
  left: -7px;
}

.admin-panel .tippy-tooltip[data-interactive][data-state='visible'] {
  pointer-events: auto;
}

.admin-panel .tippy-tooltip[data-inertia][data-state='visible'] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.admin-panel .tippy-arrow {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.admin-panel .tippy-content {
  padding: 5px 9px;
}

.admin-panel .tooltip-template {
  display: none;
}

.admin-panel .tippy-content {
  padding: 0 !important;
}

.admin-panel .tippy-tooltip.linktree-theme {
  background-color: #0a0b0d;
  border-radius: 8px;
  padding: 12px 0 0;
}

.admin-panel .tippy-tooltip.linktree-theme[data-placement^='top'] .tippy-arrow {
  border-top-color: #0a0b0d;
}

.admin-panel
  .tippy-tooltip.linktree-theme[data-placement^='bottom']
  .tippy-arrow {
  border-bottom-color: #0a0b0d;
}

.admin-panel .tippy-tooltip.linktree-theme .tooltip-content {
  padding: 0 16px;
}

.admin-panel .tippy-tooltip.linktree-theme .tooltip-content p > a {
  display: initial;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  height: auto;
  text-decoration: underline;
  color: #bec1c3;
}

.admin-panel .tippy-tooltip.linktree-theme .tooltip-content:last-child {
  padding-bottom: 12px;
}

.admin-panel .tippy-tooltip.linktree-theme .tooltip-content:last-child p {
  margin-bottom: 0;
}

.admin-panel .tippy-tooltip.linktree-theme .tooltip-buttons {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #1d2329;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.admin-panel .tippy-tooltip.linktree-theme b,
.admin-panel .tippy-tooltip.linktree-theme strong {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: #fff;
  margin-bottom: 4px;
}

.admin-panel .tippy-tooltip.linktree-theme p {
  font-size: 12px;
  line-height: 1.67;
  color: #bec1c3;
  margin-bottom: 14px;
  margin-top: 0;
}

.admin-panel .tippy-tooltip.linktree-theme a:hover {
  text-decoration: underline;
}

.admin-panel .tippy-tooltip.linktree-theme a,
.admin-panel .tippy-tooltip.linktree-theme button {
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  line-height: 40px;
  height: 40px;
  background-color: #0a0b0d;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.admin-panel .tippy-tooltip.linktree-theme a:hover,
.admin-panel .tippy-tooltip.linktree-theme button:hover {
  background-color: #1d2329;
}

.admin-panel [data-popper] {
  z-index: 10001;
}

.admin-panel [data-popper-visible='true'] {
  opacity: 1;
  visibility: visible;
}

.admin-panel [data-popper-visible='false'] {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.admin-panel [data-popper-animation='fade'] {
  transition: 0.2s ease-in-out;
}

.admin-panel [data-popper-theme='interface'] {
  background-color: #fff;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  color: #686d73;
  font-size: 14px;
  min-width: 230px;
}

.admin-panel [data-popper-theme='interface'] ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-panel [data-popper-theme='interface'] ul li {
  padding: 8px 16px 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.admin-panel [data-popper-theme='interface'] ul li:hover {
  color: #4f29cc;
  background-color: #eee8ff;
}

.admin-panel [data-popper-theme='interface'] ul li:not(:last-child) {
  margin-bottom: 8px;
}

.admin-panel #HW_frame_cont {
  display: none;
}

@media only screen and (min-width: 768px) {
  .admin-panel #HW_frame_cont {
    z-index: 10001 !important;
    left: 70px !important;
    display: block;
  }
}

.admin-panel section.plans {
  background-color: #fff;
  border-left: 0;
  border-radius: 4px;
  border-top: 0;
  padding: 24px 16px;
  position: relative;
  padding: 0 !important;
  border-radius: 8px;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 160px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.plans {
    padding: 32px 24px;
    flex-direction: row;
  }
}

.admin-panel section.plans > section {
  flex: 1;
  text-align: left;
  padding: 0 26.66667px 40px;
  background-color: #fff;
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.plans > section {
    padding: 0 60px 80px;
  }
  .admin-panel section.plans > section:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .admin-panel section.plans > section:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.admin-panel section.plans section.free {
  border-top: 5px solid transparent;
  border-right: 1px solid #f5f6f8;
}

.admin-panel section.plans section.pro {
  border-top: 5px solid #38e29b;
}

.admin-panel section.plans section.pro ul svg {
  fill: #00d775;
}

.admin-panel section.plans h3 {
  margin: 40px 0 0;
  text-align: left;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.plans h3 {
    margin: 60px 0 0;
  }
}

.admin-panel section.plans ul {
  list-style: none;
  padding-left: 20px;
}

@media only screen and (min-width: 768px) {
  .admin-panel section.plans ul {
    padding-left: 0;
  }
}

.admin-panel section.plans ul li {
  position: relative;
  margin-bottom: 0.5em;
}

.admin-panel section.plans ul svg {
  position: absolute;
  top: 7px;
  left: -25px;
  fill: #a9abaf;
}

@media only screen and (max-width: 767px) {
  .admin-panel section.plans .btn {
    width: 100%;
  }
}

.admin-panel section.plans a:not(.button) {
  opacity: 0.5;
  padding: 0.5em 0;
  display: block;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.admin-panel section.plans .desc h3 {
  margin-top: 2em !important;
  margin-bottom: 0 !important;
  font-size: 30px !important;
}

.admin-panel section.plans .desc p {
  margin-top: 0.25em;
  font-size: 1.2em;
}

.admin-panel section.plans .price {
  font-weight: 700;
}

.admin-panel section.plans .price .cur {
  font-style: normal;
  line-height: 4em;
  vertical-align: top;
}

.admin-panel section.plans .price .val {
  font-size: 4em;
}

.admin-panel section.plans .features p {
  margin-top: 2em;
  font-size: 1.2em;
  font-weight: 700;
}

.admin-panel section.plans .features svg {
  height: 10.5px;
  width: 15px;
}

@media print {
  .admin-panel body {
    background: #fff;
  }
  .admin-panel a,
  .admin-panel h1,
  .admin-panel h2,
  .admin-panel h3,
  .admin-panel h4,
  .admin-panel h5,
  .admin-panel li,
  .admin-panel p {
    color: #000;
  }
}

.admin-panel .sidebar-logo-wrap {
  top: 20px;
  display: block;
}

.admin-panel .secondary.admin.has-toolbar,
.admin-panel .sidebar-logo-wrap {
  transition: all 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .banner--error {
  background-color: #ffe2e5 !important;
}

.admin-panel .banner--error-text {
  color: #cc0e1f;
  -webkit-text-decoration-color: #cc0e1f;
  text-decoration-color: #cc0e1f;
  text-decoration: underline;
}

.admin-panel .banner {
  color: #323232;
  background-color: #ffe2e5;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  line-height: 1;
  border-bottom: 1px solid #d7dce1;
}

@media only screen and (max-width: 767px) {
  .admin-panel .banner {
    font-size: 12px;
  }
}

.admin-panel .banner .banner__inner {
  text-decoration: none !important;
  line-height: 1.4;
  padding: 0 24px;
  width: 100%;
  display: flex;
  align-items: center;
}

.admin-panel .banner .banner__inner > :not(:last-child) {
  margin-right: 8px;
}

@media only screen and (max-width: 767px) {
  .admin-panel .banner .banner__inner {
    display: block;
    padding: 15px 50px 15px 15px;
  }
}

.admin-panel .banner--login .banner__inner {
  justify-content: center;
}

.admin-panel .banner--admin {
  padding: 0;
  z-index: 1000000;
  transition: all 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  .admin-panel .banner--admin {
    height: 0;
    margin-left: 64px;
  }
}

.admin-panel .banner__close {
  width: 70px;
  height: 64px;
  z-index: 100000;
  display: block;
  position: absolute;
  padding-right: 30px;
  padding-top: 15px;
  right: 0;
  color: #fff;
  font-size: 20px;
  top: 0;
  text-align: right;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .admin-panel .banner__close {
    padding-right: 20px;
  }
}

.admin-panel .cta-button {
  background: transparent;
  border: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-weight: 700;
}

.admin-panel .cta-button:focus {
  outline: none;
}

.admin-panel .banner-tooltip,
.admin-panel .support-icon {
  display: inline-block;
  line-height: 0;
}

.admin-panel .banner-tooltip svg,
.admin-panel .support-icon svg {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 50%;
}

@media only screen and (min-width: 768px) {
  .admin-panel body.slide-down-admin .body__inner {
    position: relative;
  }
  .admin-panel body.slide-down-admin .banner--admin {
    height: 64px;
  }
  .admin-panel body.slide-down-admin .secondary.admin.has-toolbar {
    top: 64px;
  }
  .admin-panel body.slide-down-admin section.main.admin,
  .admin-panel body.slide-down-admin section.secondary.admin {
    height: calc(100vh - 64px) !important;
    min-height: calc(100vh - 64px) !important;
  }
  .admin-panel body.slide-down-admin[data-page-links] section.main.admin {
    height: calc(100vh - 128px) !important;
    min-height: calc(100vh - 128px) !important;
  }
  .admin-panel
    body.slide-down-admin--ig-auth-warning.slide-down-admin--email-verification
    .secondary.admin.has-toolbar {
    top: 128px;
  }
  .admin-panel
    body.slide-down-admin--ig-auth-warning.slide-down-admin--email-verification
    section.main.admin,
  .admin-panel
    body.slide-down-admin--ig-auth-warning.slide-down-admin--email-verification
    section.secondary.admin {
    height: calc(100vh - 128px) !important;
    min-height: calc(100vh - 128px) !important;
  }
}

.admin-panel .donation_banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 1000;
  padding: 16px;
  min-height: 56px;
  color: #fff;
  cursor: pointer;
}

.admin-panel .donation_banner:after {
  content: attr(data-title);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  line-height: 1 !important;
  transition: opacity 0.2s ease-in-out 0.2s;
  text-align: center;
}

.admin-panel .donation_banner--open {
  cursor: default;
}

.admin-panel .donation_banner--open:after {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.admin-panel .donation_banner--open .donation_banner {
  transition: max-width 0.2s ease-in-out 0.4s, padding 0.2s ease-in-out 0.4s;
}

.admin-panel .donation_banner--open .donation_banner__toggle {
  transform: rotate(0);
  pointer-events: auto;
}

.admin-panel .donation_banner--open .donation_banner__collapsible {
  transition: max-height 0.2s ease-in-out 0.2s, max-width 0.2s ease-in-out 0.4s;
}

.admin-panel .donation_banner__toggle {
  height: 24px;
  width: 24px;
  margin-left: auto;
  position: absolute;
  right: 16px;
  top: 16px;
  transform: rotate(180deg);
  pointer-events: none;
  cursor: pointer;
  transition: transform 0.2s ease-in-out 0.2s;
}

.admin-panel .donation_banner__toggle.active .chevron {
  display: none;
}

.admin-panel .donation_banner__toggle.active .cross,
.admin-panel .donation_banner__toggle:not(.active) .chevron {
  display: block;
}

.admin-panel .donation_banner__toggle:not(.active) .cross {
  display: none;
}

.admin-panel .donation_banner__toggle svg {
  width: 100%;
  height: 100%;
  fill: #888;
}

.admin-panel .donation_banner__collapsible {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  max-width: 680px;
  margin: 0 auto;
}

.admin-panel .donation_banner__inner {
  min-height: 48px;
  height: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  align-items: center;
  transition: margin-top 0.2s ease-in-out, margin-bottom 0.2s ease-in-out;
}

.admin-panel .donation_banner__slide {
  overflow: hidden;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: margin 0.2s ease-in-out;
}

.admin-panel .donation_banner__iframe {
  margin-top: 0;
}

.admin-panel .donation_banner__logo {
  border-radius: 50%;
  display: block;
  background-color: #fff;
  margin-bottom: 8px;
}

.admin-panel .donation_banner__content {
  max-width: 358px;
  padding: 0 16px;
  text-align: center;
}

.admin-panel .donation_banner:after,
.admin-panel .donation_banner__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.75;
  letter-spacing: -0.32px;
}

.admin-panel .donation_banner__message {
  opacity: 0.75;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.28px;
  margin: 8px 0 24px;
}

.admin-panel .donation_banner__cta {
  width: 100%;
  color: #000;
  text-align: center;
}

.admin-panel .donation_banner__cta .btn,
.admin-panel .donation_banner__cta .cta {
  font-size: 12px;
  color: #fff;
  text-decoration: underline;
  display: block;
  text-transform: uppercase;
  padding: 16px 0;
  border-radius: 12px;
  color: #000;
  background-color: #fff;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.32px;
  text-decoration: none;
}

.admin-panel .donation_banner--preview .donation_banner__cta {
  pointer-events: none;
}

.admin-panel .preview .preview-wrap .donation_banner {
  position: absolute;
  bottom: 20px;
  left: 17px;
  right: 17px;
}

.admin-panel
  .service:not(.is-mobile)
  .donation_banner--profile
  .service:not(.is-mobile)
  .donation_banner__slide {
  flex-direction: row;
}

.admin-panel
  .service:not(.is-mobile)
  .donation_banner--profile
  .service:not(.is-mobile)
  .donation_banner__logo {
  height: 80px;
  width: 80px;
  margin-bottom: 0;
}

.admin-panel
  .service:not(.is-mobile)
  .donation_banner--profile
  .service:not(.is-mobile)
  .donation_banner__content {
  text-align: left;
}

.admin-panel
  .service:not(.is-mobile)
  .donation_banner--profile
  .service:not(.is-mobile)
  .donation_banner__message {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.32px;
  margin: 4px 0 0;
}

.admin-panel
  .service:not(.is-mobile)
  .donation_banner--profile
  .service:not(.is-mobile)
  .donation_banner__cta {
  max-width: 192px;
}

.admin-panel .coupon-banner {
  position: absolute;
  top: -48px;
  left: 0;
  right: 0;
  height: 48px;
  background: #000;
  color: #fff;
}

@media only screen and (max-width: 400px) {
  .admin-panel .coupon-banner {
    display: flex;
    justify-content: center;
    top: -80px;
    height: 80px;
  }
}

.admin-panel .coupon-banner__box {
  text-align: center;
  padding: 12px;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #bec1c3;
  align-content: center;
}

.admin-panel .coupon-banner__flex-break {
  flex-basis: 100%;
  height: 0;
  display: none;
}

@media only screen and (max-width: 400px) {
  .admin-panel .coupon-banner__flex-break .coupon-banner__flex-break {
    display: unset;
  }
}

.admin-panel .coupon-banner__duration-label {
  text-transform: capitalize;
  font-weight: 600;
  padding-left: 36px;
  padding-right: 8px;
  color: #fff;
  background: url(https://d1qr63pinnvtia.cloudfront.net/assets/dbf1af3ae353454bcaa637cb4649e8b593f2e6bc/images/icon-coupon.svg)
    0 0 no-repeat;
}

.admin-panel body[data-has-coupon] {
  margin-top: 48px !important;
}

@media only screen and (max-width: 400px) {
  .admin-panel body[data-has-coupon] {
    margin-top: 80px !important;
  }
}

.admin-panel body[data-has-coupon] .admin-sidebar.sidebar {
  height: calc(100vh - 48px);
  top: 48px !important;
}

@media only screen and (max-width: 400px) {
  .admin-panel body[data-has-coupon] .admin-sidebar.sidebar {
    height: calc(100vh - 80px);
    top: 80px !important;
  }
}

.admin-panel .img-circle {
  border-radius: 50%;
}

@media (min-width: 544px) {
  .admin-panel .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .admin-panel .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .admin-panel .container {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .admin-panel .container {
    max-width: 1140px;
  }
}

.admin-panel .container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.admin-panel .text-left {
  text-align: left;
}

.admin-panel .text-center {
  text-align: center;
}

.admin-panel .text-right {
  text-align: right;
}

.admin-panel .text-justify {
  text-align: justify;
}

.admin-panel .social-media {
  width: 100%;
  display: block;
  background: #fff;
}

.admin-panel .social-media-header {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  height: 64px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 24px;
  border-bottom: 1px solid #d7dce1;
}

.admin-panel .social-media-header > div {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.admin-panel .social-media-header > div > span {
  letter-spacing: -0.32px;
  margin-right: 16px;
}

.admin-panel .yHHN .sc-fHxwqH {
  padding: 0px;
  flex: 0 1 0%;
}

.admin-panel .joBQTw {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.admin-panel .joBQTw span {
  text-align: left;
  white-space: nowrap;
  color: #263238;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.32px;
}

.admin-panel .GOISA {
  position: relative;
  margin-right: 32px;
  font-size: 14px;
  font-weight: normal;
}

.admin-panel .GOISA::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 8px;
  width: 8px;
  margin-right: 8px;
  background-color: #39e09b;
  border-radius: 50%;
}

.admin-panel .yHHN > svg {
  flex: 0 0 16px;
}

.admin-panel svg:not(:root) {
  overflow: hidden;
}

.admin-panel .kTUawo {
  width: 20px;
  height: 20px;
  fill: currentcolor;
  stroke: none;
  transition: transform 200ms linear 0s;
}

.admin-panel .kFJBmd {
  position: relative;
  border-bottom: 1px solid #d7dce1;
}

.admin-panel .ikRYoy {
  height: calc(245px);
  padding: 0px 16px;
}
