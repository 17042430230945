.home .flag-select__option__icon {
  top: 0em !important;
}

.home .flag-select {
  margin-top: 35px;
}
.home .flag-select__btn {
  color: white;
}
.home .flag-select__btn:focus {
  outline: none;
}
.home .video {
  display: flex;
  justify-content: center;
}
.home .content-text-middle2 {
  padding: 8% 15px 0 10%;
}

.home .content-text-middle {
  padding: 18% 10% 0 30px;
}

.home .navbar-nav > li > a {
  font-size: 120% !important;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  padding: 40px 7px !important;
}

.home .navbar-header {
  float: none !important;
}

.home .navbar-brand {
  padding: 0 !important;
  padding-top: 20px !important;
}

.home .nav > li > a:hover {
  background: none !important;
}

.home .height-0 {
  height: 0;
}

.home .element-banner {
  position: relative;
  bottom: 0;
  z-index: 50;
  width: 100%;
}

.home .navbar-nav > li {
  margin-left: 15px;
}

.home .navbar .navbar-collapse {
  max-height: inherit;
}

.home .footer {
  background: url('../assests/images/footer.jpg') top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #cacaca;
}

.home .footer p.pull-left {
  margin-top: 85px;
}

.home .footer a {
  color: #cacaca;
}

.home footer .inner {
  padding-top: 10%;
  padding-bottom: 3%;
  padding-left: 1%;
}

.home .inner {
  padding-top: 5%;
  padding-bottom: 5%;
}

.home .container {
  padding-right: 0;
  padding-left: 0;
}
.home .btn-link {
  padding: 40px 7px !important;
}
.home .social {
  margin-right: 65px;
}

.home .social {
  padding: 0;
  margin: -45px -70px -5px 0;
  font-size: 0;
  line-height: 1;
}

.home .social.pull-right li {
  margin: 0 0 0 5px;
}

.home .social li {
  display: inline-block;
  font-family: 'fontello-social';
  margin-right: 5px;
  margin-bottom: 5px;
}

.home .social li a {
  display: table;
}

.home .social li a i {
  font-size: 36px;
  border: none;
  color: white !important;
}

.home .social li a i {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  color: #f5f5f5;
  background: none;
  line-height: 1;
  width: 28px;
  height: 28px !important;
  line-height: 1;
  -webkit-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  -moz-transition: all 200ms ease-in;
}

.home .light-wrapper {
  background: #fff;
  margin: 0;
}

.home .inner {
  padding-top: 5%;
  padding-bottom: 5%;
}

.home .content-text-middle {
  padding: 18% 10% 0 30px !important;
}

.home .baw {
  width: 100%;
  text-align: center;
  margin: 0 0 0 10px;
}

.home .btn.responsive-menu {
  padding: 7px 12px;
  margin: 0;
  display: none;
  font-size: 15px;
}

.home .navbar .btn,
.home .navbar .hs-button,
.home .navbar .parallax .btn-submit,
.home .navbar .btn-submit {
  color: white !important;
  background: transparent !important;
  margin-bottom: 10px !important;
  margin-right: 4px !important;
  padding: 11px 20px 10px 20px !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  text-shadow: none !important;
  border: none !important;
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
  text-transform: uppercase !important;
  -webkit-transition: all 200ms ease-in !important;
  -o-transition: all 200ms ease-in !important;
  -moz-transition: all 200ms ease-in !important;
}

.home .section-title h2 {
  font-size: 220%;
  line-height: 110%;
  color: #313334;
}

.home .copy {
  max-width: 6%;
  margin-right: 10px;
}

.home .social li a:hover {
  text-decoration: none;
}

.buttontext {
  background-color: #f0e849;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-weight: bold;
}

.buttontext:hover {
  background-color: #f0e849;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-weight: bold;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}



@media (max-width: 767px) {
  .home .logo {
    width: 70% !important;
  }
  .home .copy {
    max-width: 8%;
    margin-right: 10px;
  }
  .home .inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .home .no-sidebar {
    width: 100%;
  }
  .home .btn.responsive-menu {
    display: block;
  }
  .home .navbar .social {
    display: none;
  }
  .home .navbar .nav,
  .home .navbar .dropup,
  .home .navbar .dropdown,
  .home .navbar .collapse {
    position: relative;
  }
  .home .navbar .dropdown-menu {
    position: relative;
    left: inherit;
    top: inherit;
    float: none;
    width: auto;
    display: block !important;
    background: none;
  }
  .home .navbar-nav > li > a,
  .home .navbar-nav > li:first-child > a,
  .home .navbar .dropdown-menu li {
    border: none;
  }
  .home .navbar-nav > li > a,
  .home .navbar .dropdown-menu li a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.13) !important;
  }
  .home .navbar .nav .open > a,
  .home .navbar .nav .open > a:hover,
  .home .navbar .nav .open > a:focus {
    background: none;
    border: none;
  }
  .home .navbar-nav > li > a,
  .home .navbar .dropdown-menu li a,
  .home .navbar .dropdown-menu li a:hover,
  .home .navbar .dropdown-menu li a.active {
    padding: 12px 0 !important;
  }
  .home .navbar-nav .open .dropdown-menu > li > a {
    line-height: 1;
  }
  .home .navbar-nav > li > a,
  .home .navbar-nav > li > a:focus {
    color: #28b8d8;
  }
  .home .navbar .dropdown-menu li a {
    color: #cacaca;
  }
  .home .navbar .dropdown-menu {
    padding-left: 20px;
  }
  .home .navbar .dropdown-menu a {
    text-transform: none !important;
    font-size: 13px !important;
  }
  .home .navbar .btn.responsive-menu {
    margin: 10px -15px 10px 0 !important;
  }
  .home .navbar-header {
    float: left;
    width: 100%;
  }
  .home .navbar-header:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  .home .navbar .container {
    width: 100%;
    padding: 0;
  }
  .home .navbar .navbar-collapse {
    width: 100%;
    float: none !important;
    margin: 0;
    background: none;
  }
  .home .navbar .navbar-nav {
    width: 720px;
    margin: 0 auto;
    padding: 0;
  }
  .home .navbar .basic-wrapper {
    width: 720px;
    margin: 0 auto;
    display: none;
  }
  .home .navbar,
  .home .navbar.fixed {
    position: relative;
  }
  .home .navbar.fixed .navbar-header,
  .home .navbar .navbar-header {
    background: rgba(21, 21, 21, 0.94);
  }
  .home .navbar.fixed .dropdown-menu,
  .home .navbar .dropdown-menu {
    background: none;
  }
  .home .navbar .navbar-brand,
  .home .navbar.fixed .navbar-brand {
    padding-top: 25px;
  }
  .home .navbar.fixed .navbar-brand img {
    height: 25px !important;
    width: inherit !important;
  }
  .home .navbar.fixed .navbar-nav > li > a {
    padding: 30px 14px;
  }
  .home .navbar .container,
  .home .container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .home [class*='col-'] {
    margin-bottom: 30px;
  }
  .home .navbar .navbar-nav {
    width: 100%;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  .home .navbar .basic-wrapper {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  .home .navbar .navbar-nav > li {
    margin-left: 0;
  }
  .home .swiper-container.instagram {
    height: 250px;
  }
  .home .swiper-container.instagram img {
    height: 250px;
    width: 250px;
  }
  .home .swiper-container.gallery,
  .home .swiper-container.gallery img {
    height: 250px;
    width: auto;
  }
  .home .swiper .item .details .content .wrap {
    padding: 30px;
  }
  .home .swiper .item .details .content .wrap .text h2 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .home .swiper .item .details .content .wrap .text .info {
    display: none;
  }
  .home section.inner {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .home .services-1 .divide30 {
    display: none;
  }
  .home .services-3 .col .icon:before,
  .home .services-3 .col .icon:after {
    display: none;
  }
  .home .footer .inner {
    padding-bottom: 40px;
  }
  .home .footer p.pull-left,
  .home .footer ul.pull-right {
    float: none !important;
    text-align: center;
    margin-right: 0px !important;
  }

  .home .footer p.pull-left {
    margin-bottom: 70px;
  }

  .home .footer p {
    margin-bottom: 20px;
  }
  .home .sub-footer p {
    margin-bottom: 20px !important;
  }
  .home .page-title {
    display: none;
  }
  .home .filter {
    text-align: left;
  }
  .home .filter ul li a {
    padding: 0 12px;
  }
  .home .filter ul li:first-child a {
    padding-left: 0;
  }
  .home .fix-portfolio .items {
    margin-left: 0;
    margin-bottom: -10px;
  }
  .home .fix-portfolio .items li {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
  }
  .home #testimonials,
  .home .thin {
    width: 100%;
  }
  .home .share.pull-left {
    float: none !important;
  }
  .home .sidebar {
    margin-top: 70px;
    padding-left: 15px !important;
  }
  .home .pagination ul > li {
    margin-bottom: 20px;
  }
  .home .post-gallery li {
    width: 50%;
  }
  .home #comments .user {
    display: none;
  }
  .home #comments ul.children {
    padding: 0 0 0 20px;
  }
  .home #comments .arrow-box {
    margin-left: 0;
  }
  .home .about-author .author-image {
    display: none;
  }
  .home .tab {
    display: block !important;
    margin: 0 !important;
  }
  .home #testimonials .tab {
    display: inline-block !important;
  }
  .home .footer .widget.single .contact-info li {
    display: block;
  }
  .home .section-title.text-center h3:before,
  .home .section-title h3:after {
    display: none;
  }
  .home .blog-posts [class*='col-'] {
    margin-bottom: 0;
  }
  .home .offset {
    display: none;
  }
  .home .mode-xs .sticky-wrapper {
    display: none;
  }

  .cards {
    display: grid;
  }
}

@media (min-width: 1400px) {
  .home .element-banner2 {
    top: 15%;
  }
  .cards {
    display: flex;
  }
}
@media (max-width: 1024px) {
  .home .social {
    margin: 0px 0px 0px 0px;
  }
}
.home .element-banner2 {
  position: relative;
  top: 25%;
  z-index: 50;
  width: 100%;
  padding: 10% 0 20% 0;
}


.home .link-header2 {
  color: #333333 !important;
  background: #f0e849 !important;
  padding: 7px 15px;
  border-radius: 10px;
}
.home .link-boton {
  color: #333333 !important;
  font-weight: 900;
  background: #f0e849 !important;
  padding: 15px 15%;
  border-radius: 10px;
  box-shadow: -10px 7px 17px 0px rgba(178, 179, 179, 0.49);
  text-decoration: none;
}
.divide30 {
  margin-top: 60px;
}

.icon-home-1:before {
  content: '\e811';
}
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0;
  text-align: center;
  line-height: 1em;
}
