@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.admin-panel .plyr audio,
.admin-panel .plyr iframe,
.admin-panel .plyr video {
  display: block;
  height: 100%;
  width: 100%;
}

.admin-panel .plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.admin-panel .plyr:focus {
  outline: 0;
}

.admin-panel .plyr--full-ui {
  box-sizing: border-box;
}

.admin-panel .plyr--full-ui *,
.admin-panel .plyr--full-ui :after,
.admin-panel .plyr--full-ui :before {
  box-sizing: inherit;
}

.admin-panel .plyr--full-ui a,
.admin-panel .plyr--full-ui button,
.admin-panel .plyr--full-ui input,
.admin-panel .plyr--full-ui label {
  touch-action: manipulation;
}

.admin-panel .plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.admin-panel .plyr__captions span:empty {
  display: none;
}

.admin-panel .plyr--captions-active .plyr__captions {
  display: block;
}

.admin-panel .plyr__caption div {
  display: inline;
}

.admin-panel .plyr__control:focus {
  outline: 0;
}

.admin-panel a.plyr__control {
  text-decoration: none;
}

.admin-panel .plyr__control.plyr__control--pressed .icon--not-pressed,
.admin-panel .plyr__control.plyr__control--pressed .label--not-pressed,
.admin-panel .plyr__control:not(.plyr__control--pressed) .icon--pressed,
.admin-panel .plyr__control:not(.plyr__control--pressed) .label--pressed,
.admin-panel a.plyr__control:after,
.admin-panel a.plyr__control:before {
  display: none;
}

.admin-panel .plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.admin-panel .plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.admin-panel .plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}

.admin-panel .plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.admin-panel
  .plyr__controls
  .plyr__controls__item.plyr__progress__container:first-child,
.admin-panel .plyr__controls .plyr__controls__item.plyr__time + .plyr__time,
.admin-panel .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0;
}

.admin-panel .plyr [data-plyr='airplay'],
.admin-panel .plyr [data-plyr='captions'],
.admin-panel .plyr [data-plyr='fullscreen'],
.admin-panel .plyr [data-plyr='pip'],
.admin-panel .plyr__controls:empty {
  display: none;
}

.admin-panel .plyr--airplay-supported [data-plyr='airplay'],
.admin-panel .plyr--captions-enabled [data-plyr='captions'],
.admin-panel .plyr--fullscreen-enabled [data-plyr='fullscreen'],
.admin-panel .plyr--pip-supported [data-plyr='pip'] {
  display: inline-block;
}

.admin-panel .plyr__menu {
  display: flex;
  position: relative;
}

.admin-panel .plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}

.admin-panel .plyr__menu .plyr__control[aria-expanded='true'] svg {
  transform: rotate(90deg);
}

.admin-panel .plyr__menu .plyr__control[aria-expanded='true'] .plyr__tooltip {
  display: none;
}

.admin-panel .plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.admin-panel .plyr__menu__container [role='menuitem'],
.admin-panel .plyr__menu__container [role='menuitemradio'] {
  margin-top: 2px;
}

.admin-panel .plyr__menu__container [role='menuitem']:first-child,
.admin-panel .plyr__menu__container [role='menuitemradio']:first-child {
  margin-top: 0;
}

.admin-panel .plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}

.admin-panel
  .plyr__menu__container
  .plyr__control--forward.plyr__tab-focus:after,
.admin-panel .plyr__menu__container .plyr__control--forward:hover:after {
  border-left-color: currentColor;
}

.admin-panel .plyr__menu__container .plyr__control--back.plyr__tab-focus:after,
.admin-panel .plyr__menu__container .plyr__control--back:hover:after {
  border-right-color: currentColor;
}

.admin-panel .plyr__menu__container .plyr__control[role='menuitemradio']:after,
.admin-panel
  .plyr__menu__container
  .plyr__control[role='menuitemradio']:before {
  border-radius: 100%;
}

.admin-panel .plyr__menu__container .plyr__control[role='menuitemradio']:after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}

.admin-panel
  .plyr__menu__container
  .plyr__control[role='menuitemradio'][aria-checked='true']:after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.admin-panel
  .plyr__menu__container
  .plyr__control[role='menuitemradio'].plyr__tab-focus:before,
.admin-panel
  .plyr__menu__container
  .plyr__control[role='menuitemradio']:hover:before {
  background: rgba(35, 40, 47, 0.1);
}

.admin-panel .plyr .plyr__control.plyr__tab-focus .plyr__tooltip,
.admin-panel .plyr .plyr__control:hover .plyr__tooltip,
.admin-panel .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%) scale(1);
}

.admin-panel .plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.admin-panel
  .plyr__controls
  > .plyr__control:first-child
  + .plyr__control
  .plyr__tooltip,
.admin-panel .plyr__controls > .plyr__control:first-child .plyr__tooltip {
  left: 0;
  transform: translateY(10px) scale(0.8);
  transform-origin: 0 100%;
}

.admin-panel .plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translateY(10px) scale(0.8);
  transform-origin: 100% 100%;
}

.admin-panel
  .plyr__controls
  > .plyr__control:first-child
  + .plyr__control.plyr__tab-focus
  .plyr__tooltip,
.admin-panel
  .plyr__controls
  > .plyr__control:first-child
  + .plyr__control
  .plyr__tooltip--visible,
.admin-panel
  .plyr__controls
  > .plyr__control:first-child
  + .plyr__control:hover
  .plyr__tooltip,
.admin-panel
  .plyr__controls
  > .plyr__control:first-child.plyr__tab-focus
  .plyr__tooltip,
.admin-panel
  .plyr__controls
  > .plyr__control:first-child
  .plyr__tooltip--visible,
.admin-panel .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip,
.admin-panel
  .plyr__controls
  > .plyr__control:last-child.plyr__tab-focus
  .plyr__tooltip,
.admin-panel
  .plyr__controls
  > .plyr__control:last-child
  .plyr__tooltip--visible,
.admin-panel .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0) scale(1);
}

.admin-panel .plyr__progress input[type='range'] {
  position: relative;
  z-index: 2;
}

.admin-panel .plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -ms-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.admin-panel .plyr__volume {
  align-items: center;
  display: flex;
  max-width: 110px;
  min-width: 80px;
  position: relative;
  width: 20%;
}

.admin-panel .plyr--is-ios .plyr__volume {
  min-width: 0;
  width: auto;
}

.admin-panel .plyr--audio {
  display: block;
}

.admin-panel .plyr--video {
  background: #000;
  overflow: hidden;
}

.admin-panel .plyr--video.plyr--menu-open {
  overflow: visible;
}

.admin-panel .plyr__video-wrapper {
  background: #000;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.admin-panel .plyr__video-embed,
.admin-panel .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%;
}

.admin-panel .plyr__video-embed iframe,
.admin-panel .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  left: 0;
  position: absolute;
  top: 0;
}

.admin-panel .plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.admin-panel .plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.admin-panel .plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}

.admin-panel .plyr__control--overlaid:focus,
.admin-panel .plyr__control--overlaid:hover {
  opacity: 1;
}

.admin-panel .plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.admin-panel .plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.admin-panel .plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.admin-panel .plyr:fullscreen video {
  height: 100%;
}

.admin-panel .plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.admin-panel .plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.admin-panel .plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.admin-panel .plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.admin-panel .plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

.admin-panel .plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.admin-panel .plyr:-webkit-full-screen video {
  height: 100%;
}

.admin-panel .plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.admin-panel .plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.admin-panel .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.admin-panel
  .plyr:-webkit-full-screen
  .plyr__control
  .icon--exit-fullscreen
  + svg {
  display: none;
}

.admin-panel .plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

.admin-panel .plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.admin-panel .plyr:-moz-full-screen video {
  height: 100%;
}

.admin-panel .plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.admin-panel .plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.admin-panel .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.admin-panel
  .plyr:-moz-full-screen
  .plyr__control
  .icon--exit-fullscreen
  + svg {
  display: none;
}

.admin-panel .plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

.admin-panel .plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.admin-panel .plyr:-ms-fullscreen video {
  height: 100%;
}

.admin-panel .plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.admin-panel .plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.admin-panel .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.admin-panel .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.admin-panel .plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

.admin-panel .plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}

.admin-panel .plyr--fullscreen-fallback video {
  height: 100%;
}

.admin-panel .plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.admin-panel .plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.admin-panel .plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.admin-panel
  .plyr--fullscreen-fallback
  .plyr__control
  .icon--exit-fullscreen
  + svg {
  display: none;
}

.admin-panel .plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

.admin-panel .plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.admin-panel .plyr__ads > div,
.admin-panel .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.admin-panel .plyr__ads:after:empty {
  display: none;
}

.admin-panel .plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0) scale(1);
}

.admin-panel .plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.admin-panel .plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3;
}

.admin-panel .plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}

.admin-panel .plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.admin-panel .plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.admin-panel .plyr--no-transition {
  transition: none !important;
}

.admin-panel .plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.admin-panel .plyr [hidden] {
  display: none !important;
}

.admin-panel .btn-link[class*='animate'] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes spin-positioned-x {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(359deg);
  }
}

@keyframes spin-positioned-x {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(359deg);
  }
}

@-webkit-keyframes spin-positioned-xy {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@keyframes spin-positioned-xy {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@-webkit-keyframes breathing {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@-webkit-keyframes animate-buzz {
  0% {
    transform: translateX(3px) rotate(1deg);
  }
  2.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  5% {
    transform: translateX(3px) rotate(1deg);
  }
  7.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  10% {
    transform: translateX(2px) rotate(1deg);
  }
  12.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  15% {
    transform: translateX(2px) rotate(1deg);
  }
  17.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  20% {
    transform: translateX(1px) rotate(1deg);
  }
  22.5% {
    transform: translateX(-1px) rotate(-1deg);
  }
  25% {
    transform: translateX(0) rotate(0);
  }
}

@keyframes animate-buzz {
  0% {
    transform: translateX(3px) rotate(1deg);
  }
  2.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  5% {
    transform: translateX(3px) rotate(1deg);
  }
  7.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  10% {
    transform: translateX(2px) rotate(1deg);
  }
  12.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  15% {
    transform: translateX(2px) rotate(1deg);
  }
  17.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  20% {
    transform: translateX(1px) rotate(1deg);
  }
  22.5% {
    transform: translateX(-1px) rotate(-1deg);
  }
  25% {
    transform: translateX(0) rotate(0);
  }
}

.admin-panel .admin .link__highlight .animate-buzz.active,
.admin-panel .admin .link__highlight .animate-buzz:hover,
.admin-panel .service .animate-buzz {
  -webkit-animation-name: animate-buzz;
  animation-name: animate-buzz;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.admin-panel .service .animate-buzz:active,
.admin-panel .service .animate-buzz:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
}

@-webkit-keyframes animate-wobble {
  4.16% {
    transform: translateY(8px);
  }
  8.33% {
    transform: translateY(-6px);
  }
  12.48% {
    transform: translateY(4px);
  }
  16.66% {
    transform: translateY(-2px);
  }
  20.81% {
    transform: translateY(1px);
  }
  25% {
    transform: translateY(0);
  }
}

@keyframes animate-wobble {
  4.16% {
    transform: translateY(8px);
  }
  8.33% {
    transform: translateY(-6px);
  }
  12.48% {
    transform: translateY(4px);
  }
  16.66% {
    transform: translateY(-2px);
  }
  20.81% {
    transform: translateY(1px);
  }
  25% {
    transform: translateY(0);
  }
}

.admin-panel .admin .link__highlight .animate-wobble.active,
.admin-panel .admin .link__highlight .animate-wobble:hover,
.admin-panel .service .animate-wobble {
  -webkit-animation-name: animate-wobble;
  animation-name: animate-wobble;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.admin-panel .service .animate-wobble:active,
.admin-panel .service .animate-wobble:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
}

@-webkit-keyframes animate-pop {
  0% {
    transform: scale(1);
  }
  12.5% {
    transform: scale(1.05);
  }
  25% {
    transform: scale(1);
  }
}

@keyframes animate-pop {
  0% {
    transform: scale(1);
  }
  12.5% {
    transform: scale(1.05);
  }
  25% {
    transform: scale(1);
  }
}

.admin-panel .admin .link__highlight .animate-pop.active,
.admin-panel .admin .link__highlight .animate-pop:hover,
.admin-panel .service .animate-pop {
  -webkit-animation-name: animate-pop;
  animation-name: animate-pop;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.admin-panel .service .animate-pop:active,
.admin-panel .service .animate-pop:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
}

@-webkit-keyframes animate-swipe-admin {
  4.16%,
  8.33% {
    background-position: 0 0;
    color: #fff;
  }
  12.49%,
  20.83% {
    background-position: -100% 0;
    color: #777;
  }
  24.99%,
  29.16% {
    background-position: 0 0;
    color: #fff;
  }
  33.33% {
    background-position: 100% 0;
    color: #777;
  }
}

@keyframes animate-swipe-admin {
  4.16%,
  8.33% {
    background-position: 0 0;
    color: #fff;
  }
  12.49%,
  20.83% {
    background-position: -100% 0;
    color: #777;
  }
  24.99%,
  29.16% {
    background-position: 0 0;
    color: #fff;
  }
  33.33% {
    background-position: 100% 0;
    color: #777;
  }
}

.admin-panel .admin .link__highlight .animate-swipe {
  background-size: 200% auto;
  background-position: 100% 0;
  background-color: transparent !important;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  transition-timing-function: ease-in-out;
  background-image: linear-gradient(90deg, #777 50%, transparent 0);
  color: #777;
}

.admin-panel .admin .link__highlight .animate-swipe.active,
.admin-panel .admin .link__highlight .animate-swipe:hover {
  -webkit-animation-name: animate-swipe-admin;
  animation-name: animate-swipe-admin;
}

.admin-panel body:after {
  content: 'small';
  display: none;
}

@media only screen and (min-width: 768px) {
  .admin-panel body:after {
    content: 'medium';
  }
}

@media only screen and (min-width: 992px) {
  .admin-panel body:after {
    content: 'large';
  }
}

@media only screen and (min-width: 1260px) {
  .admin-panel body:after {
    content: 'extra-large';
  }
}

.admin-panel body.inactive {
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-panel body.inactive .service {
  text-align: center;
}

.admin-panel body.inactive .service p {
  margin: 0;
}

.admin-panel body.forwarding .wrapper {
  padding-top: 220px;
  padding-bottom: 190px;
}

@-webkit-keyframes colorswipe {
  0% {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes colorswipe {
  0% {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes colorunswipe {
  0% {
    width: 100%;
  }
  to {
    width: 0;
  }
}

@keyframes colorunswipe {
  0% {
    width: 100%;
  }
  to {
    width: 0;
  }
}

@-webkit-keyframes swipeup {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes swipeup {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.admin-panel body.forwarding .wrapper {
  display: block;
  position: absolute;
  height: 100vh;
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  place-items: center center;
}

.admin-panel body.forwarding .center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  text-align: center;
}

.admin-panel body.forwarding .move {
  display: inline-block;
}

.admin-panel body.forwarding .redirecting {
  display: block;
}

.admin-panel body.forwarding .redirecting,
.admin-panel body.forwarding .show,
.admin-panel body.forwarding .url {
  height: 100px;
  line-height: 100px;
}

.admin-panel body.forwarding .url {
  overflow: hidden;
}

.admin-panel body.forwarding .show {
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
}

.admin-panel body.forwarding .examplesiteco {
  display: block;
  font-family: Karla;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  position: relative;
}

.admin-panel body.forwarding .examplesiteco--ghost {
  display: block;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 0;
}

.admin-panel body.forwarding .swipeOne {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#28bf7b),
    color-stop(20%, #2ed3ba),
    color-stop(40%, #44c2d7),
    color-stop(60%, #5aa2dc),
    color-stop(80%, #708de0),
    to(#8a86e5)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation-name: colorswipe;
  animation-name: colorswipe;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  z-index: 1;
  background-color: #fff;
}

.admin-panel body.forwarding .swipeUp {
  display: inline-block;
  position: relative;
  -webkit-animation-name: swipeup;
  animation-name: swipeup;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.admin-panel .service .link .btn-link {
  border-width: 2px;
  border-style: solid;
}

.admin-panel .service .link .btn-link .icon--amazon {
  width: 24px;
  height: 24px;
}

.admin-panel .service .link .btn-link:hover .icon-wrap {
  background-color: transparent;
  border-color: transparent;
}

.admin-panel .service .link .btn-link.amazon-handmade-none .icon--amazon,
.admin-panel
  .service
  .link
  .btn-link.amazon-influencer-dot
  .icon-wrap
  .icon--amazon,
.admin-panel .service .link .btn-link.amazon-influencer-none .icon--amazon,
.admin-panel .service .link .btn-link:hover .icon-wrap .icon--amazon {
  fill: currentColor;
}

.admin-panel .service .link .btn-link.amazon-influencer-full {
  background-color: #019083;
  border-color: #019083;
  color: #fff;
}

.admin-panel .service .link .btn-link.amazon-influencer-full .icon-wrap {
  background-color: transparent;
}

.admin-panel
  .service
  .link
  .btn-link.amazon-influencer-full
  .icon-wrap
  .icon--amazon {
  fill: #fff;
}

.admin-panel
  .service
  .link
  .btn-link.amazon-handmade-dot
  .icon-wrap
  .icon--amazon,
.admin-panel
  .service
  .link
  .btn-link.amazon-influencer-full:hover
  .icon--amazon {
  fill: currentColor;
}

.admin-panel .service .link .btn-link.amazon-handmade-full {
  background-color: #82dfe3;
  border-color: #82dfe3;
  color: #fff;
}

.admin-panel .service .link .btn-link.amazon-handmade-full .icon-wrap {
  background-color: transparent;
}

.admin-panel
  .service
  .link
  .btn-link.amazon-handmade-full
  .icon-wrap
  .icon--amazon {
  fill: #fff;
}

.admin-panel .service .link .btn-link.amazon-handmade-full:hover .icon--amazon {
  fill: currentColor;
}

.admin-panel .theme .link {
  margin-bottom: 16px;
  padding: 0;
  position: relative;
}

.admin-panel .theme .link a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  white-space: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 56px;
  padding: 15px 20px;
  width: 100%;
  position: relative;
  transition: none;
  transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .theme .link.link-disabled > a {
  border-color: #d7dce1;
  background-color: #d7dce1;
  padding: 15px 20px;
}

.admin-panel .theme .link .link-icon {
  padding: 15px 66px !important;
}

.admin-panel .theme .link .link-icon .icon-wrap {
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  height: 100%;
  width: 100%;
  max-height: 46px;
  max-width: 46px;
  z-index: 5;
  overflow: hidden;
  pointer-events: none;
}

.admin-panel .theme .link.fill .btn-link:hover {
  background-color: transparent;
  color: #39e09b;
  border-color: #39e09b;
}

.admin-panel .theme .link.fill .btn-link {
  border-width: 2px;
  border-style: solid;
  border-color: #39e09b;
  background-color: #39e09b;
  color: #fff;
}

.admin-panel .theme .link .link-icon .icon-wrap--amazon,
.admin-panel .theme .link .link-icon .icon-wrap--thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-panel .theme .link .link-icon .icon-wrap--thumbnail img {
  height: 100%;
}

.admin-panel .theme .link .link-icon .icon-wrap--thumbnail svg {
  height: 100%;
  width: 100%;
  stroke: currentColor;
  stroke-width: 1;
}

.admin-panel .theme .links {
  text-align: center;
  clear: both;
  margin-bottom: 12px;
}

.admin-panel .video-button {
  position: relative;
  display: block;
  margin-bottom: 16px;
}

.admin-panel .video-button .link {
  margin: 0 !important;
}

.admin-panel .video-button .video-wrapper {
  display: none;
  padding-bottom: 48px;
}

.admin-panel .video-button .video-wrapper .close-icon {
  position: absolute;
  bottom: 8px;
  left: calc(50% - 16px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  opacity: 1;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.admin-panel .video-button .video-wrapper .close-icon svg {
  display: block;
  width: 14px;
  height: 14px;
}

.admin-panel
  .service-container.luminance-light
  .video-button
  .video-wrapper
  .close-icon {
  background-color: rgba(0, 0, 0, 0.5);
}

.admin-panel
  .service-container.luminance-light
  .video-button
  .video-wrapper
  .close-icon
  svg {
  fill: #fff;
}

.admin-panel
  .service-container.luminance-light
  .video-button
  .video-wrapper
  .close-icon:hover {
  background-color: #000;
}

.admin-panel
  .service-container.luminance-dark
  .video-button
  .video-wrapper
  .close-icon {
  background-color: rgba(255, 255, 255, 0.5);
}

.admin-panel
  .service-container.luminance-dark
  .video-button
  .video-wrapper
  .close-icon
  svg {
  fill: #000;
}

.admin-panel
  .service-container.luminance-dark
  .video-button
  .video-wrapper
  .close-icon:hover {
  background-color: #fff;
}

.admin-panel .video-button .video-wrapper .video-styles {
  overflow: hidden;
}

.admin-panel .video-button .video-wrapper .video-styles .embed-container {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.admin-panel
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_everything {
  padding-bottom: 112.5%;
}

.admin-panel
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_everything
  .video-player
  iframe {
  top: 0 !important;
  height: 50% !important;
}

.admin-panel
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_everything
  .chat-iframe {
  top: 50% !important;
  height: 50% !important;
}

.admin-panel
  .service.is-mobile
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_everything,
.admin-panel
  .service.preview-area
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_everything {
  padding-bottom: 168.75%;
}

.admin-panel
  .service.is-mobile
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_everything
  .video-player
  iframe,
.admin-panel
  .service.preview-area
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_everything
  .video-player
  iframe {
  height: 33.33% !important;
}

.admin-panel
  .service.is-mobile
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_everything
  .chat-iframe,
.admin-panel
  .service.preview-area
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_everything
  .chat-iframe {
  top: 33.33% !important;
  height: 66.66% !important;
}

.admin-panel
  .service.is-mobile
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_chat,
.admin-panel
  .service.preview-area
  .video-button
  .video-wrapper
  .video-styles
  .embed-container--embed_chat {
  padding-bottom: 112.5%;
}

.admin-panel
  .video-button
  .video-wrapper
  .video-styles
  .embed-container
  iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.admin-panel .video-button .video-wrapper .plyr {
  width: 100%;
}

.admin-panel .video-button .video-wrapper .plyr--hide-controls .plyr__controls {
  opacity: 1;
  transform: unset;
  pointer-events: auto;
}

.admin-panel .video-button .video-wrapper .plyr [data-plyr='play'],
.admin-panel .video-button .video-wrapper .plyr__poster {
  display: none;
}

.admin-panel .video-button .video-wrapper .plyr [data-plyr] {
  background: transparent;
  box-shadow: unset;
}

.admin-panel .video-button .video-wrapper .plyr__controls {
  display: flex;
  flex-direction: row;
  padding: 0 16px 21px;
  background-image: unset;
}

.admin-panel .video-button .video-wrapper .plyr__controls__item {
  max-width: unset;
  min-width: unset;
  margin: unset;
  padding: unset;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item.plyr__progress__container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item:not(.plyr__progress__container) {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 1;
  height: 32px;
  font-size: 12px;
  margin-right: 8px;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item:not(.plyr__progress__container)
  ::-moz-selection {
  background-color: transparent;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item:not(.plyr__progress__container)
  ::selection {
  background-color: transparent;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item:not(.plyr__progress__container)
  svg {
  width: 16px;
  height: 16px;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item:not(.plyr__progress__container):after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  z-index: -1;
  top: 0;
  left: 0;
  transition: background-color 0.2s ease-in-out;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item:not(.plyr__progress__container):hover:after {
  background-color: #000 !important;
}

.admin-panel .video-button .video-wrapper .plyr__controls__item.plyr__volume,
.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item[data-plyr='fullscreen'] {
  width: 32px;
}

.admin-panel .video-button .video-wrapper .plyr__controls__item.plyr__time {
  pointer-events: none;
  padding: 0 12px !important;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item.plyr__time--current {
  order: 1;
  margin-right: auto;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item.plyr__time--duration {
  order: 2;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item.plyr__time--duration:before {
  display: none;
}

.admin-panel .video-button .video-wrapper .plyr__controls__item.plyr__volume {
  order: 3;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__controls__item[data-plyr='fullscreen'] {
  order: 4;
  margin-right: 0;
}

.admin-panel .video-button .video-wrapper .plyr__progress {
  left: unset;
  margin: unset;
}

.admin-panel .video-button .video-wrapper .plyr__progress input[type='range'],
.admin-panel .video-button .video-wrapper .plyr__progress progress {
  margin: unset;
  width: 100%;
  height: 5px;
  border-radius: 0;
}

.admin-panel .video-button .video-wrapper .plyr__progress progress {
  top: 0;
  background-color: #000;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__progress
  progress::-webkit-progress-value {
  background-color: rgba(255, 255, 255, 0.14);
}

.admin-panel .video-button .video-wrapper .plyr__progress input[type='range'] {
  color: #f43636;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__progress
  input[type='range']::-webkit-slider-thumb {
  width: 12px;
  height: 12px;
  background-color: #f43636;
  box-shadow: unset !important;
  opacity: 0;
}

.admin-panel
  .video-button
  .video-wrapper
  .plyr__progress
  input[type='range']::-webkit-slider-runnable-track {
  border-radius: 0;
  background-color: transparent;
  box-shadow: unset;
}

.admin-panel .colourway-blocks .subscribe-button .select .iti__flag-container,
.admin-panel .colourway-blocks .subscribe-button button,
.admin-panel .colourway-confetti .subscribe-button .select .iti__flag-container,
.admin-panel .colourway-confetti .subscribe-button button,
.admin-panel
  .colourway-dillonfrancis
  .subscribe-button
  .select
  .iti__flag-container,
.admin-panel .colourway-dillonfrancis .subscribe-button button,
.admin-panel .colourway-diplo .subscribe-button .select .iti__flag-container,
.admin-panel .colourway-diplo .subscribe-button button,
.admin-panel
  .colourway-halloween-2019
  .subscribe-button
  .select
  .iti__flag-container,
.admin-panel .colourway-halloween-2019 .subscribe-button button,
.admin-panel
  .colourway-majorlazer
  .subscribe-button
  .select
  .iti__flag-container,
.admin-panel .colourway-majorlazer .subscribe-button button,
.admin-panel
  .colourway-neikedmusic
  .subscribe-button
  .select
  .iti__flag-container,
.admin-panel .colourway-neikedmusic .subscribe-button button,
.admin-panel .colourway-rainbow .subscribe-button .select .iti__flag-container,
.admin-panel .colourway-rainbow .subscribe-button button,
.admin-panel
  .colourway-selenagomez
  .subscribe-button
  .select
  .iti__flag-container,
.admin-panel .colourway-selenagomez .subscribe-button button,
.admin-panel
  .colourway-starry-night
  .subscribe-button
  .select
  .iti__flag-container,
.admin-panel .colourway-starry-night .subscribe-button button,
.admin-panel
  .colourway-valentine-2020
  .subscribe-button
  .select
  .iti__flag-container,
.admin-panel .colourway-valentine-2020 .subscribe-button button,
.admin-panel
  .colourway-valentinokhan
  .subscribe-button
  .select
  .iti__flag-container,
.admin-panel .colourway-valentinokhan .subscribe-button button,
.admin-panel
  .colourway-whatsonot
  .subscribe-button
  .select
  .iti__flag-container,
.admin-panel .colourway-whatsonot .subscribe-button button,
.admin-panel .subscribe-button.formal-2-stroke .select .iti__flag-container,
.admin-panel .subscribe-button.formal-2-stroke button,
.admin-panel .subscribe-button.jiggly-edges .select .iti__flag-container,
.admin-panel .subscribe-button.jiggly-edges button,
.admin-panel .subscribe-button.torn-edges .select .iti__flag-container,
.admin-panel .subscribe-button.torn-edges button,
.admin-panel
  .subscribe-button[class*='hardshadow']
  .select
  .iti__flag-container,
.admin-panel .subscribe-button[class*='hardshadow'] button,
.admin-panel
  .subscribe-button[class*='softshadow']
  .select
  .iti__flag-container,
.admin-panel .subscribe-button[class*='softshadow'] button {
  margin-top: 8px;
  margin-bottom: 8px;
}

.admin-panel .colourway-blocks .subscribe-button button span,
.admin-panel .colourway-confetti .subscribe-button button span,
.admin-panel .colourway-dillonfrancis .subscribe-button button span,
.admin-panel .colourway-diplo .subscribe-button button span,
.admin-panel .colourway-halloween-2019 .subscribe-button button span,
.admin-panel .colourway-majorlazer .subscribe-button button span,
.admin-panel .colourway-neikedmusic .subscribe-button button span,
.admin-panel .colourway-rainbow .subscribe-button button span,
.admin-panel .colourway-selenagomez .subscribe-button button span,
.admin-panel .colourway-starry-night .subscribe-button button span,
.admin-panel .colourway-valentine-2020 .subscribe-button button span,
.admin-panel .colourway-valentinokhan .subscribe-button button span,
.admin-panel .colourway-whatsonot .subscribe-button button span,
.admin-panel .subscribe-button.formal-2-stroke button span,
.admin-panel .subscribe-button.jiggly-edges button span,
.admin-panel .subscribe-button.torn-edges button span,
.admin-panel .subscribe-button[class*='hardshadow'] button span,
.admin-panel .subscribe-button[class*='softshadow'] button span {
  transform: scale(1);
}

.admin-panel .colourway-blocks .subscribe-button button:hover span,
.admin-panel .colourway-confetti .subscribe-button button:hover span,
.admin-panel .colourway-dillonfrancis .subscribe-button button:hover span,
.admin-panel .colourway-diplo .subscribe-button button:hover span,
.admin-panel .colourway-halloween-2019 .subscribe-button button:hover span,
.admin-panel .colourway-majorlazer .subscribe-button button:hover span,
.admin-panel .colourway-neikedmusic .subscribe-button button:hover span,
.admin-panel .colourway-rainbow .subscribe-button button:hover span,
.admin-panel .colourway-selenagomez .subscribe-button button:hover span,
.admin-panel .colourway-starry-night .subscribe-button button:hover span,
.admin-panel .colourway-valentine-2020 .subscribe-button button:hover span,
.admin-panel .colourway-valentinokhan .subscribe-button button:hover span,
.admin-panel .colourway-whatsonot .subscribe-button button:hover span,
.admin-panel .subscribe-button.formal-2-stroke button:hover span,
.admin-panel .subscribe-button.jiggly-edges button:hover span,
.admin-panel .subscribe-button.torn-edges button:hover span,
.admin-panel .subscribe-button[class*='hardshadow'] button:hover span,
.admin-panel .subscribe-button[class*='softshadow'] button:hover span {
  transform: scale(1.048) !important;
}

.admin-panel .iti.iti--container {
  padding: 0;
}

.admin-panel .iti.iti--container .iti__country-list {
  border: none;
  color: #3d444b;
}

.admin-panel body:not(.iti-mobile) .iti.iti--container {
  margin-top: 2px;
  overflow: hidden;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.admin-panel body:not(.iti-mobile) .iti.iti--container .iti__country-list {
  position: relative;
}

.admin-panel .iti-mobile .iti.iti--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.admin-panel .iti-mobile .iti.iti--container .iti__country-list {
  max-height: 60vh;
  border-radius: 8px;
  width: calc(100% - 32px);
  box-shadow: none;
}

.admin-panel .subscribe-button {
  position: relative;
  display: block;
  margin-bottom: 16px;
}

.admin-panel .subscribe-button[data-type='PHONE'] input {
  width: 100%;
}

.admin-panel .subscribe-button .disclaimer {
  position: fixed;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 13px;
  z-index: 6;
  opacity: 0;
  text-align: center;
  transform: translateY(0) !important;
  transition: transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) !important;
}

.admin-panel .subscribe-button .disclaimer span {
  line-height: 1.2;
  font-weight: inherit;
  font-size: inherit;
}

.admin-panel .subscribe-button .disclaimer span a {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-left: 8px;
  text-decoration: underline;
  white-space: nowrap;
  color: inherit !important;
  font-size: inherit !important;
}

.admin-panel .subscribe-button .wrapper {
  position: relative;
}

.admin-panel .subscribe-button .wrapper .markup {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.admin-panel .subscribe-button .wrapper .form {
  min-height: 56px;
  display: flex;
  flex-wrap: nowrap;
  border: 2px solid;
  border-color: inherit;
  overflow: hidden;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .subscribe-button .wrapper .form .message {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: inherit;
  border-radius: inherit;
  background-color: inherit;
  opacity: 1;
  z-index: 1 !important;
  transition: background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    opacity 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0.25s;
}

.admin-panel .subscribe-button .wrapper .form .select,
.admin-panel .subscribe-button .wrapper .form button,
.admin-panel .subscribe-button .wrapper .form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: inherit;
  border-radius: 0;
  border: 0;
  color: inherit;
  display: flex;
  align-items: stretch;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  opacity: 0;
  outline: none;
}

.admin-panel .subscribe-button .wrapper .form .select {
  order: 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  color: inherit;
  border-color: inherit;
  transition: opacity 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .subscribe-button .wrapper .form .select .iti__flag-container {
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  border: none;
  border-right-color: inherit;
  border-right-style: solid;
  border-right-width: 2px;
}

.admin-panel
  .subscribe-button
  .wrapper
  .form
  .select
  .iti__flag-container
  .iti__selected-flag {
  padding: 0 8px;
  outline: none;
  background: transparent !important;
}

.admin-panel
  .subscribe-button
  .wrapper
  .form
  .select
  .iti__flag-container
  .iti__selected-flag
  .iti__flag {
  display: none;
}

.admin-panel
  .subscribe-button
  .wrapper
  .form
  .select
  .iti__flag-container
  .iti__selected-flag
  .iti__selected-dial-code {
  margin-left: 4px;
  margin-right: 6px;
}

.admin-panel
  .subscribe-button
  .wrapper
  .form
  .select
  .iti__flag-container
  .iti__selected-flag
  .iti__arrow {
  border-top-color: inherit;
  margin: 0;
}

.admin-panel
  .subscribe-button
  .wrapper
  .form
  .select
  .iti__flag-container
  .iti__selected-flag
  .iti__arrow--up {
  border-bottom-color: inherit;
}

.admin-panel .subscribe-button .wrapper .form input {
  order: 1;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: opacity 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .subscribe-button .wrapper .form button {
  order: 2;
  cursor: pointer;
  font-weight: inherit;
  align-items: center;
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: inherit;
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  padding: 0 16px;
  transform: translateX(calc(100% + 4px));
  opacity: 1;
  transition: background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .subscribe-button .wrapper .form button span {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
}

.admin-panel .subscribe-button .wrapper .form button:after {
  height: 24px;
  width: 24px;
  -webkit-animation: spin 0.4s linear infinite;
  animation: spin 0.4s linear infinite;
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  content: '';
  position: absolute;
  top: 50%;
  left: -24px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
  -webkit-animation: spin-positioned-xy 0.4s linear infinite;
  animation: spin-positioned-xy 0.4s linear infinite;
}

.admin-panel .subscribe-button ::-moz-selection {
  background-color: transparent;
}

.admin-panel .subscribe-button ::selection {
  background-color: transparent;
}

.admin-panel .subscribe-button.loading button:after {
  opacity: 1 !important;
}

.admin-panel .subscribe-button.active .disclaimer {
  transform: translateY(100%) !important;
  opacity: 1;
}

.admin-panel .subscribe-button.active .form .select,
.admin-panel .subscribe-button.active .form input {
  opacity: 1 !important;
  transition: opacity 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0.25s;
}

.admin-panel .subscribe-button.active .form button {
  transform: translateX(0);
  transition: background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99),
    transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0.25s;
}

.admin-panel .subscribe-button.active .form .message {
  opacity: 0 !important;
  pointer-events: none;
  transition-delay: 0s !important;
}

.admin-panel .theme p.description {
  padding: 0 40px;
  margin-top: 8px;
  margin-bottom: 0;
  text-align: center;
  color: #bbb;
  font-size: 12px;
  line-height: 22px;
}

.admin-panel .theme.is-mobile p.description,
.admin-panel .theme.preview-area p.description {
  padding: 0 16px;
}

.admin-panel .service {
  overflow: hidden;
}

.admin-panel .theme:not(.preview-area) {
  background-attachment: fixed;
}

.admin-panel .theme:not(.colourway-custom) form {
  border: 2px solid #fff;
  color: #fff;
}

.admin-panel .theme:not(.colourway-custom) form:not(.active):hover {
  background-color: #fff;
  color: #222;
}

.admin-panel .theme:not(.colourway-custom) form.active button:hover {
  background-color: #fff;
  border-left-color: #fff;
  color: #222;
}

.admin-panel .theme .user-area {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 32px;
  padding-top: 15px;
}

.admin-panel .theme .user-area h3 {
  line-height: 1;
  margin: 0 auto;
}

.admin-panel .theme section.main {
  background-color: transparent;
}

.admin-panel .theme section.main:after,
.admin-panel .theme section.main:before {
  content: ' ';
  display: table;
}

.admin-panel .theme section.main:after {
  clear: both;
}

.admin-panel .service-container {
  max-width: 700px;
  width: auto;
}

.admin-panel .theme .img-circle {
  position: relative;
  width: 96px;
  height: 96px;
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 auto 16px;
  border-radius: 50%;
  overflow: hidden;
}

.admin-panel .theme .img-circle img {
  width: 0;
  min-width: 96px;
  min-height: 96px;
}

.admin-panel .theme .user-name {
  color: #bbb;
  font-size: 1rem;
  font-weight: 600;
  border-color: transparent;
  line-height: 1.25;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  overflow: hidden;
  outline-color: transparent !important;
}

.admin-panel .theme .overlay-unverified {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    180deg,
    transparent 0,
    transparent 1%,
    rgba(255, 255, 255, 0.96) 45%,
    #fff 50%
  );
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-panel .theme .overlay-unverified h2 {
  white-space: pre-line;
}

.admin-panel .user-profile__no-social__links {
  margin: 50px auto 30px;
}

.admin-panel .user-profile__no-social__links,
.admin-panel .user-profile__social__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.admin-panel .user-profile__social__links {
  margin: 50px auto 70px;
  position: relative;
  left: 0;
  width: 100%;
  bottom: 15px;
}

.admin-panel .user-profile__social__links.no-logo {
  margin: 50px auto 20px;
}

.admin-panel .user-profile__social__links__item {
  cursor: pointer;
  width: 60px;
  max-width: 50px;
  transition: transform cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  padding-bottom: 12px;
  justify-content: center;
}

.admin-panel .user-profile__social__links__item:hover {
  transform: scale(1.075);
}

.admin-panel .user-profile__social__links__item svg {
  pointer-events: none;
}
